import { DapicIntegrationCard } from "./bling/components/dapic-card.component";
import { PodOrganizarIntegrationCard } from "./bling/components/podorganizar.component";
import { VestuarIntegrationCard } from "./bling/components/vestuar-card.component";
import { WebpicIntegrationCard } from "./bling/components/webpic-card.component";

export const IntegrationsPage: React.FC = () => {
  return (
    <div className="d-flex flex-row gap-4" style={{ minHeight: 160 }}>
      <DapicIntegrationCard />
      <VestuarIntegrationCard />
      <WebpicIntegrationCard />
      <PodOrganizarIntegrationCard />
    </div>
  );
};
