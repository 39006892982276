import LoadingIndicator from "@components/LoadingIndicator";
import Api from "@services/Api";
import tableStyles from "@styles/table";
import { toastError } from "@utils/functions";
import { User } from "@utils/types";
import * as React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useParams } from "react-router";

const CompanyDetailsPage: React.FC = () => {
  const [companyUsers, setCompanyUsers] = React.useState<User[]>([]);
  const { company_id: companyId } = useParams<{ company_id: string }>();
  const [loading, setLoading] = React.useState(true);

  const fetchCompanyUsers = async (companyId: string) => {
    try {
      console.log("fetch");
      const { data } = await Api.get(`/company/${companyId}/seller`);
      setCompanyUsers(data.sellers);
      setLoading(false);
    } catch (error) {
      toastError(error);
    }
  };

  const columns = React.useMemo<TableColumn<User>[]>(
    () => [
      {
        name: "Nome",
        selector: (row) => row.name ?? "N/D",
      },
      {
        name: "Tipo de usuário",
        selector: (row) => {
          if (row.role === "company_admin") {
            return "Administrador";
          } else if (row.role === "company_seller") {
            return "Vendedor";
          } else {
            return "N/D";
          }
        },
      },
      {
        name: "Email",
        selector: (row) => row.email ?? "N/D",
      },
      {
        name: "Telefone",
        selector: (row) => row.phone ?? "N/D",
      },
      {
        name: "Data de Criação",
        selector: (row) => {
          const date = new Date(row.created_at?.toString() ?? "");
          return date.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          });
        },
        sortable: true,
      },
    ],
    [],
  );

  React.useEffect(() => {
    console.log(companyId, "efeito");
    companyId && fetchCompanyUsers(companyId);
  }, [companyId]);

  return (
    <>
      <DataTable
        columns={columns}
        data={companyUsers}
        progressPending={loading}
        progressComponent={<LoadingIndicator />}
        customStyles={tableStyles}
        noDataComponent="Nenhum usuário cadastrado"
        pagination
        paginationPerPage={10}
        paginationTotalRows={companyUsers.length}
        responsive
      ></DataTable>
    </>
  );
};

export default CompanyDetailsPage;
