import Select from "@components/Select";
import { SelectOption } from "@utils/types";
import moment, { Moment } from "moment";

export interface SelectIntervalProps {
  onSelectedInterval: (interval: Moment) => void;
}

const intervalOptions = [
  { value: moment().subtract(30, "days"), label: "Últimos 30 dias" },
  { value: moment().subtract(60, "days"), label: "Últimos 60 dias" },
  { value: moment().subtract(6, "months"), label: "Últimos 6 meses" },
  { value: moment().startOf("year"), label: "Este ano" },
  { value: moment().subtract(1, "years"), label: "Todo o período" },
] as SelectOption[];

export const SelectInterval: React.FC<SelectIntervalProps> = ({ onSelectedInterval }) => {
  return (
    <Select
      options={intervalOptions}
      defaultValue={intervalOptions[0]}
      onChange={(value) => {
        const option = value as SelectOption;
        onSelectedInterval(intervalOptions.find((d) => d.value === option.value)?.value ?? intervalOptions[0].value);
      }}
    />
  );
};
