import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "@services/Api";
import { toastError } from "@utils/functions";
import { Company } from "@utils/types";

const company = createSlice({
  name: "company",
  initialState: {
    loading: true,
    data: {} as Company,
  },
  reducers: {
    setLoadingCompany(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setCompany(state, action: PayloadAction<Company>) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompany.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchCompany.rejected, (state, action) => {});
  },
});

export default company.reducer;

export const { setLoadingCompany, setCompany } = company.actions;

export const fetchCompany = createAsyncThunk("company", async (companyId: string, { rejectWithValue }) => {
  try {
    const { data } = await Api.get(`/company/by_code/${companyId}`);
    return data.company;
  } catch (error) {
    toastError(error);
    throw rejectWithValue(error);
  }
});
