import styled from "styled-components";

const BannerImage = styled.img`
  width: 200px;
  object-fit: contain;
`;

const BannerImageActionsContainer = styled.div`
  width: 100%;
  background-color: #ededed;
  height: 0px;
  z-index: 3;
  display: flex;
  height: 50px;
  max-height: 0px;
  opacity: 0;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  @media (max-width: 992px) {
    opacity: 1;
    max-height: 50px;
  }
`;

const BannerImageContainer = styled.div`
  background-color: #ededed;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  min-height: 160px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  min-width: 200px;
  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  &:hover ${BannerImageActionsContainer} {
    max-height: 50px;
    opacity: 1;
    transition: all 0.3s;
  }
`;

const modules = {
  BannerImage,
  BannerImageContainer,
  BannerImageActionsContainer,
};

export default modules;
