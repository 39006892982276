import * as React from "react";
import { useDropzone, DropzoneInputProps } from "react-dropzone";
import styled from "styled-components";
import { AiOutlinePlusCircle } from "react-icons/ai";
import ReactLoading from "react-loading";

const DropZoneContainer = styled.div`
  width: 200px;
  height: 160px;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 4px dashed rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  justify-content: center;
  display: flex;
  align-items: center;
`;

interface DropImagesInputProps extends DropzoneInputProps {
  imageUrl?: string;
  onFileSelect?: Function;
  loading?: boolean;
}

const DropImageInput: React.FC<DropImagesInputProps> = (props) => {
  const onDrop = React.useCallback(
    (acceptedFiles: any) => {
      if (props.loading) return;
      props.onFileSelect?.(acceptedFiles);
    },
    [props],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop, disabled: props.loading });

  return (
    <DropZoneContainer
      style={{
        backgroundImage: `url(${props.imageUrl})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div
        {...getRootProps()}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        <input {...getInputProps()} disabled={props.loading} />
        {props.loading ? (
          <p style={{ textAlign: "center" }}>
            <ReactLoading type={"spin"} color={"#8d8d8d"} height={40} width={40} />
          </p>
        ) : !props.imageUrl ? (
          <p style={{ textAlign: "center" }}>
            <AiOutlinePlusCircle color="#8d8d8d" size={40} />
          </p>
        ) : null}
      </div>
    </DropZoneContainer>
  );
};

export default DropImageInput;
