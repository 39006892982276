import * as React from "react";
import Styled from "./styled";
import logo from "@assets/svg/logo.svg";
import { AiOutlineMenu } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { useAppDispatch } from "@services/Redux";
import { toggleSidebar } from "@services/Redux/app";
import env from "@utils/env";

const TopBar: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleSidebarToggleClick = () => {
    dispatch(toggleSidebar());
  };

  return (
    <Styled.RootContainer>
      <Styled.SidebarMenuToggle onClick={handleSidebarToggleClick}>
        <AiOutlineMenu size={28} />
      </Styled.SidebarMenuToggle>
      <img
        src={env.REACT_APP_LOGO_URL_LIGHT ? env.REACT_APP_LOGO_URL : env.REACT_APP_LOGO_URL ?? logo}
        alt="company_logo"
        style={{ maxWidth: 180 }}
      />
      <span>
        <CgProfile color="white" size={28} />
      </span>
    </Styled.RootContainer>
  );
};

export default TopBar;
