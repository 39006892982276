import InfoCard from "@components/InfoCard";
import { SelectInterval } from "@components/SelectInterval";
import Api from "@services/Api";
import { toastError } from "@utils/functions";
import { Money } from "@utils/money";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { AxisOptions, Chart } from "react-charts";

interface ChartData {
  date: Date;
  sales: number;
}

interface SalesData {
  sales: number;
  averageTicket: number;
  chartData: ChartData[];
}

type Series = {
  label: string;
  data: ChartData[];
};

type Metrics = {
  salesVolume: number;
  sales: number;
  orders: number;
  averageTicket: number;
  catalogsActivation: number;
  sellersQualified: number;
  productsRegistered: number;
  productsActive: number;
};

export const NewDashboardPage: React.FC = () => {
  const [fetchingTemporalData, setFetchingTemporalData] = useState(true);
  const [salesData, setSalesData] = useState<SalesData[]>([]);
  const [startDate, setStartDate] = useState(moment().subtract(30, "days"));
  const [chartData, setChartData] = useState<Series[]>([]);
  const [metrics, setMetrics] = useState<Metrics>({
    salesVolume: 0,
    sales: 0,
    orders: 0,
    averageTicket: 0,
    catalogsActivation: 0,
    sellersQualified: 0,
    productsRegistered: 0,
    productsActive: 0,
  });

  const fetchFixedData = async () => {
    try {
      const { data: fixedData } = await Api.get(`/metrics/fixed-data`);
      setMetrics((prev) => ({
        ...prev,
        productsActive: fixedData.metrics.productsActive,
        productsRegistered: fixedData.metrics.productsRegistered,
        sellersQualified: fixedData.metrics.sellersQualified,
      }));
    } catch (error) {
      toastError(error);
    }
  };

  const fetchCatalogsActivation = async () => {
    try {
      const { data: catalogActiviationData } = await Api.get(
        `/metrics/catalog-activation?start_date=${startDate.format("YYYY-MM-DD")}&end_date=${moment().format("YYYY-MM-DD")}`,
      );
      setMetrics((prev) => ({
        ...prev,
        catalogsActivation: catalogActiviationData.catalogActivations,
      }));
    } catch (error) {
      toastError(error);
    }
  };

  const fetchTemporalData = async () => {
    setFetchingTemporalData(true);
    try {
      const { data: temporalData } = await Api.get(
        `/metrics/temporal-data?start_date=${startDate.format("YYYY-MM-DD")}&end_date=${moment().format("YYYY-MM-DD")}`,
      );

      const salesChartData = {
        label: "Vendas no dia",
        data: temporalData.chartData.map((data: ChartData) => ({
          date: new Date(data.date),
          sales: data.sales,
        })),
      };

      const ordersChartData = {
        label: "Pedidos no dia",
        data: temporalData.ordersChartData.map((data: ChartData) => ({
          date: new Date(data.date),
          sales: data.sales,
        })),
      };

      const cumulativeSalesChartData = {
        label: "Vendas (acumulado)",
        data: salesChartData.data.reduce((acc: any, data: any, index: any) => {
          if (index === 0) {
            return [{ date: data.date, sales: data.sales }];
          }

          return [
            ...acc,
            {
              date: data.date,
              sales: data.sales + acc[index - 1].sales,
            },
          ];
        }, []),
      };

      const cumulativeOrdersChartData = {
        label: "Pedidos (acumulado)",
        data: ordersChartData.data.reduce((acc: any, data: any, index: any) => {
          if (index === 0) {
            return [{ date: data.date, sales: data.sales }];
          }

          return [
            ...acc,
            {
              date: data.date,
              sales: data.sales + acc[index - 1].sales,
            },
          ];
        }, []),
      };

      setChartData([salesChartData, cumulativeSalesChartData, ordersChartData, cumulativeOrdersChartData]);
      setSalesData(salesData);
      setMetrics((prev) => ({
        ...prev,
        averageTicket: temporalData.metrics.averageTicket,
        salesVolume: temporalData.metrics.salesVolume,
        sales: temporalData.metrics.sales,
        orders: temporalData.metrics.orders,
      }));
    } catch (error) {
      console.log(error, "error");
      toastError(error);
    } finally {
      setFetchingTemporalData(false);
    }
  };

  const primaryAxis = useMemo(
    (): AxisOptions<ChartData> => ({
      getValue: (datum) => datum.date,
      formatters: {
        tooltip: (datum: any) => moment(datum).format("DD/MM/YYYY dddd"),
      },
    }),
    [],
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<ChartData>[] => [
      {
        getValue: (datum) => datum.sales,
      },
    ],
    [],
  );

  useEffect(() => {
    fetchFixedData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchTemporalData();
    fetchCatalogsActivation();
    // eslint-disable-next-line
  }, [startDate]);

  return (
    <div className="p-1">
      <div style={{ maxWidth: 300 }}>
        <SelectInterval onSelectedInterval={setStartDate} />
      </div>

      <div className="row mt-3">
        <div className="col-3">
          <InfoCard
            loading={fetchingTemporalData}
            title="Volume de vendas"
            description={Money(metrics.salesVolume).format()}
            color="#2546BB"
          />
        </div>
        <div className="col-3">
          <InfoCard
            loading={fetchingTemporalData}
            title="Pedidos realizados"
            description={metrics.orders}
            color="#2546BB"
          />
        </div>
        <div className="col-3">
          <InfoCard loading={fetchingTemporalData} title="Pedidos pagos" description={metrics.sales} color="#2546BB" />
        </div>
        <div className="col-3">
          <InfoCard
            loading={fetchingTemporalData}
            title="Ticket médio"
            description={Money(metrics.averageTicket).format()}
            color="#2546BB"
          />
        </div>
        <div className="col-3 mt-3">
          <InfoCard
            loading={fetchingTemporalData}
            title="Ativação de catálogos"
            description={metrics.catalogsActivation}
            color="#2546BB"
          />
        </div>
      </div>

      <div className="mt-4 bg-white rounded p-2" style={{ width: "100%", height: 300 }}>
        <h5 style={{ fontWeight: "bold" }}>Número de vendas</h5>
        <div className="w-full" style={{ height: 250 }}>
          {chartData.length > 0 && !fetchingTemporalData && (
            <Chart
              style={{
                width: "100%",
                height: "300px",
              }}
              options={{
                data: chartData,
                primaryAxis,
                secondaryAxes,
              }}
            />
          )}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-3">
          <InfoCard
            loading={!metrics.sellersQualified}
            title="Sellers qualificados"
            description={metrics.sellersQualified}
            color="#2546BB"
          />
        </div>
        <div className="col-3">
          <InfoCard
            loading={!metrics.productsRegistered}
            title="Produtos cadastrados"
            description={metrics.productsRegistered}
            color="#2546BB"
          />
        </div>
        <div className="col-3">
          <InfoCard
            loading={!metrics.productsActive}
            title="Produtos ativos"
            description={metrics.productsActive}
            color="#2546BB"
          />
        </div>
      </div>
    </div>
  );
};
