import * as React from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router";

const Analytics: React.FC = () => {
  let location = useLocation();

  React.useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return <div></div>;
};

export default Analytics;
