import * as React from "react";
import { useNavigate } from "react-router";

const Logout: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    localStorage.clear();
    navigate("/login");
  }, [navigate]);
  return <div></div>;
};

export default Logout;
