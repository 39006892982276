import Api from "@services/Api";
import { RootState } from "@services/Redux";
import { toastError, toastMessage } from "@utils/functions";
import * as React from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

interface FormData {
  requires_document: boolean;
}

const OrderForm: React.FC = () => {
  const companyData = useSelector((state: RootState) => state.company.data);

  const [updating, setUpdating] = React.useState(false);
  const { register, handleSubmit, setValue } = useForm<FormData>();

  React.useEffect(() => {
    if (companyData.id) {
      setValue("requires_document", companyData.requires_document);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  const onSubmit = handleSubmit(async (data) => {
    setUpdating(true);
    try {
      await Api.patch(`/company/${companyData.id}`, data);
      toastMessage("Configurações atualizadas com sucesso!", "success");
    } catch (error) {
      toastError(error);
    } finally {
      setUpdating(false);
    }
  });

  return (
    <div className={"col-12 p-2"}>
      <form className={"bg-white p-3 h-100 rounded-3"} onSubmit={onSubmit}>
        <div className={"border-bottom pb-3"}>
          <h4>Configurações do formulário de pedido</h4>
          <span>Defina as configurações do formulário que é preenchido ao finalizar um pedido</span>
        </div>
        <div className={"border-bottom d-flex flex-row  py-3 align-items-center"}>
          <div className="form-check form-switch">
            <input
              {...register("requires_document")}
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="switch-document-required"
              defaultChecked={companyData.requires_document}
            />
            <label className="form-check-label" htmlFor="switch-document-required">
              CPF/CNPJ obrigatório
            </label>
          </div>
        </div>
        <div className={"d-flex flex-row py-3 align-items-center"}>
          <button className={"btn btn-primary"} disabled={updating}>
            {updating ? "Atualizando..." : "Atualizar"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrderForm;
