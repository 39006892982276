import FormikTextInput from "@components/FormikTextInput";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import { renameFile, toastError, toastMessage } from "@utils/functions";
import { Color } from "@utils/types";
import { Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import DropImageInput from "@components/DropImageInput";
import FullScreenLoading from "@components/FullScreenLoading";

interface NewColorFormValues {
  name: string;
}

const EditColorPage: React.FC = () => {
  const { color_id } = useParams();
  const companyData = useSelector((state: RootState) => state.company.data);
  const [image, setImage] = React.useState({ file: null, url: null } as { file: File | null; url: string | null });
  const [color, setColor] = React.useState<Color>();
  const [loadingColor, setLoadingColor] = React.useState(true);
  const navigate = useNavigate();

  const handleSubmit = async (values: NewColorFormValues, actions: FormikHelpers<NewColorFormValues>) => {
    try {
      actions.setSubmitting(true);
      await Api.patch(`/company/${companyData.id}/color/${color?.id}`, values);
      if (image.file) {
        const formData = new FormData();
        formData.append("files", image.file);
        await Api.post(`/company/${companyData.id}/color/${color?.id}/image`, formData);
      }
      toastMessage("Sua nova cor foi adicionada.", "success");
      navigate(`/configuracoes/cores`);
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  const handleImageSelect = (files: File[]) => {
    let file = files[0];
    const url = URL.createObjectURL(file);
    file = renameFile(file, "color-image." + file.name.split(".").pop());
    setImage({ file, url });
  };

  const fetchColor = async () => {
    try {
      const { data } = await Api.get(`/company/${companyData.id}/color/id/${color_id}`);
      setColor(data.color);
      setLoadingColor(false);
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    if (companyData.id) fetchColor();
    // eslint-disable-next-line
  }, [companyData]);

  if (loadingColor || !color) return <FullScreenLoading />;

  const formInitialValues: NewColorFormValues = {
    name: color.name,
  };

  return (
    <div className={"p-2 rounded-3"} style={{ width: "100%", backgroundColor: "white" }}>
      <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form className={"row"}>
            <div className={"col-12"}>
              <FormikTextInput name="name" mask="" label={"Nome da cor"} />
            </div>
            <div className={"col-12 mt-2"}>
              <label>Imagem (opcional)</label>
              <DropImageInput
                imageUrl={
                  image.url
                    ? image.url
                    : color.image
                    ? `${color.image?.base_url}/colors/${color.id}/thumb_2/${color.image?.key}`
                    : ""
                }
                onFileSelect={handleImageSelect}
                placeholder={"Arraste uma imagem para cá ou clique aqui para selecionar uma imagem"}
              />
            </div>
            <div className={"col-12 border-top mt-3 pt-3"}>
              <button className={"btn btn-primary"} type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Enviando" : "Enviar"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditColorPage;
