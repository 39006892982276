import React from "react";
import { GrCircleQuestion } from "react-icons/gr";
import { Tooltip } from "react-tooltip";

export interface QuestionToolTipProps {
  message: string;
  icon?: React.ReactNode;
  id: string;
  place?: "top" | "right" | "bottom" | "left";
  type?: "dark" | "success" | "warning" | "error" | "info" | "light";
}

export const QuestionToolTip = (props: QuestionToolTipProps) => {
  return (
    <>
      <a id={props.id}>{props.icon ?? <GrCircleQuestion className="mx-2" />}</a>
      <Tooltip
        className={`${props.message.length > 160 ? "w-50" : "w-auto"}`}
        clickable={true}
        place={props.place ?? "right"}
        anchorId={props.id}
        content={props.message}
        style={{
          maxWidth: 300,
          zIndex: 9999,
        }}
      />
    </>
  );
};
