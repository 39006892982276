import StatusPill from "@components/StatusPill";
import tableStyles from "@styles/table";
import { getStatusColor, toastError } from "@utils/functions";
import { Order, OrderStatus, User } from "@utils/types";
import moment from "moment";
import * as React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import LoadingIndicator from "@components/LoadingIndicator";
import { Link, useParams } from "react-router-dom";
import Api from "@services/Api";
import { Money } from "@utils/money";
import { RowItem } from "./styled";
import { RootState } from "@services/Redux";
import { useSelector } from "react-redux";

interface SellerWithOrders extends User {
  orders: Order[];
}

const columns: TableColumn<Order>[] = [
  {
    name: "Pedido",
    selector: (row) => row.code,
    cell: (row) => <Link to={`/pedido/${row.code}`}>{row.code}</Link>,
  },
  {
    name: "Data de criação",
    selector: (row) => moment(row.created_at).format("DD/MM/YY HH:mm"),
  },
  {
    name: "Total",
    selector: (row) => Money(row.value).format(),
  },
  {
    name: "Status",
    cell: (row) => <StatusPill title={row.status} bgColor={getStatusColor(row.status)} />,
  },
];
const AddSellersPage: React.FC = () => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const [loading, setLoading] = React.useState(true);
  const [seller, setSeller] = React.useState<SellerWithOrders>({} as SellerWithOrders);

  const { seller_id } = useParams();

  const fetchSeller = async () => {
    try {
      setLoading(true);
      const { data } = await Api.get(`/company/${companyData.id}/seller/${seller_id}`);
      setSeller({ ...data.seller, orders: data.orders });
    } catch (error) {
      toastError(error);
    }
    setLoading(false);
  };

  const calculateValue = (orders: Order[]) => {
    return orders?.reduce((acc, order) => {
      return acc + (order.status === OrderStatus.PAID ? order.value : 0);
    }, 0);
  };

  const calculateLatest = (orders: Order[]) => {
    return new Date(Math.max(...orders.map((order) => new Date(order.created_at).getTime())));
  };

  React.useEffect(() => {
    fetchSeller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-3">
      <div className="bg-white text-center p-4 my-3 d-flex flex-column justify-content-between rounded-3">
        <h4 className="text-capitalize">{seller.name}</h4>
        <div>{seller.phone}</div>
        <div>{seller.email}</div>
        <div>Vendedor desde: {moment(seller.created_at).format("DD/MM/YY")}</div>
        <div>Última atualização em: {moment(seller.updated_at).format("DD/MM/YY")}</div>
        <div className={`text-${seller.is_active ? "success" : "warning"}`}>
          {seller.is_active ? "ativo" : "inativo"}
        </div>
      </div>

      <div className="d-flex text-center mb-3 bg-white rounded-3">
        <RowItem>
          <div>Total vendido (pago)</div>
          <div>{Money(calculateValue(seller.orders)).format()}</div>
        </RowItem>
        <RowItem>
          <b>Vendas</b>
          <div>{seller.orders?.length !== 0 ? seller.orders?.length : "Nenhuma venda realizada"}</div>
        </RowItem>
        <RowItem>
          <b>Última Venda</b>
          <div>
            {seller.orders?.length
              ? moment(calculateLatest(seller.orders)).format("DD/MM/YY")
              : "Nenhuma venda realizada"}{" "}
          </div>
        </RowItem>
      </div>

      <div>
        <DataTable
          columns={columns}
          data={seller.orders}
          progressPending={loading}
          progressComponent={<LoadingIndicator />}
          customStyles={tableStyles}
          noDataComponent="Nenhuma venda realizada"
          pagination
          responsive
        />
      </div>
    </div>
  );
};

export default AddSellersPage;
