import * as React from "react";

import ErrorMessage, { FormContainer, FormContainerWrapper, RootContainer, TextContainer } from "./components";

import Api from "@services/Api";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const ResetPasswordScreen: React.FC = () => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const handleSubmit = async () => {
    try {
      setErrorMessage("");
      setLoading(true);
      await Api.post(`/user/reset_password`, { password, token: searchParams.get("token") });
      setLoading(false);
      alert("Sua senha foi alterada com sucesso! Faça login utilizando sua nova senha.");
      navigate("/login");
    } catch (error: any) {
      setLoading(false);
      alert(error.response.data.userMessage);
      setErrorMessage(error?.response?.data?.userMessage ?? "erro de conexão");
    }
  };

  return (
    <RootContainer>
      <FormContainerWrapper>
        <TextContainer>
          <h2>Redefinição de senha</h2>
          <span>Crie sua nova senha</span>
        </TextContainer>
        <FormContainer>
          <form>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="password"
                aria-describedby="passwordHelp"
                placeholder="Digite sua nova senha"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <ErrorMessage message={errorMessage} />
            <button
              type="submit"
              className="btn btn-primary w-100 mt-2"
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              Enviar
            </button>
          </form>
        </FormContainer>
      </FormContainerWrapper>
    </RootContainer>
  );
};

export default ResetPasswordScreen;
