import Select from "@components/Select";
import { reactSelectStyles } from "@components/Select/configs";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import { toastError, toastMessage } from "@utils/functions";
import { SelectOption, OrderStatus } from "@utils/types";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const statusOptions: SelectOption<OrderStatus>[] = [
  { value: OrderStatus.WAITING_SELLER_CONFIRMATION, label: "Assim que o pedido for enviado pelo cliente" },
  { value: OrderStatus.READY_TO_PAY, label: "Quando o vendedor liberar o pedido para pagamento" },
  { value: OrderStatus.PAID, label: "Apenas quando confirmado o pagamento" },
];

interface FormData {
  order_status_to_settle_stock: OrderStatus;
}

export const StockSettings = () => {
  const companyData = useSelector((state: RootState) => state.company.data);

  const [updating, setUpdating] = React.useState(false);
  const { register, handleSubmit, setValue } = useForm<FormData>();

  React.useEffect(() => {
    if (companyData.id) {
      setValue("order_status_to_settle_stock", companyData.order_status_to_settle_stock);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  const onSubmit = handleSubmit(async (data) => {
    setUpdating(true);
    try {
      await Api.patch(`/company/${companyData.id}`, data);
      toastMessage("Configurações atualizadas com sucesso!", "success");
    } catch (error) {
      toastError(error);
    } finally {
      setUpdating(false);
    }
  });

  return (
    <div className={"col-12 p-2"}>
      <form className={"bg-white p-3 h-100 rounded-3"} onSubmit={onSubmit}>
        <div className={"border-bottom pb-3"}>
          <h4>Configurações do Gerenciamento de Estoque</h4>
          <span>Defina as configurações de como o estoque age ao ser efetuado um pedido</span>
        </div>
        <div className={"border-bottom d-flex flex-row  py-3 align-items-center"}>
          <div className="form-check form-switch">
            <div>Você deseja que o estoque do produto seja debitado em qual momento?</div>
            <Select
              {...register("order_status_to_settle_stock")}
              options={statusOptions}
              placeholder={"Selecione um tipo de desconto"}
              defaultValue={statusOptions.find((o) => o.value === companyData.order_status_to_settle_stock)}
              onChange={(option) => {
                const value = (option as SelectOption).value;
                setValue("order_status_to_settle_stock", value);
              }}
              styles={{
                control: (defaultStyles, props) => ({
                  ...(reactSelectStyles?.control?.(defaultStyles, props) ?? {}),
                  minWidth: 234,
                }),
              }}
            />
          </div>
        </div>
        <div className={"d-flex flex-row py-3 align-items-center"}>
          <button className={"btn btn-primary"} disabled={updating}>
            {updating ? "Atualizando..." : "Atualizar"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default StockSettings;
