import { FormEvent, useState } from "react";
import { BlingCategoriesSelect } from "./categories-select.commponents";

export interface BlingProductsFiltersProps {
  onChange: (value: { name: string; code: string; category: string }) => void;
  loading?: boolean;
}

export const BlingProductsFilters: React.FC<BlingProductsFiltersProps> = ({ onChange, loading }) => {
  const [searchCategory, setSearchCategory] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchCode, setSearchCode] = useState("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onChange({ name: searchName, code: searchCode, category: searchCategory });
  };

  return (
    <form className="d-flex justify-content-between" onSubmit={handleSubmit}>
      <div className="">
        <BlingCategoriesSelect onChange={(category) => setSearchCategory(category)} />
      </div>
      <input
        className="form-control mx-2"
        value={searchName}
        placeholder="Nome"
        onChange={(e) => {
          setSearchName(e.target.value);
        }}
      />
      <input
        className="form-control mx-2"
        value={searchCode}
        placeholder="Código"
        onChange={(e) => {
          setSearchCode(e.target.value);
        }}
      />
      <button type="submit" className="btn btn-primary" disabled={loading}>
        {loading ? "Buscando..." : "Buscar"}
      </button>
    </form>
  );
};
