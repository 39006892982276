import { useState } from "react";
import { BlingCategory } from "../interfaces";
import Api from "@services/Api";
import { toastError } from "@utils/functions";

export interface FetchCategoriesOptions {
  name?: string;
}

export const useBlingCategories = () => {
  const [categories, setCategories] = useState<BlingCategory[]>([]);
  const [fetchingCategories, setFetchingCategories] = useState(false);
  const [page, setPage] = useState(1);

  const fetchCategories = async (options?: FetchCategoriesOptions) => {
    setFetchingCategories(true);
    let url = `/integrations/bling/categories?page=${page}`;
    if (options?.name) {
      url += `&name=${options.name}`;
    }
    try {
      const { data } = await Api.get(url);
      setCategories(data.categories);
      setPage(1);
      console.log(data.categories, "cats");
      return data.categories as BlingCategory[];
    } catch (error) {
      toastError(error);
      return [];
    } finally {
      setFetchingCategories(false);
      return [];
    }
  };

  return {
    categories,
    fetchingCategories,
    fetchCategories,
  };
};
