import { QuestionToolTip } from "@components/QuestionToolTip/question-tooltip";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";

export interface ConfigurationInputItemProps<T extends FieldValues> {
  label: string;
  description?: string;
  hasBottomBorder?: boolean;
  tooltipMessage?: string;
  registerName: Path<T>;
  register: UseFormRegister<T>;
  defaultValue: string | number;
  cardColor?: "warning" | "danger" | "success" | "info";
  cardMessage?: string;
}
export function ConfigurationInputItem<T extends FieldValues>(props: ConfigurationInputItemProps<T>) {
  const hasBottomBorder = props.hasBottomBorder === false ? "" : "border-bottom";
  return (
    <div className={`${hasBottomBorder} d-flex flex-column py-3 align-items-start`}>
      <div className="d-flex flex-row">
        <h5 className="m-0">{props.label}</h5>
        {props.tooltipMessage && <QuestionToolTip id={`input-${props.registerName}`} message={props.tooltipMessage} />}
      </div>
      {props.description && <label className="text-black-50 my-1">{props.description}</label>}
      <div className="d-flex flex-row justify-content-between">
        <input
          {...props.register(props.registerName)}
          className="text-input my-2"
          style={{ width: "auto" }}
          defaultValue={props.defaultValue}
        />
      </div>
      {props.cardMessage && (
        <div className={`card bg-${props.cardColor} text-white my-1`} style={{ maxWidth: "800px" }}>
          <div className="card-body">
            <p className="card-text">{props.cardMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
}
