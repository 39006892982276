import { getPaymentMethodLabel, getPaymentStatusLabel } from "@utils/functions";
import { OrderPayment } from "@utils/types";
import moment from "@utils/moment";
import { TableColumn } from "react-data-table-component";

const orderPaymentsColumns: TableColumn<OrderPayment>[] = [
  {
    name: "Data",
    selector: (row) => moment(row.created_at).format("DD/MM/YY HH:mm"),
  },

  {
    name: "Situação",
    selector: (row) => getPaymentStatusLabel(row.status),
  },

  {
    name: "Forma de pagamento",
    selector: (row) => (row.payment_method ? getPaymentMethodLabel(row.payment_method) : ""),
  },
];

export default orderPaymentsColumns;
