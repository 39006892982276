import { RootState, useAppDispatch } from "@services/Redux";
import { setCustomersFilters } from "@services/Redux/customers";
import { ControlledMenu, MenuItem } from "@szhsin/react-menu";
import { OrderStatus, SelectOption } from "@utils/types";
import moment from "moment";
import * as locales from "react-date-range/dist/locale";
import React from "react";
import { DateRange, RangeKeyDict } from "react-date-range";
import { useSelector } from "react-redux";
import Select from "@components/Select";
import Styled from "./styled";
import { reactSelectStyles } from "@components/Select/configs";

const statusOptions = [
  { value: OrderStatus.CREATED, label: "Criados" },
  { value: OrderStatus.WAITING_SELLER_CONFIRMATION, label: "Em espera" },
  { value: OrderStatus.READY_TO_PAY, label: "Aguardando pagamento" },
  { value: OrderStatus.PAID, label: "Pagos" },
  { value: OrderStatus.CANCELLED, label: "Cancelados" },
] as SelectOption[];

const CustomersFilters: React.FC = () => {
  const filters = useSelector((state: RootState) => state.customers.filters);
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);
  const [datePickerSelectStep, setDatePickerSelectStep] = React.useState(0);
  const datePickerInputRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const selectionRange = {
    startDate: moment(filters.startDate).toDate(),
    endDate: moment(filters.endDate).toDate(),
    key: "selection",
  };

  const handleDateChange = (data: RangeKeyDict) => {
    if (datePickerSelectStep === 1) setIsDatePickerOpen(false);
    dispatch(
      setCustomersFilters({
        ...filters,
        dateRangeSelectStep: datePickerSelectStep,
        startDate: moment(data.selection.startDate).format(),
        endDate: moment(data.selection.endDate).format(),
      }),
    );
    setDatePickerSelectStep(datePickerSelectStep === 0 ? 1 : 0);
  };

  return (
    <div className="col-auto m-0 p-0 mb-2">
      <div className={"row p-0 m-0 mb-lg-0"}>
        <div className={"col-12 col-md-auto p-0 me-0 me-md-2"}>
          <Select
            options={statusOptions}
            onChange={(option) => {
              const selectedOption = option as SelectOption;
              dispatch(setCustomersFilters({ ...filters, status: selectedOption.value }));
            }}
            defaultValue={statusOptions.find((s) => s.value === filters.status)}
            styles={{
              control: (defaultStyles, props) => ({
                ...(reactSelectStyles?.control?.(defaultStyles, props) ?? {}),
                minWidth: 300,
              }),
            }}
          />
        </div>
        <div className={"col-12 col-md-auto p-0 me-0 me-md-2 mt-2 mt-lg-0"}>
          <Styled.DateRangeInput
            ref={datePickerInputRef}
            onClick={() => {
              setIsDatePickerOpen(!isDatePickerOpen);
            }}
          >
            De <b className={"mx-1"}>{moment(filters.startDate).format("DD/MM/YYYY")}</b> até{" "}
            <b className={"mx-1"}>{moment(filters.endDate).format("DD/MM/YYYY")}</b>
          </Styled.DateRangeInput>
          <ControlledMenu state={isDatePickerOpen ? "open" : "closed"} anchorRef={datePickerInputRef}>
            <MenuItem>
              <DateRange ranges={[selectionRange]} onChange={handleDateChange} locale={locales["ptBR"]} />
            </MenuItem>
          </ControlledMenu>
        </div>
      </div>
    </div>
  );
};

export default CustomersFilters;
