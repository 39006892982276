import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PreRegisteredCustomer } from "@utils/types";
import store from ".";
import Api from "@services/Api";
import { toastError } from "@utils/functions";

interface PreRegisteredCustomersFilters {
  searchTerm?: string;
  page?: number;
  perPage?: number;
}

const preRegisteredCustomers = createSlice({
  name: "preRegisteredCustomers",
  initialState: {
    loading: true,
    data: [] as PreRegisteredCustomer[],
    count: 0,
    filters: {
      searchTerm: "",
      page: 1,
      perPage: 10,
    } as PreRegisteredCustomersFilters,
  },

  reducers: {
    setPreRegisteredCustomersFilter(state, action: PayloadAction<PreRegisteredCustomersFilters>) {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPreRegisteredCustomers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPreRegisteredCustomers.fulfilled, (state, action) => {
      console.log(action.payload);
      state.data = action.payload.data;
      state.count = action.payload.count;
      state.loading = false;
    });
    builder.addCase(fetchPreRegisteredCustomers.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default preRegisteredCustomers.reducer;

export const { setPreRegisteredCustomersFilter } = preRegisteredCustomers.actions;

export const fetchPreRegisteredCustomers = createAsyncThunk(
  "preRegisteredCustomers/fetchPreRegisteredCustomers",
  async (typePrefix, { rejectWithValue }) => {
    try {
      const { preRegisteredCustomers } = store.getState();
      const { data } = await Api.get("/company-customers", { params: preRegisteredCustomers.filters });
      return data;
    } catch (error) {
      toastError(error);
      throw rejectWithValue(error);
    }
  }
);
