import FormikTextInput from "@components/FormikTextInput";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import { toastError, toastMessage } from "@utils/functions";
import { Size } from "@utils/types";
import { Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import FullScreenLoading from "@components/FullScreenLoading";

interface NewSizeFormValues {
  name: string;
}

const EditSizePage: React.FC = () => {
  const { size_id } = useParams();
  const companyData = useSelector((state: RootState) => state.company.data);
  const [size, setSize] = React.useState<Size>();
  const [loadingSize, setLoadingSize] = React.useState(true);
  const navigate = useNavigate();

  const handleSubmit = async (values: NewSizeFormValues, actions: FormikHelpers<NewSizeFormValues>) => {
    try {
      actions.setSubmitting(true);
      await Api.patch(`/company/${companyData.id}/size/${size?.id}`, values);
      toastMessage("Seu novo tamanho foi adicionado.", "success");
      navigate(`/configuracoes/tamanhos`);
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  const fetchSize = async () => {
    try {
      const { data } = await Api.get(`/company/${companyData.id}/size/id/${size_id}`);
      setSize(data.size);
      setLoadingSize(false);
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    if (companyData.id) fetchSize();
    // eslint-disable-next-line
  }, [companyData]);

  if (loadingSize || !size) return <FullScreenLoading />;

  const formInitialValues: NewSizeFormValues = {
    name: size.name,
  };

  return (
    <div className={"p-2 rounded-3"} style={{ width: "100%", backgroundColor: "white" }}>
      <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form className={"row"}>
            <div className={"col-12"}>
              <FormikTextInput name="name" mask="" label={"Nome do tamanho"} />
            </div>
            <div className={"col-12 border-top mt-3 pt-3"}>
              <button className={"btn btn-primary"} type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Enviando" : "Enviar"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditSizePage;
