import { useEffect, useState } from "react";
import { useBlingProducts } from "./hooks/use-products.hook";
import { BlingProductsFilters } from "./components/products-filters.component";
import { BlingProduct } from "./interfaces";
import Api from "@services/Api";
import { toastError, toastMessage } from "@utils/functions";
import DataTable, { TableColumn } from "react-data-table-component";
import LoadingIndicator from "@components/LoadingIndicator";
import tableStyles from "@styles/table";
import { Money } from "@utils/money";

export const BlingProductsPage: React.FC = () => {
  const { fetchProducts, products, fetchingProducts } = useBlingProducts();
  const [selectedProducts, setSelectedProducts] = useState<BlingProduct[]>([]);
  const [importingProducts, setImportingProducts] = useState(false);
  const [notImportedProducts, setNotImportedProducts] = useState<number[]>([]);

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectProduct = (product: BlingProduct) => {
    const productAlreadySelected = selectedProducts.find((selectedProduct) => selectedProduct.id === product.id);

    if (productAlreadySelected) {
      setSelectedProducts(selectedProducts.filter((selectedProduct) => selectedProduct.id !== product.id));
    } else {
      setSelectedProducts([...selectedProducts, product]);
    }
  };

  const handleImportProducts = async () => {
    setImportingProducts(true);
    try {
      alert("Importação em andamento, isto pode levar alguns minutos.");
      const { data } = await Api.post(`/integrations/bling/import_products`, {
        product_ids: selectedProducts.map((product) => product.id),
      });
      setNotImportedProducts(data.products_not_imported);
      toastMessage("Produtos importados com sucesso!", "success");
    } catch (error) {
      toastError(error);
    } finally {
      setImportingProducts(false);
    }
  };

  const columns: TableColumn<BlingProduct>[] = [
    {
      name: (
        <input
          type="checkbox"
          checked={selectedProducts.length > 0}
          onClick={() => {
            if (selectedProducts.length > 0) {
              setSelectedProducts([]);
            } else {
              setSelectedProducts(products);
            }
          }}
        />
      ),
      minWidth: "50px",
      maxWidth: "50px",
      cell: (row) => (
        <input
          type="checkbox"
          value={selectedProducts.find((product) => product.id === row.id) ? "true" : "false"}
          checked={selectedProducts.find((product) => product.id === row.id) ? true : false}
          onChange={() => handleSelectProduct(row)}
        />
      ),
      selector: (row) => row.id,
    },
    {
      name: "Id",
      minWidth: "50px",
      maxWidth: "120px",
      selector: (row) => row.id,
    },
    {
      name: "Nome",
      minWidth: "50px",
      selector: (row) => row.nome,
    },
    {
      name: "Código",
      minWidth: "50px",
      selector: (row) => row.codigo,
    },
    {
      name: "Preço",
      minWidth: "50px",
      selector: (row) => Money(row.preco).format(),
    },
  ];

  return (
    <div>
      <BlingProductsFilters onChange={fetchProducts} loading={fetchingProducts} />
      {notImportedProducts.length > 0 && (
        <div className="mt-3 bg-danger p-3 text-white">
          <div>Os seguintes produtos não foram importados:</div>
          <div>
            <ul>
              {notImportedProducts.map((productId) => (
                <li key={productId}>{productId}</li>
              ))}
            </ul>
          </div>
          <div className="mt-3">Verifique as configurações de variações e categorias do produto</div>
        </div>
      )}
      <div className="my-3">
        <DataTable
          columns={columns}
          data={products}
          progressPending={fetchingProducts}
          progressComponent={<LoadingIndicator />}
          customStyles={tableStyles}
          noDataComponent="Sem produtos"
          pagination
          responsive
        />
      </div>
      <button className="btn btn-primary" onClick={handleImportProducts} disabled={importingProducts}>
        {importingProducts ? "Importando..." : "Importar"}
      </button>
    </div>
  );
};
