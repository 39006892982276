import Api from "@services/Api";
import { useAppDispatch } from "@services/Redux";
import { fetchOrders } from "@services/Redux/orders";
import { toastError, toastMessage } from "@utils/functions";
import { Order, OrderStatus } from "@utils/types";
import * as React from "react";
import { confirmAlert } from "react-confirm-alert";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BiEdit, BiTrashAlt } from "react-icons/bi";
import { MdOutlineAttachMoney } from "react-icons/md";
import { useNavigate } from "react-router";
import { Tooltip } from "react-tooltip";

const OrderItemActions: React.FC<{
  order: Order;
  onEditOrderClick: (order: Order) => void;
  onRefreshRequest?: () => void;
}> = ({ order, onEditOrderClick }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isOrderEditable = [
    OrderStatus.CREATED,
    OrderStatus.WAITING_SELLER_CONFIRMATION,
    OrderStatus.READY_TO_PAY,
  ].includes(order.status);
  const isOrderCancellable = [
    OrderStatus.CREATED,
    OrderStatus.WAITING_SELLER_CONFIRMATION,
    OrderStatus.READY_TO_PAY,
  ].includes(order.status);
  const isOrderPayable = [
    OrderStatus.CREATED,
    OrderStatus.IN_ANALYSIS,
    OrderStatus.READY_TO_PAY,
    OrderStatus.WAITING_PAYMENT_CONFIRMATION,
    OrderStatus.WAITING_SELLER_CONFIRMATION,
  ].includes(order.status);

  const handleCancelOrderRequest = async () => {
    try {
      await Api.post(`/order/${order?.id}/cancel`);
      toastMessage(`Pedido cancelado`, "success");
      dispatch(fetchOrders());
    } catch (error) {
      toastError(error);
    }
  };

  const backToCreated = async () => {
    try {
      await Api.post(`/order/${order?.id}/return_to_created`);
      onEditOrderClick(order);
    } catch (error) {
      toastError(error);
    }
  };

  const handleCancelOrderBtnClick = () => {
    confirmAlert({
      title: "Atenção!",
      message: "Deseja cancelar este pedido?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            handleCancelOrderRequest();
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleClickEditButton = () => {
    if (order.status !== OrderStatus.CREATED) {
      confirmAlert({
        title: "Atenção!",
        message:
          "Ao avançar, o status deste pedido será alterado para 'Criado', e o as peças serão devolvidas ao estoque. Você precisará confirmar o pedido novamente. Tem certeza que deseja continuar?",
        buttons: [
          {
            label: "Sim",
            onClick: () => {
              backToCreated();
            },
          },
          {
            label: "Não",
            onClick: () => {},
          },
        ],
      });
    } else {
      onEditOrderClick(order);
    }
  };

  return (
    <div className="row no-gutters">
      <div className="col-auto p-0 m-0" data-tooltip-content="hello world">
        <button
          data-tooltip-content="Abrir whatsapp do cliente"
          id={`${order.id}-open-whatsapp`}
          className={"rounded-circle"}
          onClick={() => {
            window
              .open(`https://api.whatsapp.com/send?phone=55${order.customer_phone?.replace(/\D+/g, "")}`, "_blank")
              ?.focus();
          }}
          style={{
            display: "flex",
            backgroundColor: "white",
            justifyContent: "center",
            alignItems: "center",
            opacity: 1,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#7d7d7d",
            height: 30,
            width: 30,
          }}
        >
          <div>
            <AiOutlineWhatsApp color="black" size={20} />
          </div>
        </button>
      </div>
      <div className="col-auto p-0 m-0 ms-2">
        <button
          data-tooltip-content={isOrderEditable ? `Editar pedido` : `O pedido não pode ser editado neste status`}
          id={`${order.id}-edit-order`}
          className={`rounded-circle`}
          onClick={() => {
            if (!isOrderEditable) return;
            handleClickEditButton();
          }}
          style={{
            backgroundColor: "white",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: isOrderEditable ? 1 : 0.6,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#7d7d7d",
            height: 30,
            width: 30,
          }}
        >
          <div>
            <BiEdit color="black" opacity={isOrderEditable ? 1 : 0.6} size={18} />
          </div>
        </button>
      </div>
      <div className="col-auto p-0 m-0 ms-2">
        <button
          data-tooltip-content={
            isOrderPayable ? "Confirmar pagamento" : "O pedido não pode ser confirmado neste status"
          }
          id={`${order.id}-confirm-order-payment`}
          className={"rounded-circle"}
          onClick={() => {
            if (!isOrderPayable) return;
            navigate(`/pedidos/${order.code}/pagamentos/confirmar`);
            onEditOrderClick(order);
          }}
          style={{
            backgroundColor: "white",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: isOrderPayable ? 1 : 0.6,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#7d7d7d",
            height: 30,
            width: 30,
          }}
        >
          <div>
            <MdOutlineAttachMoney color="black" opacity={isOrderPayable ? 1 : 0.6} size={18} />
          </div>
        </button>
      </div>
      <div className="col-auto p-0 m-0 ms-2">
        <button
          data-tooltip-content={isOrderCancellable ? "Cancelar pedido" : "O pedido não pode ser cancelado neste status"}
          id={`${order.id}-cancel-order`}
          className={"rounded-circle"}
          onClick={() => {
            if (!isOrderCancellable) return;
            handleCancelOrderBtnClick();
          }}
          style={{
            backgroundColor: "white",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: isOrderCancellable ? 1 : 0.6,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#7d7d7d",
            height: 30,
            width: 30,
          }}
        >
          <div>
            <BiTrashAlt color="black" opacity={isOrderCancellable ? 1 : 0.6} size={18} />
          </div>
        </button>
      </div>
      <Tooltip anchorId={`${order.id}-cancel-order`} style={{ width: "auto" }} />
      <Tooltip anchorId={`${order.id}-confirm-order-payment`} style={{ width: "auto" }} />
      <Tooltip anchorId={`${order.id}-edit-order`} style={{ width: "auto" }} />
      <Tooltip anchorId={`${order.id}-open-whatsapp`} style={{ width: "auto" }} />
    </div>
  );
};

export default OrderItemActions;
