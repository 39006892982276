import FormikTextInput from "@components/FormikTextInput";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import { toastError, toastMessage } from "@utils/functions";
import { Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

interface NewSizeFormValues {
  name: string;
}

const formInitialValues: NewSizeFormValues = {
  name: "",
};

const AddSizePage: React.FC = () => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const navigate = useNavigate();

  const handleSubmit = async (values: NewSizeFormValues, actions: FormikHelpers<NewSizeFormValues>) => {
    try {
      actions.setSubmitting(true);
      await Api.post(`/company/${companyData.id}/size`, values);
      toastMessage("Seu novo tamanho foi adicionado.", "success");
      navigate("/configuracoes/tamanhos");
      actions.setSubmitting(true);
    } catch (error) {
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  return (
    <div className={"p-2 rounded-3"} style={{ width: "100%", backgroundColor: "white" }}>
      <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form className={"row"}>
            <div className={"col-12"}>
              <FormikTextInput name="name" mask="" label={"Nome do tamanho"} />
            </div>
            <div className={"col-12 border-top mt-3 pt-3"}>
              <button className={"btn btn-primary"} type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Enviando" : "Enviar"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddSizePage;
