import styled from "styled-components";

const FakeLink = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  height: auto;
`;

export default FakeLink;
