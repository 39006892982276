import * as React from "react";
import { PreRegisteredCustomer } from "@utils/types";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@services/Redux";
import DataTable, { TableColumn } from "react-data-table-component";
import LoadingIndicator from "@components/LoadingIndicator";
import tableStyles from "@styles/table";
import { toastError, toastMessage } from "@utils/functions";
import { useWindowSize } from "@utils/hooks";
import Api from "@services/Api";
import { fetchPreRegisteredCustomers, setPreRegisteredCustomersFilter } from "@services/Redux/pre-registered-customers";
import { MdCheck } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";

const PreRegisteredCustomersPage: React.FC = () => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const {
    data: customers,
    filters,
    count: customersCount,
    loading,
  } = useSelector((state: RootState) => state.preRegisteredCustomers);
  const [searchTerm, setSearchTerm] = React.useState("");
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  const columns: TableColumn<PreRegisteredCustomer>[] = [
    {
      name: "Nome",
      selector: (row) => row.user.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.user.email,
    },
    {
      name: "Telefone",
      selector: (row) => row.user.phone,
    },
    {
      name: "Liberar acesso",
      grow: 0.3,
      cell: (row) => {
        const handleApprove = async (id: string) => {
          try {
            await Api.post(`/company-customers/${id}/approve`);
            toastMessage("Cliente liberado com sucesso!", "success");
            dispatch(fetchPreRegisteredCustomers());
          } catch (error) {
            toastError(error);
          }
        };
        const handleClick = () => {
          confirmAlert({
            title: "Atenção!",
            message:
              "Deseja dar para esse cliente acesso ao seu catálogo? Assim ele poderá ver os preços e realizar pedidos",
            buttons: [
              {
                label: "Sim",
                onClick: () => {
                  handleApprove(row.id);
                },
              },
              {
                label: "Não",
                onClick: () => {},
              },
            ],
          });
        };
        return (
          <div className="col-auto p-0 m-0 ms-2">
            <button
              className={"rounded-circle"}
              onClick={() => {
                handleClick();
              }}
              style={{
                backgroundColor: "white",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                opacity: 1,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#7d7d7d",
                height: 30,
                width: 30,
              }}
            >
              <div>
                <MdCheck color="black" opacity={1} size={18} />
              </div>
            </button>
          </div>
        );
      },
    },
  ];

  const handlePerPageChange = (perPage: number) => {
    dispatch(setPreRegisteredCustomersFilter({ ...filters, perPage }));
  };

  const handlePageChange = (page: number) => {
    dispatch(setPreRegisteredCustomersFilter({ ...filters, page }));
  };

  React.useEffect(() => {
    dispatch(
      setPreRegisteredCustomersFilter({
        page: 1,
        perPage: 10,
        searchTerm: "",
      })
    );
  }, []);

  React.useEffect(() => {
    if (companyData.id) {
      dispatch(fetchPreRegisteredCustomers());
    }
    // eslint-disable-next-line
  }, [companyData, filters.page, filters.perPage, filters.searchTerm]);

  return (
    <div>
      <div className="row m-0 p-0 mb-4">
        <div className="col-auto p-0 m-0">
          <form
            className="col m-0 p-0"
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(setPreRegisteredCustomersFilter({ ...filters, searchTerm, page: 1 }));
            }}
          >
            <div className="row p-0 m-0">
              <div className={"col col-lg-auto p-0 m-0"}>
                <input
                  className={"text-input"}
                  placeholder={"Busque pelo nome dos clientes"}
                  value={searchTerm}
                  style={{ maxWidth: 300, minWidth: width >= 768 ? 400 : 200 }}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                />
              </div>
              <div className={"col-auto p-0 m-0 ms-3"}>
                <button type={"submit"} className={"btn btn-primary"}>
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={customers}
        progressPending={loading}
        progressComponent={<LoadingIndicator />}
        customStyles={tableStyles}
        noDataComponent="Nenhum cliente encontrado"
        pagination
        paginationServer
        paginationPerPage={filters.perPage}
        paginationTotalRows={customersCount}
        onChangeRowsPerPage={handlePerPageChange}
        onChangePage={handlePageChange}
        responsive
      />
    </div>
  );
};

export default PreRegisteredCustomersPage;
