import noImage from "@assets/img/noimage.jpeg";
import FakeLink from "@components/FakeLink";
import LoadingIndicator from "@components/LoadingIndicator";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import tableStyles from "@styles/table";
import { toastError, toastMessage } from "@utils/functions";
import { useWindowSize } from "@utils/hooks";
import { Money } from "@utils/money";
import { FilterActiveProductsOptions, Product } from "@utils/types";
import * as React from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable, { TableColumn } from "react-data-table-component";
import ReactGA from "react-ga4";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "@components/Select";
import { SelectOption } from "@utils/types";

const ListProductsPage: React.FC = () => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [productsCount, setProductsCount] = React.useState(0);
  const [loadingProducts, setLoadingProducts] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [filterActiveProducts, setFilterActiveProducts] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState("");
  const { width } = useWindowSize();
  const [selectedProducts, setSelectedProducts] = React.useState<number[]>([]);

  const filterActiveProductsOptions: SelectOption<FilterActiveProductsOptions>[] = [
    { value: true, label: "Apenas produtos ativos" },
    { value: false, label: "Todos os produtos" },
  ];

  const columns = React.useMemo<TableColumn<Product>[]>(
    () => [
      // Select column
      {
        name: "Selecionar",
        grow: 0,
        cell: (row) => (
          <input
            type="checkbox"
            checked={selectedProducts.includes(row.id)}
            onChange={(e) => {
              console.log(e.target.checked);
              if (e.target.checked) {
                setSelectedProducts([...selectedProducts, row.id]);
              } else {
                setSelectedProducts(selectedProducts.filter((id) => id !== row.id));
              }
            }}
          />
        ),
      },
      {
        name: "Imagem",
        grow: 0,
        cell: (row) => (
          <img
            alt="Product_image"
            style={{ width: 25 }}
            src={row.images[0] ? `${row.images[0].base_url}/products/${row.id}/thumb_1/${row.images[0].key}` : noImage}
          />
        ),
      },
      {
        name: "Código",
        grow: 0,
        selector: (row) => row.ref ?? "",
      },
      {
        name: "Ativo",
        selector: (row) => row.is_active,
        grow: 0,
        cell: (row) => {
          const handleToggle = async () => {
            try {
              await Api.post(`/product/${row.id}/toggle`);
              toastMessage("Produto atualizado com sucesso!", "success");
              setProducts(
                products.map((product) =>
                  product.id === row.id ? { ...product, is_active: !product.is_active } : product,
                ),
              );
            } catch (error) {
              toastError(error);
            }
          };
          return (
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckCheckedDisabled"
                checked={row.is_active}
                onChange={handleToggle}
              />
            </div>
          );
        },
      },

      {
        name: "Nome",
        selector: (row) => row.name,
      },

      {
        name: "Preço",
        selector: (row) => row.price,
        cell: (row) =>
          row.lowest_price !== row.highest_price ? (
            <b>
              {Money(row.lowest_price ?? 0).format()} - {Money(row.highest_price ?? 0).format()}
            </b>
          ) : (
            <b>{Money(row.lowest_price).format()}</b>
          ),
      },

      {
        button: true,
        name: "Ações",
        selector: (row) => row.id,
        cell: (row) => (
          <>
            <Link style={{ textDecoration: "none" }} to={`/produtos/${row.id}/editar`} className={"text-primary me-2"}>
              Editar
            </Link>
            <FakeLink
              onClick={() => {
                handleDeleteProduct(row);
              }}
              className={"text-danger"}
            >
              Excluir
            </FakeLink>
          </>
        ),
      },
    ],
    // eslint-disable-next-line
    [companyData, products, selectedProducts],
  );

  const handleDeleteMultipleProducts = async () => {
    confirmAlert({
      title: "Atenção!",
      message: `Deseja realmente excluir os produtos selecionados?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.post(`/product/multiple/ids`, { product_ids: selectedProducts });
              fetchProducts();
              toastMessage(`Concluído.`, "success");
            } catch (error) {
              toastError(error);
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleDeleteProduct = async (product: Product) => {
    confirmAlert({
      title: "Atenção!",
      message: `Deseja realmente excluir o produto ${product.name}?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.delete(`/product/${product.id}`);
              fetchProducts();
              ReactGA.event({
                action: "delete_product",
                category: "Product",
                label: "Deletou um produto",
              });
              toastMessage(`Concluído.`, "success");
            } catch (error) {
              toastError(error);
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handlePerPageChange = (perPage: number) => {
    setPerPage(perPage);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const fetchProducts = async () => {
    try {
      setLoadingProducts(true);
      const { data } = await Api.get(
        `/product?company_code=${companyData.code}&page=${page}&perPage=${perPage}&search=${encodeURIComponent(
          searchTerm,
        )}&isActiveOnly=${filterActiveProducts}`,
      );
      setProducts(data.products);
      setProductsCount(data.count);
      setLoadingProducts(false);
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    if (companyData.id) fetchProducts();
    // eslint-disable-next-line
  }, [companyData, page, perPage, filterActiveProducts]);

  return (
    <div className="">
      {/* FILTERS */}
      <div className="row m-0 mb-3 align-items-center">
        <form
          className={"col m-0 p-0"}
          onSubmit={(e) => {
            e.preventDefault();
            if (page !== 1) {
              setPage(1);
            } else {
              fetchProducts();
            }
          }}
        >
          <div className="row m-0  justify-content-between justify-content-lg-start">
            <div className={"col-auto p-0 m-0"}>
              <button
                style={{
                  height: 40,
                  width: 40,
                  border: "none",
                  outline: "none",
                  padding: 0,
                  margin: 0,
                  borderRadius: 50,
                  background: "none",
                  backgroundColor: "#2546BB",
                }}
                type="button"
                onClick={() => {
                  navigate("/produtos/cadastrar");
                }}
              >
                <AiOutlinePlus color={"white"} size={"100%"} className="p-0 m-0" />
              </button>
            </div>

            <div className={"col col-lg-auto"}>
              <input
                className={"text-input"}
                placeholder={"Busque por produtos"}
                style={{ maxWidth: width < 768 ? 1000 : 300, minWidth: width >= 768 ? 400 : 200 }}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </div>
            <div className={"col-auto p-0 m-0 "}>
              <button type={"submit"} className={"btn btn-primary"}>
                {width >= 768 ? "Buscar" : <AiOutlineSearch size={24} />}
              </button>
            </div>
            {companyData.bling_integration?.is_authorized && (
              <div className={"col-12 col-md p-0 m-0 d-flex justify-content-end"}>
                <Link
                  to={`/configuracoes/integracoes/bling/produtos`}
                  className="btn btn-primary col-12 col-md-auto mt-2 mt-md-0 d-flex justify-content-center align-items-center"
                >
                  Importar do bling
                </Link>
              </div>
            )}
          </div>
        </form>
      </div>

      {/* FILTERS */}
      <div className="d-flex flex-row ">
        <div className={"bg-white p-3 rounded-3 mb-3 w-100 "}>
          <h5> Filtrar</h5>
          <div className="col-5">
            <Select
              defaultValue={filterActiveProductsOptions.find((option) => option.value === false)}
              options={filterActiveProductsOptions}
              onChange={(option) => {
                const selectedOption = option as SelectOption<FilterActiveProductsOptions>;
                setFilterActiveProducts(selectedOption.value);
              }}
            />
          </div>
        </div>
      </div>
      {/* TABLE */}
      <div className={"col-12"}>
        {selectedProducts.length > 0 && (
          <div className="d-flex justify-content-start mb-3">
            <button className="btn btn-danger btn-sm" onClick={handleDeleteMultipleProducts}>
              Excluir produtos selecionados
            </button>
          </div>
        )}
        <DataTable
          columns={columns}
          data={products}
          progressPending={loadingProducts}
          progressComponent={<LoadingIndicator />}
          customStyles={tableStyles}
          noDataComponent="Você ainda não cadastrou nenhum produto"
          pagination
          paginationServer
          paginationPerPage={perPage}
          paginationTotalRows={productsCount}
          onChangeRowsPerPage={handlePerPageChange}
          onChangePage={handlePageChange}
          responsive
        />
      </div>
    </div>
  );
};

export default ListProductsPage;
