import LoadingIndicator from "@components/LoadingIndicator";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import tableStyles from "@styles/table";
import { toastError, toastMessage } from "@utils/functions";
import { useWindowSize } from "@utils/hooks";
import { Money } from "@utils/money";
import { Coupon } from "@utils/types";
import moment from "moment";
import * as React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { AiOutlinePlus } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const ListCouponsPage: React.FC = () => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const [coupons, setCoupons] = React.useState<Coupon[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const columns = React.useMemo<TableColumn<Coupon>[]>(
    () => [
      {
        name: "Código",
        selector: (row) => row.code,
      },
      {
        name: "Uso",
        selector: (row) => row.usage_count,
        cell: (row) => `${row.usage_count} / ${row.conditions.usage_limit ?? "∞"}`,
      },
      {
        name: "Expira em",
        selector: (row) =>
          row.conditions.expires_at ? moment(row.conditions.expires_at, "DD/MM/YYYY").unix() : moment().unix(),
        cell: (row) =>
          `${row.conditions.expires_at ? moment(row.conditions.expires_at, "DD/MM/YYYY").format("DD/MM/YYYY") : "∞"}`,
      },
      {
        name: "Desconto (%)",
        selector: (row) => `${row.discount_percentage ?? 0}%`,
      },
      {
        name: "Desconto (R$)",
        selector: (row) => row.discount_fixed_value ?? 0,
        cell: (row) => Money(row.discount_fixed_value ?? 0).format(),
      },
      {
        name: "Ativo",
        selector: (row) => row.active,
        grow: 0,
        cell: (row) => {
          const handleToggle = async () => {
            try {
              await Api.patch(`/company/${companyData.id}/coupon/${row.id}/toggle`);
              toastMessage("Cupom atualizado com sucesso!", "success");
              setCoupons((prev) =>
                prev.map((coupon) => (coupon.id === row.id ? { ...coupon, active: !coupon.active } : coupon)),
              );
            } catch (error) {
              toastError(error);
            }
          };
          return (
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckCheckedDisabled"
                checked={row.active}
                onChange={handleToggle}
              />
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [companyData],
  );

  const fetchCoupons = async () => {
    try {
      setLoading(true);
      const { data } = await Api.get(`/company/${companyData.id}/coupon?search=${searchTerm}`);
      setCoupons(data.coupons);
      setLoading(false);
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    if (companyData.id) fetchCoupons();
    // eslint-disable-next-line
  }, [companyData]);

  return (
    <div>
      <div className={"row p-0 m-0 align-items-center mb-4"}>
        <div className={"col-auto p-0 m-0"}>
          <button
            style={{
              height: 40,
              width: 40,
              border: "none",
              outline: "none",
              padding: 0,
              margin: 0,
              borderRadius: 50,
              background: "none",
              backgroundColor: "#2546BB",
            }}
            onClick={() => {
              navigate("/cupons/cadastrar");
            }}
          >
            <AiOutlinePlus color={"white"} size={"100%"} className="p-0 m-0" />
          </button>
        </div>
        <form
          className={"col"}
          onSubmit={(e) => {
            e.preventDefault();
            fetchCoupons();
          }}
        >
          <div className="row">
            <div className={"col col-lg-auto"}>
              <input
                className={"text-input"}
                placeholder={"Busque por código"}
                value={searchTerm}
                style={{ maxWidth: 300, minWidth: width >= 768 ? 400 : 200 }}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </div>
            <div className={"col-auto p-0 m-0 "}>
              <button type={"submit"} className={"btn btn-primary"}>
                Buscar
              </button>
            </div>
          </div>
        </form>
      </div>
      <DataTable
        columns={columns}
        data={coupons}
        progressPending={loading}
        progressComponent={<LoadingIndicator />}
        customStyles={tableStyles}
        noDataComponent="Você ainda não cadastrou nenhum cupom"
        pagination
        responsive
      />
    </div>
  );
};

export default ListCouponsPage;
