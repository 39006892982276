import podOrganizar from "@assets/img/podorganizar.png";

export const PodOrganizarIntegrationCard: React.FC = () => {
  return (
    <div className="d-flex">
      <div
        className={"p-0 m-0 rounded-3 d-flex flex-column align-items-center justify-content-center"}
        style={{ width: 200, backgroundColor: "white" }}
      >
        <div className="mt-3 ">
          <img src={podOrganizar} width={150} alt="Logo" />
        </div>
      </div>
    </div>
  );
};
