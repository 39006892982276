import InfoCard from "@components/InfoCard";
import Select from "@components/Select";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import { generateDarkColorHex, toastError } from "@utils/functions";
import { Money } from "@utils/money";
import { SelectOption } from "@utils/types";
import moment from "moment";
import React from "react";
import { BsClipboardData } from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";
import { useSelector } from "react-redux";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const intervalOptions = [
  { value: moment().subtract(30, "days"), label: "Últimos 30 dias" },
  { value: moment().subtract(60, "days"), label: "Últimos 60 dias" },
  { value: moment().subtract(6, "months"), label: "Últimos 6 meses" },
  { value: moment().subtract(1, "year"), label: "Último ano" },
  { value: moment().startOf("year"), label: "Este ano" },
] as SelectOption[];

interface CompanySellersWithMetrics {
  id: number;
  name: string;
  strokeColor: string;
  paid_sales_count: number;
  paid_sales_value: number;
  paid_sales_series: { date: string; count: number; value: number }[];
}

export const ReportsPage = () => {
  const [startDate, setStartDate] = React.useState(intervalOptions[0].value);
  const [loadingMetrics, setLoadingMetrics] = React.useState(true);
  const [chartData, setChartData] = React.useState<CompanySellersWithMetrics[]>([] as CompanySellersWithMetrics[]);
  const [allSellersMetrics, setAllSellersMetrics] = React.useState<CompanySellersWithMetrics[]>(
    [] as CompanySellersWithMetrics[],
  );
  const [selectedSeller, setSelectedSeller] = React.useState<CompanySellersWithMetrics | null>(null);
  const [sellers, setSellers] = React.useState<SelectOption[]>([] as SelectOption[]);
  const company = useSelector((state: RootState) => state.company);

  const SalesValueBySeller = chartData.map((seller) => ({
    name: seller.name,
    strokeColor: seller.strokeColor,
    data: seller.paid_sales_series.map((s) => ({
      category: s.date,
      value: s.value,
    })),
  }));

  const SalesQuantityBySeller = chartData.map((seller) => ({
    name: seller.name,
    strokeColor: seller.strokeColor,
    data: seller.paid_sales_series.map((s) => ({
      category: s.date,
      value: s.count,
    })),
  }));

  const fetchMetricsAndColorizeLine = async () => {
    try {
      setLoadingMetrics(true);
      const { data } = await Api.get(
        `/metrics/seller_sales/${company.data.id}/?startDate=${encodeURIComponent(
          startDate.format("DD/MM/YYYY"),
        )}&endDate=${encodeURIComponent(moment().format("DD/MM/YYYY"))}`,
      );
      data.result.forEach((seller: CompanySellersWithMetrics) => {
        seller.strokeColor = generateDarkColorHex();
      });
      setSellers([
        { value: null, label: "Todos" },
        ...data.result.map((seller: CompanySellersWithMetrics) => ({ value: seller, label: seller.name })),
      ]);
      setChartData(data.result);
      setAllSellersMetrics(data.result);
      setLoadingMetrics(false);
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    if (!company.data.id) return;
    fetchMetricsAndColorizeLine();
  }, []);

  React.useEffect(() => {
    setSelectedSeller(null);
    fetchMetricsAndColorizeLine();
  }, [startDate]);

  React.useEffect(() => {
    if (!selectedSeller) return setChartData(allSellersMetrics);
    setChartData(allSellersMetrics.filter((seller) => seller.id === selectedSeller?.id));
  }, [selectedSeller]);

  return (
    <div className="row m-0">
      <div className={"col-12 col-md-auto p-0 me-0 mx-md-2 mb-2 mb-md-0 "}>
        <Select
          options={intervalOptions}
          defaultValue={intervalOptions[0]}
          onChange={(value) => {
            const option = value as SelectOption;
            setStartDate(intervalOptions.find((d) => d.value === option.value)?.value ?? intervalOptions[0].value);
          }}
        />
      </div>
      <div className={"col-12 col-md-auto p-0 me-0 me-md-2"}>
        <Select
          options={sellers}
          defaultValue={{ value: null, label: "Todos" }}
          onChange={(value) => {
            const option = value as SelectOption;
            setSelectedSeller(option.value);
          }}
        />
      </div>
      <div className="w-100 d-none d-lg-block" />
      <div className={"col-12 col-lg-6 my-2 my-lg-0 p-0 p-lg-2"}>
        <InfoCard
          title={`Pedidos pagos ` + (selectedSeller ? `(${selectedSeller.name})` : "")}
          description={chartData.reduce((total, seller) => total + seller.paid_sales_count, 0)}
          color="#2546BB"
          icon={<BsClipboardData />}
          loading={loadingMetrics}
        />
      </div>
      <div className={"col-12 col-lg-6 my-2 my-lg-0 p-0 p-lg-2"}>
        <InfoCard
          title={`Total em pedidos pagos ` + (selectedSeller ? `(${selectedSeller.name})` : "")}
          description={Money(
            chartData.reduce((total, seller) => Money(total).add(seller.paid_sales_value).value, 0),
          ).format()}
          color="#64de57"
          icon={<MdAttachMoney />}
          loading={loadingMetrics}
        />
      </div>
      <div className={"col-12 col-lg-6 my-2 my-lg-0 p-0 p-lg-2"}>
        <div className={"bg-white p-2 rounded-3"}>
          <h5>Valor de vendas por vendedor {selectedSeller && `(${selectedSeller.name})`}</h5>
          <ResponsiveContainer width={"100%"} height={400} className={"bg-white"}>
            <LineChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="category"
                type="category"
                tickFormatter={(tick) => moment(tick, "DD/MM/YYYY").format("DD/MM")}
                allowDuplicatedCategory={false}
              />
              <YAxis dataKey="value" />
              <Tooltip formatter={(value: any) => Money(value).format()} />
              {SalesValueBySeller.map((s) => (
                <Line type="monotone" dataKey="value" data={s.data} name={s.name} key={s.name} stroke={s.strokeColor} />
              ))}
              <YAxis />
              <Legend />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className={"col-12 col-lg-6 my-2 my-lg-0 p-0 p-lg-2"}>
        <div className={"bg-white p-2 rounded-3"}>
          <h5>Quantidade de vendas por vendedor {selectedSeller && `(${selectedSeller.name})`}</h5>
          <ResponsiveContainer width={"100%"} height={400}>
            <LineChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="category"
                type="category"
                tickFormatter={(tick) => moment(tick, "DD/MM/YYYY").format("DD/MM")}
                allowDuplicatedCategory={false}
              />
              <YAxis dataKey="value" allowDecimals={false} />
              <Tooltip />
              <Legend />
              {SalesQuantityBySeller.map((s) => (
                <Line type="monotone" dataKey="value" data={s.data} name={s.name} key={s.name} stroke={s.strokeColor} />
              ))}
              <YAxis />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
