import authImage from "@assets/img/auth.svg";
import Api from "@services/Api";
import { toastError, toastMessage } from "@utils/functions";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const BlingAuthRedirect: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      setCode(code);
    }
  }, [searchParams]);

  useEffect(() => {
    if (code) {
      sendToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const sendToken = async () => {
    try {
      await Api.post(`/integrations/bling/authorize`, { code });
      toastMessage(`Integração realizada com sucesso!`, "success");
      navigate("/configuracoes/integracoes");
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <div className="d-flex w-100 align-items-center justify-content-center flex-column vh-100">
      <img src={authImage} alt="Autenticando..." className="w-25 mb-5" />
      <h2>Autenticando...</h2>
      <div>Code: {code}</div>
    </div>
  );
};
