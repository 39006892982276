import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "@services/Api";
import { toastError } from "@utils/functions";
import { Order, OrderByOptions, OrderStatus } from "@utils/types";
import moment from "@utils/moment";
import store from ".";

interface OrderFilters {
  status: OrderStatus;
  startDate: string;
  endDate: string;
  page: number;
  perPage: number;
  dateRangeSelectStep: number;
  orderBy: OrderByOptions;
  sort: "ASC" | "DESC";
}

const orders = createSlice({
  name: "orders",
  initialState: {
    loading: true,
    data: [] as Order[],
    count: 0,
    filters: {
      status: OrderStatus.WAITING_SELLER_CONFIRMATION,
      startDate: moment().subtract(1, "month").format(),
      endDate: moment().format(),
      sort: "DESC",
      orderBy: "created_at",
      dateRangeSelectStep: 0,
      page: 1,
      perPage: 10,
    } as OrderFilters,
    selected: [] as Order[],
  },
  reducers: {
    setOrdersFilters(state, action: PayloadAction<OrderFilters>) {
      state.filters = action.payload;
    },
    selectOrder(state, action: PayloadAction<Order>) {
      if (!state.selected.find((order) => order.id === action.payload.id)) {
        state.selected.push(action.payload);
      }
    },
    unselectOrder(state, action: PayloadAction<Order>) {
      state.selected = state.selected.filter((order) => order.id !== action.payload.id);
    },
    selectAllOrders(state) {
      state.selected = state.data;
    },
    unselectAllOrders(state) {
      state.selected = [];
    },
    selectOrders(state, action: PayloadAction<Order[]>) {
      action.payload.forEach((order) => {
        if (!state.selected.find((selectedOrder) => selectedOrder.id === order.id)) {
          state.selected.push(order);
        }
      });
    },
    unselectOrders(state, action: PayloadAction<Order[]>) {
      state.selected = state.selected.filter(
        (selectedOrder) => !action.payload.find((order) => order.id === selectedOrder.id)
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.pending, (state, _action) => {
      state.loading = true;
    });
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.data = action.payload.orders;
      state.count = action.payload.count;
      state.loading = false;
    });
    builder.addCase(fetchOrders.rejected, (state, _action) => {
      state.loading = false;
    });
  },
});

export default orders.reducer;

export const {
  setOrdersFilters,
  selectOrder,
  selectOrders,
  unselectOrder,
  unselectOrders,
  selectAllOrders,
  unselectAllOrders,
} = orders.actions;

export const fetchOrders = createAsyncThunk("orders", async (typePrefix, { rejectWithValue }) => {
  try {
    const { company, orders } = store.getState();
    const { page, perPage, startDate, endDate, status, orderBy, sort } = orders.filters;
    const url = `/order`;
    const query = `?company_code=${
      company.data?.code
    }&status=${status}&order_by=${orderBy}&sort=${sort}&page=${page}&perPage=${perPage}&startDate=${encodeURIComponent(
      moment(startDate).format("DD/MM/YYYY")
    )}&endDate=${encodeURIComponent(moment(endDate).format("DD/MM/YYYY"))}`;
    const { data } = await Api.get(url + query);
    return data;
  } catch (error) {
    toastError(error);
    throw rejectWithValue(error);
  }
});
