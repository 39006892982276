import FormikTextInput from "@components/FormikTextInput";
import FullScreenLoading from "@components/FullScreenLoading";
import { QuestionToolTip } from "@components/QuestionToolTip/question-tooltip";
import Select from "@components/Select";
import SortableSelect from "@components/SortableSelect";
import { TooltipTexts } from "@pages/Products/tooltip-texts";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import { arrayMove, toastError } from "@utils/functions";
import { Category, Color, Product, SelectOption, Size } from "@utils/types";
import { Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import CurrencyInput from "react-currency-input-field";
import { useSelector } from "react-redux";

interface AddProductFormProps {
  successCallback?: (product: Product) => void;
  onVariationsDataChange: (variations: { colors: SelectOption[]; sizes: SelectOption[] }) => void;
  stockData: { color_id: number; size_id: number; quantity: number }[];
  stockEnabled: boolean;
}

interface NewProductFormValues {
  name: string;
  description?: string;
  price: number;
  ref: string;
}

const AddProductForm: React.FC<AddProductFormProps> = ({
  successCallback,
  onVariationsDataChange,
  stockEnabled,
  stockData,
}) => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const [categories, setCategories] = React.useState<Category[]>([]);
  const [sizes, setSizes] = React.useState<Size[]>([]);
  const [colors, setColors] = React.useState<Color[]>([]);
  const [loadingData, setLoadingData] = React.useState(true);
  const [selectedCategories, setSelectedCategories] = React.useState<SelectOption[]>([]);
  const [selectedColors, setSelectedColors] = React.useState<SelectOption[]>([]);
  const [selectedSizes, setSelectedSizes] = React.useState<SelectOption[]>([]);

  /* Logic functions */
  const fetchData = async () => {
    try {
      const { data: categoriesData } = await Api.get("/category");
      const { data: sizesData } = await Api.get(`/company/${companyData.id}/size`);
      const { data: colorsData } = await Api.get(`/company/${companyData.id}/color`);
      setCategories(categoriesData.categories);
      setSizes(sizesData.sizes);
      setColors(colorsData.colors);
      setLoadingData(false);
    } catch (error) {
      toastError(error);
    }
  };

  const handleSubmit = async (values: NewProductFormValues, actions: FormikHelpers<NewProductFormValues>) => {
    let createdProduct = null;
    try {
      actions.setSubmitting(true);
      const variations: {
        color_id: number;
        size_id: number;
        price: number;
        stock?: number;
        size_priority: number;
        color_priority: number;
      }[] = [];
      selectedColors.forEach((color, colorIndex) => {
        selectedSizes.forEach((size, sizeIndex) => {
          variations.push({
            color_id: color.value,
            size_id: size.value,
            price: values.price,
            stock:
              stockData.find((stock) => stock.color_id === +color.value && stock.size_id === +size.value)?.quantity ??
              0,
            size_priority: sizeIndex,
            color_priority: colorIndex,
          });
        });
      });
      const { data } = await Api.post(`/product`, {
        ...values,
        categories: selectedCategories.map((c) => c.value),
        stock_enabled: stockEnabled,
        is_active: true,
      });
      createdProduct = data.product;
      if (variations.length > 0) {
        await Api.post(`/product/${data.product.id}/variation/batch`, { variations });
      }
      successCallback?.(createdProduct);
    } catch (error) {
      if (createdProduct) Api.delete(`/product/${createdProduct?.id}`);
      actions.setSubmitting(true);
      toastError(error);
    }
  };

  /* Building select options */

  const categoriesOptions = React.useMemo(() => {
    let options: SelectOption[] = [];
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      options.push({ label: category.name, value: category.id });
      category.children.forEach((children) => {
        options.push({ label: `${category.name} - ${children.name}`, value: children.id });
        children.children.forEach((grandChildren) => {
          options.push({
            label: `${category.name} - ${children.name} - ${grandChildren.name}`,
            value: grandChildren.id,
          });
        });
      });
    }
    return options;
  }, [categories]);

  const colorsOptions = React.useMemo(
    () => colors.map((c) => ({ label: c.name, value: c.id }) as SelectOption),
    [colors],
  );
  const sizesOptions = React.useMemo(() => sizes.map((c) => ({ label: c.name, value: c.id }) as SelectOption), [sizes]);

  React.useEffect(() => {
    if (companyData.id) fetchData();
    // eslint-disable-next-line
  }, [companyData]);

  React.useEffect(() => {
    onVariationsDataChange({ colors: selectedColors, sizes: selectedSizes });
    // eslint-disable-next-line
  }, [selectedColors, selectedSizes]);

  const formInitialValues = React.useMemo(() => {
    const initialValues: NewProductFormValues = {
      ref: "",
      name: "",
      description: "",
      price: 0,
    };
    return initialValues;
  }, []);

  if (loadingData) return <FullScreenLoading />;

  return (
    <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, setFieldValue, values }) => (
        <Form className={"row"}>
          <div className={"col-12"}>
            <FormikTextInput name="ref" mask="" tooltipMessage={TooltipTexts.productCode} label={"Código do produto"} />
          </div>
          <div className={"col-12 mt-3"}>
            <FormikTextInput name="name" mask="" label={"Nome do produto"} />
          </div>
          <div className={"col-12 mt-3"}>
            <label>Preço</label>
            <CurrencyInput
              defaultValue={values.price}
              className="form-control"
              style={{
                outline: "none",
                border: "1px solid #B6B6B6",
                height: "40px",
                borderRadius: "8px",
                padding: "10px 15px",
              }}
              intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              decimalSeparator={","}
              groupSeparator={"."}
              onValueChange={(e) => {
                setFieldValue("price", e?.replace(",", "."));
              }}
            />
          </div>
          <div className={"col-12 mt-3"}>
            <label>Descrição do produto (opcional)</label>
            <textarea
              placeholder=" "
              className={"form-control form-control-lg"}
              style={{
                fontSize: 16,
                outline: "none",
                border: "1px solid #B6B6B6",
                width: "100%",
                height: "40px",
                borderRadius: "8px",
                padding: "10px 15px",
                minHeight: 100,
              }}
              onChange={(e) => {
                setFieldValue("description", e.target.value);
              }}
              value={values.description}
            ></textarea>
          </div>
          <div className={"col-12 mt-3"}>
            <label>Categorias</label>
            <QuestionToolTip message={TooltipTexts.categories} id="categories" />
            <Select
              options={categoriesOptions}
              isMulti
              closeMenuOnSelect={false}
              onChange={(e: any) => {
                setSelectedCategories(e);
              }}
              placeholder={"Selecione as categorias"}
              styles={{
                control: (base) => ({
                  ...base,
                  border: "1px solid #B6B6B6 !important",
                  borderRadius: "8px !important",
                }),
              }}
            />
          </div>
          <div className={"col-12 mt-3"}>
            <label>Cores</label>
            <QuestionToolTip message={TooltipTexts.colors} id="colors" />
            <SortableSelect
              options={colorsOptions}
              isMulti
              closeMenuOnSelect={false}
              onChange={(e: any) => {
                setSelectedColors(e);
              }}
              onSortEnd={({ newIndex, oldIndex }) => {
                const newArray = arrayMove(selectedColors, oldIndex, newIndex);
                setSelectedColors(newArray);
              }}
              value={selectedColors}
              placeholder={"Selecione as cores"}
              styles={{
                control: (base) => ({
                  ...base,
                  border: "1px solid #B6B6B6 !important",
                  borderRadius: "8px !important",
                }),
              }}
            />
          </div>
          <div className={"col-12 mt-3"}>
            <label>Tamanhos</label>
            <QuestionToolTip message={TooltipTexts.sizes} id="sizes" />
            <SortableSelect
              options={sizesOptions}
              isMulti
              closeMenuOnSelect={false}
              onChange={(e: any) => {
                setSelectedSizes(e);
              }}
              onSortEnd={({ newIndex, oldIndex }) => {
                const newArray = arrayMove(selectedSizes, oldIndex, newIndex);
                setSelectedSizes(newArray);
              }}
              value={selectedSizes}
              placeholder={"Selecione os tamanhos"}
              styles={{
                control: (base) => ({
                  ...base,
                  border: "1px solid #B6B6B6 !important",
                  borderRadius: "8px !important",
                }),
              }}
            />
          </div>
          <div className={"col-12 border-top mt-3 pt-3"}>
            <button className={"btn btn-primary"} type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Enviando" : "Enviar"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddProductForm;
