import DropImageInput from "@components/DropImageInput";
import { QuestionToolTip } from "@components/QuestionToolTip/question-tooltip";
import Api from "@services/Api";
import { RootState, useAppDispatch } from "@services/Redux";
import { fetchCompany } from "@services/Redux/company";
import { toastError, toastMessage } from "@utils/functions";
import { Image, UserRole } from "@utils/types";
import * as React from "react";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import GeneralSettings from "./General";
import Styled from "./styled";

const SettingsPage: React.FC = () => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const dispatch = useAppDispatch();
  const [logoUrl, setLogoUrl] = React.useState("");
  const [requestingLogoUpload, setRequestingLogoUpload] = React.useState(false);
  const [requestingBannerUpdate, setRequestingBannerUpdate] = React.useState(false);
  const userRole = localStorage.getItem("user_role");

  const handleLogoChange = async (files: File[]) => {
    try {
      setRequestingLogoUpload(true);
      let file = files[0];
      const formData = new FormData();
      formData.append("files", file);
      await Api.post(`/company/${companyData.id}/images/logo`, formData);
      dispatch(fetchCompany(`${companyData.code}`));
      toastMessage("Sua nova logo foi adicionada.", "success");
      setRequestingLogoUpload(false);
    } catch (error) {
      setRequestingLogoUpload(false);
      toastError(error);
    }
  };

  const handleBannerChange = async (files: File[]) => {
    try {
      setRequestingBannerUpdate(true);
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      await Api.post(`/company/${companyData.id}/banner_images`, formData);
      dispatch(fetchCompany(`${companyData.code}`));
      toastMessage("Banner atualizado.", "success");
      setRequestingBannerUpdate(false);
    } catch (error) {
      setRequestingBannerUpdate(false);
      toastError(error);
    }
  };

  const confirmBannerImageDelete = async (image: Image) => {
    confirmAlert({
      title: "Excluir imagem",
      message: "Tem certeza que deseja excluir essa imagem?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            handleDeleteBannerImage(image);
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleDeleteBannerImage = async (bannerImage: Image) => {
    try {
      setRequestingLogoUpload(true);
      await Api.delete(`/company/${companyData.id}/banner_image/${bannerImage.id}`);
      dispatch(fetchCompany(`${companyData.code}`));
      toastMessage("Imagem removida.", "success");
      setRequestingLogoUpload(false);
    } catch (error) {
      setRequestingLogoUpload(false);
      toastError(error);
    }
  };

  React.useEffect(() => {
    if (companyData.logo_image)
      setLogoUrl(
        `${companyData.logo_image.base_url}/companies/${companyData.id}/media/thumb_2/${companyData.logo_image.key}`,
      );
  }, [companyData, logoUrl]);

  return (
    <div className={"row m-0 p-0"}>
      <div className={"col-lg-4 p-2"}>
        <div className={"bg-white p-3 h-100 rounded-3"}>
          <div className="d-flex flex-row align-content-center">
            <h4>Logomarca</h4>
            <QuestionToolTip
              id="logo"
              message="Recomendamos que você utilize uma imagem quadrada (proporção 1:1) para melhor visualização da logomarca."
            />
          </div>
          <p style={{ fontSize: 14 }}>Você pode adicionar aqui a logo da sua marca.</p>
          <DropImageInput
            className={"mt-2"}
            imageUrl={
              companyData.logo_image
                ? `${companyData.logo_image.base_url}/companies/${companyData.id}/media/thumb_2/${companyData.logo_image.key}`
                : ""
            }
            onFileSelect={handleLogoChange}
            placeholder={"Arraste uma imagem para cá ou clique aqui para selecionar uma imagem"}
            loading={requestingLogoUpload}
          />
        </div>
      </div>
      <div className={"col-lg-8 p-2"}>
        <div className={"bg-white p-3 h-100 rounded-3"}>
          <div className="d-flex flex-row">
            <h4>Banners</h4>
            <QuestionToolTip
              id="banner"
              message="Um tamanho de 800px x 300 px é ideal para os banners, porém diversos tamanhos podem funcionar, preferencialmente na proporção de 8:3."
            />
          </div>
          <p style={{ fontSize: 14 }}>
            Você pode adicionar aqui banners rotativos que serão exibidos para os seus clientes em um carrossel acima
            dos produtos no seu catálogo.
          </p>
          <div className={"row"}>
            <div className={"col-auto me-3 mb-3"}>
              <DropImageInput
                className={"mt-2"}
                onFileSelect={handleBannerChange}
                placeholder={"Arraste uma imagem para cá ou clique aqui para selecionar uma imagem"}
                loading={requestingBannerUpdate}
              />
            </div>
            {companyData?.banners?.map((banner, index) => {
              const imageUrl = `${banner.base_url}/companies/${companyData.id}/media/thumb_2/${banner.key}`;
              return (
                <div key={index} className={"col-auto m-0 me-3 mb-3"}>
                  <Styled.BannerImageContainer
                    style={{
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url(${imageUrl})`,
                      backgroundPosition: "center",
                    }}
                  >
                    <Styled.BannerImageActionsContainer>
                      <button
                        className="btn btn-primary"
                        onClick={async (e) => {
                          e.preventDefault();
                          confirmBannerImageDelete(banner);
                        }}
                      >
                        excluir
                      </button>
                    </Styled.BannerImageActionsContainer>
                  </Styled.BannerImageContainer>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {userRole === UserRole.COMPANY_ADMIN ? <GeneralSettings /> : null}
    </div>
  );
};

export default SettingsPage;
