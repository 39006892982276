import { QuestionToolTip } from "@components/QuestionToolTip/question-tooltip";
import { TooltipTexts } from "@pages/Products/tooltip-texts";
import { RootState, useAppDispatch } from "@services/Redux";
import { setPriceData } from "@services/Redux/editProductFormData";
import { uniqueBy } from "@utils/functions";
import * as React from "react";
import { useSelector } from "react-redux";
import Styled from "./styled";

const VariationPriceForm: React.FC = () => {
  const { priceData, variationsData } = useSelector((state: RootState) => state.editProductFormData);
  const colors = React.useMemo(() => uniqueBy("value", variationsData.colors), [variationsData]);
  const sizes = React.useMemo(() => uniqueBy("value", variationsData.sizes), [variationsData]);
  const dispatch = useAppDispatch();

  const handleInputChange = (id: string, value: number) => {
    const [color_id, size_id] = id.split("-");
    const newPriceData = [...priceData.map((p) => ({ ...p }))];
    const index = newPriceData.findIndex(
      (variation) => variation.color_id === Number(color_id) && variation.size_id === Number(size_id),
    );
    if (index !== -1) {
      newPriceData[index].price = Number(value);
    }
    dispatch(setPriceData(newPriceData));
  };

  return (
    <Styled.RootContainer>
      <div>
        <label>Preços</label>
        <QuestionToolTip message={TooltipTexts.prices} id="prices" />
      </div>
      <Styled.GridTable>
        <thead>
          <tr>
            <td></td>
            {sizes.map((size) => (
              <td key={size.value}>{size.label}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {colors.map((color) => (
            <tr key={color.value}>
              <td style={{ fontSize: 12 }}>{color.label}</td>
              {sizes.map((size) => {
                return (
                  <td key={size.value}>
                    <Styled.Input
                      defaultValue={
                        priceData.find((p) => p.color_id === color.value && p.size_id === size.value)?.price ?? 0
                      }
                      intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                      decimalSeparator={","}
                      groupSeparator={"."}
                      decimalScale={2}
                      onValueChange={(value, name) => {
                        handleInputChange(
                          `${color.value}-${size.value}-input`,
                          parseFloat(value?.replace(",", ".") || "0"),
                        );
                      }}
                    />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Styled.GridTable>
    </Styled.RootContainer>
  );
};

export default VariationPriceForm;
