import { Navigate } from "react-router-dom";

interface Props {
  component: React.ComponentType;
  path?: string;
}

const PrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  let redirectPath: string = "";
  if (!localStorage.getItem("user_token")) {
    redirectPath = "/login";
  } else {
    redirectPath = "";
  }

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  } else {
    return <RouteComponent />;
  }
};

export default PrivateRoute;
