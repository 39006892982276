import styled from "styled-components";

const RootContainer = styled.div`
  overflow: auto;
`;

const GridTable = styled.table`
  width: 100%;
  table-layout: fixed;
  background-color: white;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
  thead {
    background-color: #ececec;
  }
  td {
    padding: 0px;
    text-align: center;
    font-size: 14px;
    height: 46px;
    min-height: 46px;
    max-height: 46px;
    width: 64px;
    min-width: 64px;
    max-width: 64px;
    white-space: normal;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  text-align: center;
  outline: none;
  font-weight: bold;
  font-size: 18px;
  ::placeholder {
    font-weight: normal;
  }
  :disabled {
    background-color: #ececec;
  }
`;

const ColorImage = styled.img`
  object-fit: cover;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  overflow: hidden;
`;

const modules = {
  GridTable,
  RootContainer,
  Input,
  ColorImage,
};

export default modules;
