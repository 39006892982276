import { reactSelectStyles, reactSelectTheme } from "@components/Select/configs";
import { SelectOption } from "@utils/types";
import React, { MouseEventHandler } from "react";
import Select, { components, MultiValueGenericProps, MultiValueProps, Props } from "react-select";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from "react-sortable-hoc";

interface SelectProps extends Props<SelectOption, boolean> {
  options: SelectOption[];
  onSortEnd?: SortEndHandler;
}

const SortableMultiValue = SortableElement((props: MultiValueProps<SelectOption>) => {
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle((props: MultiValueGenericProps) => (
  <components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(Select) as React.ComponentClass<
  Props<SelectOption, true> & SortableContainerProps
>;

const MultiSelectSort: React.FC<SelectProps> = (props) => {
  return (
    <SortableSelect
      useDragHandle
      axis="xy"
      distance={4}
      theme={reactSelectTheme}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      components={{
        // @ts-ignore We're failing to provide a required index prop to SortableElement
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel as any,
      }}
      closeMenuOnSelect={false}
      {...props}
      styles={{ ...reactSelectStyles, ...props.styles }}
    />
  );
};

export default MultiSelectSort;
