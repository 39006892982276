import styled from "styled-components";

const RootContainer = styled.div`
  height: 100vh;
  overflow: auto;
  padding-bottom: 100px;
  font-size: 14px;
  @media print {
    * {
      overflow: visible !important;
    }
    height: auto;
    overflow: visible;
    font-size: 12px;
    border: none;
    padding-bottom: 0px;

    @page {
      margin: 0;
    }
    body {
      margin: 0px;
    }
  }
`;

const ContentContainer = styled.div`
  max-width: 992px;
  @media (min-width: 992px) {
    margin-top: 20px;
  }
`;

const OrderInfoContainer = styled.div``;

const ConfirmOrderModal = styled.div``;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 15px 0;
  button {
    border: none;
    border-radius: 8px;
    background: #2546bb;
    outline: none;
    width: 100%;
    color: white;
  }
  .cancel-btn {
    background: transparent;
    border: 1px solid #f44336;
    color: #f44336;
  }
  .no-border {
    border: none;
  }
`;

const LightContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 15px;
`;

const ItemsTable = styled.table`
  table-layout: auto;
  width: 100%;
  td {
    white-space: nowrap;
    padding: 10px 0px;
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid #c0c0c0;
    object-fit: cover;
  }
`;

const modules = {
  RootContainer,
  ContentContainer,
  HeaderContainer,
  LightContainer,
  ItemsTable,
  OrderInfoContainer,
  ConfirmOrderModal,
};

export default modules;
