import styled from "styled-components";

const DateRangeInput = styled.div`
  min-height: 43px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 14px;
  border-radius: 8px;
  :hover {
    cursor: pointer;
  }
`;

const modules = {
  DateRangeInput,
};

export default modules;
