import styled from "styled-components";
import { StatusPillProps } from ".";

const RootContainer = styled.div<StatusPillProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Circle = styled.div<StatusPillProps>`
  width: 12px;
  height: 12px;
  min-width: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor};
  margin-right: 5px;
`;

const modules = {
  RootContainer,
  Circle,
};

export default modules;
