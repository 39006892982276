import { SelectOption } from "@utils/types";
import * as React from "react";
import ReactSelect, { Props } from "react-select";
import { reactSelectStyles, reactSelectTheme } from "./configs";

const Select: React.FC<Props<SelectOption, boolean>> = (props) => {
  return (
    <ReactSelect
      theme={reactSelectTheme}
      placeholder={"Buscar"}
      loadingMessage={() => "Carregando..."}
      noOptionsMessage={() => "Nenhum resultado encontrado"}
      {...props}
      styles={{ ...reactSelectStyles, ...props.styles }}
    />
  );
};

export default Select;
