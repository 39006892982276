import { toast, TypeOptions } from "react-toastify";
import { toastAsHtmlElement } from "./toastAsHtml";
import { OrderStatus, PaymentMethod, PaymentStatus, ShippingMethod } from "./types";

// As many components can trigger error, and we are not sure if all of them throws 'Error'
// we use 'any' and then filter
export function toastError(error: any, type?: TypeOptions) {
  let message: string = "Erro desconhecido";
  if (error) {
    if (error.response?.data?.userMessage) message = error.response.data.userMessage;
    else if (error.response?.data?.message) message = error.response.data.message;
    else if (error.userMessage) message = error.userMessage;
    else if (error.message) message = error.message;
    else if (typeof error === "string") message = error;
  }
  error.response.data.typeError === "html"
    ? toastAsHtmlElement(message, type)
    : toast(message, { type: type ?? "error" });
}

export function toastMessage(message: string, type?: TypeOptions) {
  toast(message, { type });
}

export function getInt(float: number | string) {
  if (typeof float === "string") float = +float;
  let number = Math.round((float ?? 0) * Math.pow(10, 2));
  if (typeof number !== "number") return 0;
  return parseInt(`${number}`);
}

export function renameFile(originalFile: File, newName: string) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

export function normalizeString(text: string) {
  if (!text) return "";
  text = text.toLowerCase();
  text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
  text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
  text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
  text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
  text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
  text = text.replace(new RegExp("[Ç]", "gi"), "c");
  text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return text;
}

export const uniqueBy = <T>(uniqueKey: keyof T, objects: T[]): T[] => {
  const ids = objects.map((object) => object[uniqueKey]);
  return objects.filter((object, index) => !ids.includes(object[uniqueKey], index + 1));
};

export const getPaymentMethodLabel = (paymentMethod: PaymentMethod) => {
  switch (paymentMethod) {
    case PaymentMethod.CREDIT_CARD:
      return "Cartão de crédito";
    case PaymentMethod.BOLETO:
      return "Boleto";
    case PaymentMethod.PIX:
      return "PIX";
    case PaymentMethod.MONEY:
      return "Dinheiro";
    case PaymentMethod.BANK_TRANSFER:
      return "Transferência bancária";
    default:
      return "";
  }
};

export const getPaymentStatusLabel = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.CREATED:
      return "Criado";
    case PaymentStatus.PAID:
      return "Pago";
    case PaymentStatus.CANCELED:
      return "Cancelado";
    case PaymentStatus.REJECTED:
      return "Rejeitado";
    case PaymentStatus.ANALYSIS:
      return "Em análise";
    case PaymentStatus.EXPIRED:
      return "Expirado";
    default:
      return "Sem status";
  }
};

export const getStatusLabel = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.CREATED:
      return "Criado";
    case OrderStatus.WAITING_SELLER_CONFIRMATION:
      return "Em espera";
    case OrderStatus.READY_TO_PAY:
      return "Aguardando pagamento";
    case OrderStatus.WAITING_PAYMENT_CONFIRMATION:
      return "Aguardando confirmação de pagamento";
    case OrderStatus.IN_ANALYSIS:
      return "Em análise";
    case OrderStatus.PAID:
      return "Pago";
    case OrderStatus.CANCELLED:
      return "Cancelado";
    default:
      return "Sem status";
  }
};

export const getShippingMethodLabel = (shippingMethod: ShippingMethod) => {
  switch (shippingMethod) {
    case ShippingMethod.EXCURSION:
      return "Excursão";
    case ShippingMethod.CORREIOS:
      return "Correios";
    case ShippingMethod.SHIPPING_COMPANY:
      return "Transportadora";
    case ShippingMethod.PICKUP:
      return "Retirada";
    default:
      return "";
  }
};

export const getStatusColor = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.CREATED:
      return "#949494";
    case OrderStatus.WAITING_SELLER_CONFIRMATION:
      return "#FFC700";
    case OrderStatus.READY_TO_PAY:
      return "#00ACD1";
    case OrderStatus.WAITING_PAYMENT_CONFIRMATION:
      return "#2546BB";
    case OrderStatus.IN_ANALYSIS:
      return "#2546BB";
    case OrderStatus.PAID:
      return "#27C100";
    case OrderStatus.CANCELLED:
      return "#000000";
    default:
      return "#8f2222";
  }
};

export function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0]);
  return slicedArray;
}

export function generateDarkColorHex() {
  let color = "#";
  for (let i = 0; i < 3; i++) color += ("0" + Math.floor((Math.random() * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
  return color;
}
