import * as React from "react";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import { toastError, toastMessage } from "@utils/functions";
import { Color } from "@utils/types";
import { useSelector } from "react-redux";
import DataTable, { TableColumn } from "react-data-table-component";
import tableStyles from "@styles/table";
import FullScreenLoading from "@components/FullScreenLoading";
import FakeLink from "@components/FakeLink";
import { Link, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useWindowSize } from "@utils/hooks";

const ListColorsPage: React.FC = () => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const [colors, setColors] = React.useState<Color[]>([]);
  const [loadingColors, setLoadingColors] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const columns = React.useMemo<TableColumn<Color>[]>(
    () => [
      {
        name: "Nome",
        selector: (row) => row.name,
      },
      {
        button: true,
        name: "Ações",
        selector: (row) => row.id,
        cell: (row) => (
          <>
            <Link
              style={{ textDecoration: "none" }}
              to={`/configuracoes/cores/${row.id}/editar`}
              className={"text-primary me-2"}
            >
              Editar
            </Link>
            <FakeLink
              onClick={() => {
                handleDeleteColor(row);
              }}
              className={"text-danger"}
            >
              Excluir
            </FakeLink>
          </>
        ),
      },
    ],
    // eslint-disable-next-line
    [companyData]
  );

  const handleDeleteColor = async (color: Color) => {
    confirmAlert({
      title: "Atenção!",
      message: `Deseja realmente excluir a cor ${color.name}?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.delete(`/company/${companyData.id}/color/${color.id}`);
              fetchColors();
              toastMessage(`Concluído.`, "success");
            } catch (error) {
              toastError(error);
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const fetchColors = async () => {
    try {
      const { data } = await Api.get(`/company/${companyData.id}/color`);
      setColors(data.colors);
      setLoadingColors(false);
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    if (companyData.id) fetchColors();
    // eslint-disable-next-line
  }, [companyData]);

  React.useEffect(() => {
    if (searchTerm) {
      const filteredColors = colors.filter((color) => color.name.toLowerCase().includes(searchTerm.toLowerCase()));
      setColors(filteredColors);
    } else {
      fetchColors();
    }
    // eslint-disable-next-line
  }, [searchTerm]);

  if (loadingColors) return <FullScreenLoading />;

  return (
    <div className={"row p-0 m-0 rounded-3"} style={{ width: "100%", backgroundColor: "white" }}>
      <div className={"col-12 my-2 gap-2 gap-lg-0 d-flex justify-content-between"}>
        <input
          className={"text-input"}
          placeholder={"Busque por cores"}
          style={{ maxWidth: width < 768 ? 1000 : 300, minWidth: width >= 768 ? 400 : 200 }}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
        <button
          className={"btn btn-primary"}
          onClick={() => {
            navigate("/configuracoes/cores/cadastrar");
          }}
        >
          {width >= 768 ? "Nova cor" : "+"}{" "}
        </button>
      </div>
      <div className={"col-12"}>
        <DataTable
          columns={columns}
          data={colors}
          customStyles={tableStyles}
          noDataComponent="Você ainda não cadastrou nenhuma cor"
          pagination
          responsive
        />
      </div>
    </div>
  );
};

export default ListColorsPage;
