import styled from "styled-components";

const RootContainer = styled.div`
  height: 60px;
  width: 100vw;
  background-color: #2546bb;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
`;

const SidebarMenuToggle = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  padding: 0;
`;

const modules = {
  RootContainer,
  SidebarMenuToggle,
};

export default modules;
