import * as React from "react";
import Select from "@components/Select";
import * as locales from "react-date-range/dist/locale";
import { OrderByOptions, OrderStatus, SelectOption } from "@utils/types";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@services/Redux";
import { setOrdersFilters } from "@services/Redux/orders";
import { DateRange, RangeKeyDict } from "react-date-range";
import { MenuItem, ControlledMenu, Menu } from "@szhsin/react-menu";
import moment from "@utils/moment";
import Styled from "./styled";
import { reactSelectStyles } from "@components/Select/configs";
import Api from "@services/Api";
import { toastError, toastMessage } from "@utils/functions";
import FileDownload from "js-file-download";

const statusOptions: SelectOption<OrderStatus>[] = [
  { value: OrderStatus.CREATED, label: "Criados" },
  { value: OrderStatus.WAITING_SELLER_CONFIRMATION, label: "Em espera" },
  { value: OrderStatus.READY_TO_PAY, label: "Aguardando pagamento" },
  { value: OrderStatus.PAID, label: "Pagos" },
  { value: OrderStatus.CANCELLED, label: "Cancelados" },
];

const orderOptions: SelectOption<OrderByOptions>[] = [
  { value: "created_at", label: "Por data de criação" },
  { value: "finished_at", label: "Por data de fechamento" },
  { value: "confirmed_at", label: "Por data de liberação pelo vendedor" },
  { value: "cancelled_at", label: "Por data de cancelamento" },
  { value: "paid_at", label: "Por data de pagamento" },
];

const OrdersFilters: React.FC = () => {
  const filters = useSelector((state: RootState) => state.orders.filters);
  const { bling_integration } = useSelector((state: RootState) => state.company.data);
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);
  const [datePickerSelectStep, setDatePickerSelectStep] = React.useState(0);
  const datePickerInputRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const selectedOrders = useSelector((state: RootState) => state.orders.selected);

  const selectionRange = {
    startDate: moment(filters.startDate).toDate(),
    endDate: moment(filters.endDate).toDate(),
    key: "selection",
  };

  const handleDateChange = (data: RangeKeyDict) => {
    if (datePickerSelectStep === 1) setIsDatePickerOpen(false);
    dispatch(
      setOrdersFilters({
        ...filters,
        dateRangeSelectStep: datePickerSelectStep,
        startDate: moment(data.selection.startDate).format(),
        endDate: moment(data.selection.endDate).format(),
      })
    );
    setDatePickerSelectStep(datePickerSelectStep === 0 ? 1 : 0);
  };

  const exportToBling = async () => {
    try {
      const { data } = await Api.post(`/integrations/bling/export_orders`, {
        order_ids: selectedOrders.map((order) => order.id),
      });
      FileDownload(data, "pedidos.csv");
      toastMessage("Exportado", "success");
    } catch (error) {
      console.log(error);
      return toastError(error);
    }
  };

  return (
    <div>
      <div className={"row p-0 m-0"}>
        <div className={"col-12 col-md-auto p-0 me-0 me-md-2"}>
          <Select
            options={statusOptions}
            onChange={(option) => {
              const selectedOption = option as SelectOption<OrderStatus>;
              dispatch(setOrdersFilters({ ...filters, status: selectedOption.value }));
            }}
            defaultValue={statusOptions.find((s) => s.value === filters.status)}
            styles={{
              control: (defaultStyles, props) => ({
                ...(reactSelectStyles?.control?.(defaultStyles, props) ?? {}),
                minWidth: 300,
              }),
            }}
          />
        </div>
        <div className={"col-12 col-md-auto p-0 me-0 me-md-2 mt-2 mt-lg-0"}>
          <Styled.DateRangeInput
            ref={datePickerInputRef}
            onClick={() => {
              setIsDatePickerOpen(!isDatePickerOpen);
            }}
          >
            De <b className={"mx-1"}>{moment(filters.startDate).format("DD/MM/YYYY")}</b> até{" "}
            <b className={"mx-1"}>{moment(filters.endDate).format("DD/MM/YYYY")}</b>
          </Styled.DateRangeInput>
          <ControlledMenu state={isDatePickerOpen ? "open" : "closed"} anchorRef={datePickerInputRef}>
            <MenuItem>
              <DateRange ranges={[selectionRange]} onChange={handleDateChange} locale={locales["ptBR"]} />
            </MenuItem>
          </ControlledMenu>
        </div>
        <div className={"col-12 col-md-auto p-0 me-0 me-md-2 mt-2 mt-lg-0"}>
          <Select
            options={orderOptions}
            onChange={(option) => {
              const selectedOption = option as SelectOption<OrderByOptions>;
              dispatch(setOrdersFilters({ ...filters, orderBy: selectedOption.value }));
            }}
            defaultValue={orderOptions.find((s) => s.value === filters.orderBy)}
            styles={{
              control: (defaultStyles, props) => ({
                ...(reactSelectStyles?.control?.(defaultStyles, props) ?? {}),
                minWidth: 300,
              }),
            }}
          />
        </div>
        <div className={"col-12 col-md-auto p-0 me-0 me-md-2 mt-2 mt-lg-0"}>
          {selectedOrders.length > 0 && (
            <Menu menuButton={<button className="btn btn-primary w-100">Exportar</button>}>
              {bling_integration?.is_authorized && (
                <MenuItem
                  onClick={() => {
                    exportToBling();
                  }}
                >
                  Exportar para bling
                </MenuItem>
              )}
            </Menu>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrdersFilters;
