import FormikTextInput from "@components/FormikTextInput";
import Select from "@components/Select";
import Api from "@services/Api";
import { toastError, toastMessage } from "@utils/functions";
import { CreditCardBrands, Order, PaymentGateways, PaymentMethod, PaymentStatus } from "@utils/types";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "@utils/moment";
import * as React from "react";
import { Calendar } from "react-date-range";
import Modal from "react-responsive-modal";
import { useNavigate, useParams } from "react-router";
import { creditCardBrandOptions, gatewayOptions, paymentMethodOptions } from "./selectOptions";

interface PaymentFormValues {
  status: PaymentStatus;
  gateway?: PaymentGateways;
  gateway_order_id?: string;
  entered_in_analysis_at?: string;
  paid_at?: string;
  payment_method?: PaymentMethod;
  credit_card_brand?: CreditCardBrands;
  installments_count?: string;
}

const OrderPaymentConfirmationScreen: React.FC = () => {
  const { order_code } = useParams();
  const formRef = React.useRef<FormikProps<PaymentFormValues>>(null);
  const [isPaidAtMenuOpen, setIsPaidAtMenuOpen] = React.useState(false);
  const [order, setOrder] = React.useState<Order>();
  const navigate = useNavigate();

  const formInitialValues = React.useMemo(() => {
    const initialValues: PaymentFormValues = {
      status: PaymentStatus.PAID,
      gateway_order_id: "",
      paid_at: "",
      installments_count: "",
    };
    return initialValues;
  }, []);

  React.useEffect(() => {
    fetchOrderData();
    // eslint-disable-next-line
  }, []);

  const fetchOrderData = async () => {
    try {
      const { data } = await Api.get(`/order/code/${order_code}`);
      setOrder(data.order);
    } catch (error) {
      toastError(error);
    }
  };

  const handleSubmit = async (values: PaymentFormValues, actions: FormikHelpers<PaymentFormValues>) => {
    if (!values.paid_at) return toastError("Data de pagamento obrigatória");
    if (!values.gateway) return toastError("Gateway obrigatório");
    if (values.gateway !== PaymentGateways.MANUAL && !values.gateway_order_id)
      return toastError("ID do gateway obrigatório");
    if (!values.payment_method) return toastError("Método de pagamento obrigatório");
    if (values.payment_method === PaymentMethod.CREDIT_CARD && !values.credit_card_brand)
      return toastError("Bandeira do cartão obrigatório");
    if (values.payment_method === PaymentMethod.CREDIT_CARD && !values.installments_count)
      return toastError("Número de parcelas obrigatório");
    if (
      values.payment_method === PaymentMethod.CREDIT_CARD &&
      values.installments_count &&
      +values.installments_count < 1
    ) {
      return toastError("Número de parcelas inválido");
    }
    actions.setSubmitting(true);
    try {
      // Using partial because we don't need to send all values, and sending an empty string will trigger an API error
      const data: Partial<PaymentFormValues> = {
        status: values.status,
        paid_at: values.paid_at,
        payment_method: values.payment_method,
        credit_card_brand: values.credit_card_brand,
      };
      if (values.gateway !== PaymentGateways.MANUAL) {
        data.gateway = values.gateway;
        data.gateway_order_id = values.gateway_order_id;
      }
      if (values.installments_count) data.installments_count = values.installments_count;

      await Api.post(`/order/${order?.id}/payment`, data);
      toastMessage("Pagamento confirmado com sucesso", "success");
      actions.setSubmitting(false);
      navigate(`/pedidos/${order_code}/pagamentos`);
    } catch (error) {
      actions.setSubmitting(false);
      toastError(error);
    }
  };

  const handlePaidAtChange = (paidAt: Date) => {
    setIsPaidAtMenuOpen(false);
    if (moment(paidAt, "DD/MM/YYYY").isValid()) {
      formRef.current?.setFieldValue("paid_at", moment(paidAt).format("DD/MM/YYYY"));
    }
  };

  return (
    <div className={"row m-0 p-0"}>
      <div className={"col-12 mb-2"}>
        <h5>Confirmar pagamento manualmente</h5>
      </div>
      <div className={"col-12 py-2 rounded-3"} style={{ backgroundColor: "white" }}>
        <Formik innerRef={formRef} initialValues={formInitialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, setFieldValue, values, errors, touched }) => (
            <Form className={"row"}>
              {values.status === PaymentStatus.PAID && (
                <>
                  <div className={"col-md-4 mb-3"}>
                    <FormikTextInput
                      label={"Data de pagamento"}
                      mask="99/99/9999"
                      name="paid_at"
                      placeholder={"Clique para abrir o calendário"}
                      onClick={() => {
                        setIsPaidAtMenuOpen(true);
                      }}
                    />
                  </div>
                  <div className={"col-md-4 mb-3"}>
                    <label>Gateway</label>
                    <Select
                      options={gatewayOptions}
                      onChange={(e: any) => {
                        setFieldValue("gateway", e.value);
                      }}
                      placeholder={"Selecione uma opção"}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: "1px solid #B6B6B6 !important",
                          borderRadius: "8px !important",
                        }),
                      }}
                    />
                  </div>
                  {values.gateway !== PaymentGateways.MANUAL && (
                    <div className={"col-md-4 mb-3"}>
                      <FormikTextInput
                        label={"ID do pagamento no gateway"}
                        mask=""
                        name="gateway_order_id"
                        placeholder={"Digite o ID"}
                      />
                    </div>
                  )}
                  <div className={"col-md-4 mb-3"}>
                    <label>Forma de pagamento</label>
                    <Select
                      options={paymentMethodOptions}
                      onChange={(e: any) => {
                        setFieldValue("payment_method", e.value);
                      }}
                      placeholder={"Selecione uma opção"}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: "1px solid #B6B6B6 !important",
                          borderRadius: "8px !important",
                        }),
                      }}
                    />
                  </div>
                </>
              )}

              {values.payment_method === PaymentMethod.CREDIT_CARD && (
                <>
                  <div className={"col-md-4 mb-3"}>
                    <label>Bandeira do cartão</label>
                    <Select
                      options={creditCardBrandOptions}
                      onChange={(e: any) => {
                        setFieldValue("credit_card_brand", e.value);
                      }}
                      placeholder={"Selecione uma opção"}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: "1px solid #B6B6B6 !important",
                          borderRadius: "8px !important",
                        }),
                      }}
                    />
                  </div>
                  <div className={"col-md-4 mb-3"}>
                    <FormikTextInput
                      label={"Parcelas"}
                      mask=""
                      name={"installments_count"}
                      placeholder="Digite o número de parcelas"
                    />
                  </div>
                </>
              )}

              <div className={"col-12"}>
                <button type="submit" className={"btn btn-primary"} disabled={isSubmitting}>
                  Enviar
                </button>
              </div>

              <Modal
                open={isPaidAtMenuOpen}
                center={true}
                onClose={() => {
                  setIsPaidAtMenuOpen(false);
                }}
              >
                <Calendar
                  onChange={handlePaidAtChange}
                  date={
                    moment(values.paid_at, "DD/MM/YYYY").isValid()
                      ? moment(values.paid_at, "DD/MM/YYYY").toDate()
                      : undefined
                  }
                  color="#2546bb"
                  rangeColors={["#2546bb"]}
                />
              </Modal>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default OrderPaymentConfirmationScreen;
