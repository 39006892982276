class AuthService {
  setAuthData = (token: string, name: string, role: string, userId: string, companyCode?: string): void => {
    localStorage.setItem("user_id", userId);
    localStorage.setItem("user_token", token);
    localStorage.setItem("user_name", name);
    localStorage.setItem("user_role", role);
    if (companyCode) {
      localStorage.setItem("company_code", companyCode);
    }
  };

  isAuthenticated = (): boolean => {
    const token = localStorage.getItem("user_token");
    return token ? true : false;
  };

  logout = (): void => {
    localStorage.clear();
    window.location.href = "/";
  };
}

export default new AuthService();
