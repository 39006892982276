import * as React from "react";
import Loading from "react-loading";
import styled from "styled-components";

const RootContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullScreenLoading: React.FC = () => {
  return (
    <RootContainer>
      <Loading type={"spinningBubbles"} color={"black"} />
    </RootContainer>
  );
};

export default FullScreenLoading;
