export const TooltipTexts = {
  productCode: "O código único que representa este produto. Geralmente é utilizada a referência do produto.",
  applyToAll:
    "Ao clicar nessa opção, você define o preço para todas as variações (Cor x Tamanho) como sendo este preço único.",
  description: "Descrição do produto (opcional)",
  categories:
    "Aqui você define as categorias do produto. Essas categorias podem ajudar o seu cliente a encontrar o produto que deseja. Possuímos várias categorias pré-cadastradas para você utilizar, divididas em três níveis (Exemplo: Roupas - Camisas - Manga longa). Caso não encontre alguma categoria da qual precise, você pode entrar em contato conosco para verificarmos sobre a possibilidade de adicioná-la.",
  colors:
    "Aqui você define as cores que o seu produto possui. Antes de escolher uma cor no cadastro do produto, você precisa cadastra-la na aba ”Cores”, no menu lateral.\nVocê pode arrastar as cores para definir em qual ordem elas aparecerão para os seus clientes na página do produto.\nObservação: Todo produto precisa ter pelo menos uma cor cadastrada.",
  sizes:
    "Aqui você define os tamanhos que o seu produto possui. Antes de escolher um tamanho no cadastro do produto, você precisa cadastra-lo na aba ”Tamanhos”, no menu lateral.\nVocê pode arrastar os tamanhos para definir em qual ordem eles aparecerão para os seus clientes na página do produto.\nObservação: Todo produto precisa ter pelo menos um tamanho cadastrado.",
  photos:
    "Você pode arrastar as fotos para alterar a ordem na qual elas aparecem na página do produto. A primeira foto na ordem será aquela que aparecerá à frente do produto no catálogo.",
  prices:
    "Caso as variações do seu produto tenham preços diferentes, você pode definir preços individuais por variação nesta grade.",
  stock:
    "Ao ativar essa opção, você pode definir o estoque de cada variação (Cor x Tamanho) desse produto, e dessa forma o cliente só conseguirá adicionar à Sacola produtos que possuam estoque. Caso esta opção esteja desativada, o cliente consegue sempre adicionar esse produto ao carrinho, e você verifica a disponibilidade do mesmo após o pedido ter sido enviado pelo cliente.",
};
