import * as React from "react";

import ErrorMessage, { FormContainer, FormContainerWrapper, RootContainer, TextContainer } from "./components";

import Api from "@services/Api";

const ForgotPasswordScreen: React.FC = () => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    try {
      setErrorMessage("");
      setLoading(true);
      await Api.post(`/user/request_password_reset_token`, { email });
      setLoading(false);
      alert(
        "Um email foi enviado para você com as instruções para resetar sua senha, verifique sua caixa de entrada e spam.",
      );
    } catch (error: any) {
      setLoading(false);
      alert(error.response.data.userMessage);
      setErrorMessage(error?.response?.data?.userMessage ?? "erro de conexão");
    }
  };

  return (
    <RootContainer>
      <FormContainerWrapper>
        <TextContainer>
          <h2>Recuperação de Senha</h2>
          <span>Forneça seu email</span>
        </TextContainer>
        <FormContainer>
          <form>
            <div className="form-group">
              <label htmlFor="email">Seu email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                aria-describedby="emailHelp"
                placeholder="Digite seu email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <ErrorMessage message={errorMessage} />
            <button
              type="submit"
              className="btn btn-primary w-100 mt-2"
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              Enviar
            </button>
          </form>
        </FormContainer>
      </FormContainerWrapper>
    </RootContainer>
  );
};

export default ForgotPasswordScreen;
