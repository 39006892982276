import * as React from "react";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import { toastError } from "@utils/functions";
import { Product, ProductVariation } from "@utils/types";
import { useSelector } from "react-redux";
import DataTable, { TableColumn } from "react-data-table-component";
import tableStyles from "@styles/table";
import { Link, useNavigate } from "react-router-dom";
import noImage from "@assets/img/noimage.jpeg";
import { Money } from "@utils/money";
import LoadingIndicator from "@components/LoadingIndicator";
import { AiOutlinePlus } from "react-icons/ai";

interface VariationStockData extends ProductVariation {
  product: Product;
}

const StockListPage: React.FC = () => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const [variationsStockData, setVariationsStockData] = React.useState<VariationStockData[]>([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  const columns = React.useMemo<TableColumn<VariationStockData>[]>(
    () => [
      {
        name: "Imagem",
        grow: 0,
        cell: (row) => (
          <img
            alt="Product_image"
            style={{ width: 25 }}
            src={
              row.product.images[0]
                ? `${row.product.images[0].base_url}/products/${row.product.id}/thumb_1/${row.product.images[0].key}`
                : noImage
            }
          />
        ),
      },
      {
        name: "Código",
        grow: 0,
        selector: (row) => row.product.ref ?? "",
      },
      {
        name: "Nome",
        selector: (row) => `${row.product.name}`,
      },
      {
        name: "Variação",
        selector: (row) => `${row.size.name}-${row.color.name}`,
      },
      {
        name: "Preço",
        selector: (row) => row.price,
        button: true,
        cell: (row) => <b>{Money(row.price ?? 0).format()}</b>,
      },
      {
        name: "Estoque",
        selector: (row) => row.stock,
        cell: (row) => (
          <span className={`${row.stock <= 1 ? "text-danger" : row.stock < 5 ? "text-warning" : "text-primary"}`}>
            {row.stock}
          </span>
        ),
      },
      {
        button: true,
        name: "Ações",
        selector: (row) => row.product.id,
        cell: (row) => (
          <>
            <Link
              style={{ textDecoration: "none" }}
              to={`/produtos/${row.product.id}/editar`}
              className={"text-primary me-2"}
            >
              Editar
            </Link>
          </>
        ),
      },
    ],
    // eslint-disable-next-line
    [companyData, variationsStockData],
  );

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const { data } = await Api.get(`/product/stock_data`);
      setVariationsStockData(data.variations);
      setLoading(false);
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    if (companyData.id) fetchProducts();
    // eslint-disable-next-line
  }, [companyData]);

  return (
    <div className={"row"}>
      <div className={"col-12 mb-4 text-start"}>
        <button
          style={{
            height: 40,
            width: 40,
            border: "none",
            outline: "none",
            padding: 0,
            margin: 0,
            borderRadius: 50,
            background: "none",
            backgroundColor: "#2546BB",
          }}
          onClick={() => {
            navigate("/produtos/cadastrar");
          }}
        >
          <AiOutlinePlus color={"white"} size={"100%"} className="p-0 m-0" />
        </button>
      </div>
      <div className={"col-12 rounded-3"}>
        <DataTable
          columns={columns}
          data={variationsStockData}
          progressPending={loading}
          progressComponent={<LoadingIndicator />}
          customStyles={tableStyles}
          defaultSortAsc={true}
          defaultSortFieldId={6}
          noDataComponent="Você ainda não cadastrou nenhum produto"
          responsive
        />
      </div>
    </div>
  );
};

export default StockListPage;
