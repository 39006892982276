import { QuestionToolTip } from "@components/QuestionToolTip/question-tooltip";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";

export interface ConfigurationSwitchItemProps<T extends FieldValues> {
  label: string;
  description?: string;
  hasBottomBorder?: boolean;
  tooltipMessage?: string;
  registerName: Path<T>;
  register: UseFormRegister<T>;
  defaultChecked: boolean;
  cardColor?: "warning" | "danger" | "success" | "info";
  cardMessage?: string;
}
export function ConfigurationSwitchItem<T extends FieldValues>(props: ConfigurationSwitchItemProps<T>) {
  const hasBottomBorder = props.hasBottomBorder === false ? "" : "border-bottom";
  return (
    <div className={`${hasBottomBorder} d-flex flex-column py-3 align-items-start`}>
      <div className="d-flex flex-row">
        <h5 className="form-check-label m-0">{props.label}</h5>
        {props.tooltipMessage && <QuestionToolTip id={`switch-${props.registerName}`} message={props.tooltipMessage} />}
      </div>
      {props.description && <label className="text-black-50 my-1">{props.description}</label>}
      <div className="d-flex flex-row justify-content-between">
        <div>Desativado</div>
        <div className="form-check d-flex justify-content-center form-switch">
          <input
            {...props.register(props.registerName)}
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={props.registerName}
            defaultChecked={props.defaultChecked}
          />
        </div>
        <div>Ativado</div>
      </div>
      {props.cardMessage && (
        <div className={`card bg-${props.cardColor} text-white my-1`} style={{ maxWidth: "800px" }}>
          <div className="card-body">
            <p className="card-text">{props.cardMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
}
