import { QuestionToolTip } from "@components/QuestionToolTip/question-tooltip";
import SortableImages from "@pages/Products/Edit/ImagesForm/SortableImagesList";
import { TooltipTexts } from "@pages/Products/tooltip-texts";
import Api from "@services/Api";
import { RootState, useAppDispatch } from "@services/Redux";
import { setProduct } from "@services/Redux/editProductFormData";
import { toastError, toastMessage } from "@utils/functions";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import Loading from "react-loading";
import { useSelector } from "react-redux";
import Styled from "../../styled";

const acceptedStyle = {
  borderStyle: "dashed",
  borderColor: "#2546bb",
  borderWidth: "2px",
  backgroundColor: "#fafafa",
};

const rejectedStyle = {
  borderStyle: "dashed",
  borderColor: "#ff0000",
  borderWidth: "2px",
  backgroundColor: "#fafafa",
};

const ProductImagesForm: React.FC = () => {
  const { product } = useSelector((state: RootState) => state.editProductFormData);
  const [uploading, setUploading] = React.useState(false);
  const imageInputRef = React.useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const handleImageSelect = async (files: File[]) => {
    setUploading(true);
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append("files", file);
    }
    if (formData.getAll("files").length > 0) {
      try {
        await Api.post(`/product/${product?.id}/add_images`, formData);
        const { data: productData } = await Api.get(`/product?product_id=${product?.id}`);
        dispatch(setProduct({ ...product, images: productData.products[0].images }));
        toastMessage(`Imagens adicionadas`, "success");
      } catch (error) {
        toastError(error);
      }
    }
    setUploading(false);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: handleImageSelect,
    noClick: true,
  });

  const imagesInputContainerStyle = React.useMemo(
    () => ({
      ...(isDragAccept ? acceptedStyle : {}),
      ...(isDragReject ? rejectedStyle : {}),
    }),
    // eslint-disable-next-line
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <>
      <div>
        <div className={"d-flex align-items-center"}>
          <Styled.AddImageButton
            color={"#2546BB"}
            size={35}
            className="me-2"
            onClick={() => {
              imageInputRef.current?.click();
            }}
          />
          <label style={{ lineHeight: 1 }}>
            Fotos dos produtos{" "}
            <span style={{ fontSize: 12, color: "#3f3f3f" }}>(Arraste imagens para cá ou clique no botão +)</span>
          </label>
          <QuestionToolTip message={TooltipTexts.photos} id="product-photos" />
        </div>
        <Styled.ProductImagesContainer
          {...getRootProps({ style: imagesInputContainerStyle })}
          className={"row m-0 mt-2 mb-3"}
        >
          {uploading ? (
            <Styled.ProductImagesContainerLoadingOverlay>
              <Loading type={"bubbles"} />
            </Styled.ProductImagesContainerLoadingOverlay>
          ) : null}
          <input {...getInputProps()} ref={imageInputRef} />
          <SortableImages
            product={product}
            onChangeStart={() => {
              setUploading(true);
            }}
            onChangeEnd={() => {
              setUploading(false);
            }}
          />
        </Styled.ProductImagesContainer>
      </div>
    </>
  );
};

export default ProductImagesForm;
