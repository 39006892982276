import { QuestionToolTip } from "@components/QuestionToolTip/question-tooltip";
import { TooltipTexts } from "@pages/Products/tooltip-texts";
import { RootState, useAppDispatch } from "@services/Redux";
import { setStockData, setStockEnabled } from "@services/Redux/editProductFormData";
import { uniqueBy } from "@utils/functions";
import * as React from "react";
import { useSelector } from "react-redux";
import Styled from "./styled";

const StockForm: React.FC = () => {
  const { product, stockData, stockEnabled, variationsData } = useSelector(
    (state: RootState) => state.editProductFormData,
  );
  const colors = React.useMemo(() => uniqueBy("value", variationsData.colors), [variationsData]);
  const sizes = React.useMemo(() => uniqueBy("value", variationsData.sizes), [variationsData]);
  const dispatch = useAppDispatch();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;
    const [color_id, size_id] = id.split("-");
    const newStockData = [...stockData.map((s) => ({ ...s }))];
    const index = newStockData.findIndex(
      (stock) => stock.color_id === Number(color_id) && stock.size_id === Number(size_id),
    );
    if (index !== -1) {
      newStockData[index].quantity = Number(value);
    }
    dispatch(setStockData(newStockData));
  };

  React.useEffect(() => {
    const data = [];
    for (const color of colors) {
      for (const size of sizes) {
        data.push({
          color_id: color.value,
          size_id: size.value,
          quantity: product.variations.find((v) => v.color.id === color.value && v.size.id === size.value)?.stock || 0,
        });
      }
    }
    dispatch(setStockData(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variationsData, product]);

  React.useEffect(() => {}, [product]);
  return (
    <Styled.RootContainer>
      <div className="form-check form-switch mb-2">
        <input
          className="form-check-input"
          type="checkbox"
          id="stockEnabledSwitch"
          onChange={(e) => {
            dispatch(setStockEnabled(e.target.checked));
          }}
          checked={stockEnabled}
        />
        <label className="form-check-label" htmlFor="stockEnabledSwitch">
          Ativar gerenciamento de estoque?
        </label>
        <QuestionToolTip message={TooltipTexts.stock} id="stock" />
      </div>

      {stockEnabled ? (
        <Styled.GridTable>
          <thead>
            <tr>
              <td></td>
              {sizes.map((size) => (
                <td key={size.value}>{size.label}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {colors.map((color) => (
              <tr key={color.value}>
                <td style={{ fontSize: 12 }}>{color.label}</td>
                {sizes.map((size) => {
                  return (
                    <td key={size.value}>
                      <Styled.Input
                        id={`${color.value}-${size.value}-input`}
                        type="number"
                        min="0"
                        step="1"
                        value={
                          stockData.find(
                            (variation) =>
                              variation.color_id === Number(color.value) && variation.size_id === Number(size.value),
                          )?.quantity || 0
                        }
                        onChange={handleInputChange}
                      />
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Styled.GridTable>
      ) : null}
    </Styled.RootContainer>
  );
};

export default StockForm;
