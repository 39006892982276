import Api from "@services/Api";
import { toastError } from "@utils/functions";
import { OrderStatus } from "@utils/types";
import moment, { Moment } from "moment";
import React from "react";

type OrdersCountPayload = {
  order_status: OrderStatus;
  count: number;
};

export const useMetrics = (options: { startDate: Moment }) => {
  const [loadingMetrics, setLoadingMetrics] = React.useState(true);
  const [salesVolume, setSalesVolume] = React.useState<number>();
  const [qualifiedLeads, setQualifiedLeads] = React.useState<number>();
  const [newLeads, setNewLeads] = React.useState<number>();
  const [ordersCount, setOrdersCount] = React.useState({
    [OrderStatus.CANCELLED]: {
      count: 0,
      total_value: 0,
    },
    [OrderStatus.PAID]: {
      count: 0,
      total_value: 0,
    },
    [OrderStatus.READY_TO_PAY]: {
      count: 0,
      total_value: 0,
    },
    [OrderStatus.IN_ANALYSIS]: {
      count: 0,
      total_value: 0,
    },
    [OrderStatus.WAITING_PAYMENT_CONFIRMATION]: {
      count: 0,
      total_value: 0,
    },
    [OrderStatus.WAITING_SELLER_CONFIRMATION]: {
      count: 0,
      total_value: 0,
    },
    [OrderStatus.CREATED]: {
      count: 0,
      total_value: 0,
    },
  });

  const fetchMetrics = async () => {
    setLoadingMetrics(true);
    try {
      const { data: ordersCountData } = await Api.get(
        `/metrics/orders_count?startDate=${encodeURIComponent(
          options.startDate.format("DD/MM/YYYY"),
        )}&endDate=${encodeURIComponent(moment().format("DD/MM/YYYY"))}`,
      );
      const cancelledOrdersData = ordersCountData.orders.find(
        (d: OrdersCountPayload) => d.order_status === OrderStatus.CANCELLED,
      );
      const paidOrdersData = ordersCountData.orders.find(
        (d: OrdersCountPayload) => d.order_status === OrderStatus.PAID,
      );
      const readyToPayOrdersData = ordersCountData.orders.find(
        (d: OrdersCountPayload) => d.order_status === OrderStatus.READY_TO_PAY,
      );
      const inAnalysisOrdersData = ordersCountData.orders.find(
        (d: OrdersCountPayload) => d.order_status === OrderStatus.IN_ANALYSIS,
      );
      const waitingPaymentConfirmationOrdersData = ordersCountData.orders.find(
        (d: OrdersCountPayload) => d.order_status === OrderStatus.WAITING_PAYMENT_CONFIRMATION,
      );
      const waitingSellerConfirmationOrdersData = ordersCountData.orders.find(
        (d: OrdersCountPayload) => d.order_status === OrderStatus.WAITING_SELLER_CONFIRMATION,
      );
      const createdOrdersData = ordersCountData.orders.find(
        (d: OrdersCountPayload) => d.order_status === OrderStatus.CREATED,
      );

      setOrdersCount({
        [OrderStatus.CANCELLED]: cancelledOrdersData
          ? { count: cancelledOrdersData.count, total_value: cancelledOrdersData.total_value }
          : { count: 0, total_value: 0 },
        [OrderStatus.PAID]: paidOrdersData
          ? { count: paidOrdersData.count, total_value: paidOrdersData.total_value }
          : { count: 0, total_value: 0 },
        [OrderStatus.READY_TO_PAY]: readyToPayOrdersData
          ? { count: readyToPayOrdersData.count, total_value: readyToPayOrdersData.total_value }
          : { count: 0, total_value: 0 },
        [OrderStatus.IN_ANALYSIS]: inAnalysisOrdersData
          ? { count: inAnalysisOrdersData.count, total_value: inAnalysisOrdersData.total_value }
          : { count: 0, total_value: 0 },
        [OrderStatus.WAITING_PAYMENT_CONFIRMATION]: waitingPaymentConfirmationOrdersData
          ? {
              count: waitingPaymentConfirmationOrdersData.count,
              total_value: waitingPaymentConfirmationOrdersData.total_value,
            }
          : { count: 0, total_value: 0 },
        [OrderStatus.WAITING_SELLER_CONFIRMATION]: waitingSellerConfirmationOrdersData
          ? {
              count: waitingSellerConfirmationOrdersData.count,
              total_value: waitingSellerConfirmationOrdersData.total_value,
            }
          : { count: 0, total_value: 0 },
        [OrderStatus.CREATED]: createdOrdersData
          ? { count: createdOrdersData.count, total_value: createdOrdersData.total_value }
          : { count: 0, total_value: 0 },
      });
      setLoadingMetrics(false);
    } catch (error) {
      toastError(error);
    }
  };

  return { ordersCount, loadingMetrics, fetchMetrics };
};
