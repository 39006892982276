import { QuestionToolTip } from "@components/QuestionToolTip/question-tooltip";
import { uniqueBy } from "@utils/functions";
import { SelectOption } from "@utils/types";
import * as React from "react";
import Styled from "./styled";

interface StockFormProps {
  variationsData: {
    colors: SelectOption[];
    sizes: SelectOption[];
  };
  onStockEnabledChange: (enabled: boolean) => void;
  onStockDataChange: (stockdata: { color_id: number; size_id: number; quantity: number }[]) => void;
}

const StockForm: React.FC<StockFormProps> = ({ variationsData, onStockEnabledChange, onStockDataChange }) => {
  const colors = React.useMemo(() => uniqueBy("value", variationsData.colors), [variationsData]);
  const sizes = React.useMemo(() => uniqueBy("value", variationsData.sizes), [variationsData]);
  const [stockEnabled, setStockEnabled] = React.useState(false);
  const [stockData, setStockData] = React.useState<{ color_id: number; size_id: number; quantity: number }[]>([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;
    const [color_id, size_id] = id.split("-");
    setStockData((prevStockData) => {
      const newStockData = [...prevStockData];
      const index = newStockData.findIndex(
        (stock) => stock.color_id === Number(color_id) && stock.size_id === Number(size_id),
      );
      if (index !== -1) {
        newStockData[index].quantity = Number(value);
      }
      return newStockData;
    });
  };

  React.useEffect(() => {
    setStockData(
      colors.map((color) =>
        sizes.map((size) => ({ color_id: Number(color.value), size_id: Number(size.value), quantity: 0 })),
      )[0],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variationsData]);

  React.useEffect(() => {
    onStockDataChange(stockData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockData]);

  return (
    <Styled.RootContainer>
      <div className="form-check form-switch mb-2">
        <input
          className="form-check-input"
          type="checkbox"
          id="stockEnabledSwitch"
          onChange={(e) => {
            onStockEnabledChange(e.target.checked);
            setStockEnabled(e.target.checked);
          }}
          defaultValue=""
          placeholder="0"
        />
        <label className="form-check-label" htmlFor="stockEnabledSwitch">
          Ativar gerenciamento de estoque?
        </label>
        <QuestionToolTip
          id="stock-management"
          message="Permite que você possa definir a quantidade de produtos para venda."
        />
      </div>

      {stockEnabled ? (
        <Styled.GridTable>
          <thead>
            <tr>
              <td></td>
              {sizes.map((size) => (
                <td key={size.value}>{size.label}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {colors.map((color) => (
              <tr key={color.value}>
                <td>{color.label}</td>
                {sizes.map((size) => {
                  return (
                    <td key={size.value}>
                      <Styled.Input
                        id={`${color.value}-${size.value}-input`}
                        type="number"
                        min="0"
                        step="1"
                        defaultValue="0"
                        onChange={handleInputChange}
                      />
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Styled.GridTable>
      ) : null}
    </Styled.RootContainer>
  );
};

export default StockForm;
