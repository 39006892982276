import * as React from "react";
import { Formik, FormikHelpers, Form, Field } from "formik";

import ErrorMessage, { FormContainer, FormContainerWrapper, RootContainer, TextContainer } from "./components";

import Api from "@services/Api";
import Auth from "@services/Auth";
import { Navigate, useNavigate } from "react-router";
import LogRocket from "logrocket";
import env from "@utils/env";
import { Link } from "react-router-dom";

interface LoginFormValues {
  username: string;
  password: string;
}

const LoginScreen: React.FC = () => {
  const initialValues: LoginFormValues = { username: "", password: "" };
  const [errorMessage, setErrorMessage] = React.useState("");
  const navigate = useNavigate();

  const handleSubmit = async (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
    try {
      setErrorMessage("");
      actions.setSubmitting(true);
      const { data } = await Api.post(`/user/login`, values);
      Auth.setAuthData(data.token, data.userName, data.userRole, data.userId, data.company_code);
      LogRocket.identify(data.userId, {
        name: data.name,
        email: values.username,
      });
      navigate("/dashboard");
    } catch (error: any) {
      actions.setSubmitting(false);
      if (error?.response?.data?.userMessage === "unauthorized") return setErrorMessage("credenciais inválidas");
      setErrorMessage(error?.response?.data?.userMessage ?? "erro de conexão");
    }
  };

  if (Auth.isAuthenticated()) return <Navigate to="/" />;

  return (
    <RootContainer>
      <FormContainerWrapper>
        <img src={env.REACT_APP_ICON_URL} width={60} height={60} alt="gears" />
        <TextContainer>
          <h2>Painel {env.REACT_APP_PLATFORM_NAME}</h2>
          <span>Entre com suas credenciais</span>
        </TextContainer>
        <FormContainer>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-4">
                  <span>Email</span>
                  <Field
                    name="username"
                    type="email"
                    className="form-control"
                    placeholder="Digite seu email"
                    required
                  />
                </div>
                <div className="">
                  <span>Senha</span>
                  <Field
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Digite sua senha"
                    required
                  />
                </div>
                <ErrorMessage message={errorMessage} />
                <div className="w-100 d-grid mt-4">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    entrar
                  </button>
                </div>
                <div className={"mt-3 text-center"}>
                  <Link to={`/esqueci_minha_senha`}>esqueci minha senha</Link>
                </div>
              </Form>
            )}
          </Formik>
        </FormContainer>
      </FormContainerWrapper>
    </RootContainer>
  );
};

export default LoginScreen;
