import { ConfigurationInputItem } from "@components/ConfigurationItem/ConfigurationInput";
import { ConfigurationSwitchItem } from "@components/ConfigurationItem/ConfigurationSwitch";
import LoadingIndicator from "@components/LoadingIndicator";
import { QuestionToolTip } from "@components/QuestionToolTip/question-tooltip";
import Select from "@components/Select";
import { reactSelectStyles } from "@components/Select/configs";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import { toastError, toastMessage } from "@utils/functions";
import { CnpjDiscountType, SelectOption } from "@utils/types";
import * as React from "react";
import CurrencyInput from "react-currency-input-field";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

interface Seller {
  id: number;
  name: string;
}

interface FormData {
  default_seller_id: number;
  order_automatic_flow: boolean;
  requires_birthdate?: boolean;
  max_installments_count?: number;
  is_shipment_enabled?: boolean;
  is_product_observations_enabled?: boolean;
  cash_payment_discount_percentage?: number;
  cnpj_discount_type?: CnpjDiscountType;
  cnpj_discount_percentage?: number;
  cnpj_discount_value?: number;
  minimun_order_value?: number;
  minimun_pieces_number?: number;
  require_login_to_buy?: boolean;
  require_name_and_phone_to_buy?: boolean;
  requires_customer_address?: boolean;
  requires_customer_phone_on_register?: boolean;
  emepag_api_token?: string;
  emepag_v2_api_token?: string;
  is_payment_enabled?: boolean;
  is_maintenance_mode_enabled?: boolean;
  is_pre_customers_enabled?: boolean;
  maintenance_mode_message?: string;
  whatsapp_button_url?: string;
  is_whatsapp_button_enabled?: boolean;
  is_excursion_info_required?: boolean;
  is_pickup_enabled?: boolean;
  show_stock_to_customer?: boolean;
  gtm_container_id?: string;
  meta_pixel?: string;
  pod_organizar_api_token?: string;
  // custom_domain?: string;
}

const cnpjDiscountTypeOptions = [
  { value: "percentage", label: "Percentual" },
  { value: "fixed", label: "Valor" },
];

const requireCustomerAdrressOptions = [
  { value: true, label: "Sempre solicitar o endereço do cliente no momento da finalização do pedido" },
  { value: false, label: "Solicitar o endereço do cliente apenas quando envio não for por excursão ou retirada" },
];

const GeneralSettings: React.FC = () => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const [sellers, setSellers] = React.useState<Seller[]>([]);
  const [loadingSellers, setLoadingSellers] = React.useState(true);
  const [updating, setUpdating] = React.useState(false);
  const { register, setValue, handleSubmit, getValues, watch } = useForm<FormData>();

  watch("cnpj_discount_type");

  const fetchSellers = async () => {
    try {
      const { data } = await Api.get(`/company/${companyData.id}/seller`);
      setSellers(data.sellers);
      setLoadingSellers(false);
    } catch (error) {
      toastError(error);
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    setUpdating(true);
    if (data.cnpj_discount_value !== undefined && isNaN(data.cnpj_discount_value)) delete data.cnpj_discount_value;
    if (data.minimun_order_value !== undefined && isNaN(data.minimun_order_value)) delete data.minimun_order_value;

    if (data.is_pre_customers_enabled) {
      setValue("require_login_to_buy", true);
      data.require_login_to_buy = true;
    }

    try {
      await Api.patch(`/company/${companyData.id}`, data);
      toastMessage("Configurações atualizadas com sucesso!", "success");
    } catch (error) {
      toastError(error);
    } finally {
      setUpdating(false);
    }
  });

  React.useEffect(() => {
    if (companyData.id) {
      fetchSellers();
      setValue("cnpj_discount_type", companyData.cnpj_discount_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  if (loadingSellers || !companyData.id) return <LoadingIndicator />;

  return (
    <div className={"col-12 p-2"}>
      <form className={"bg-white p-3 h-100 rounded-3"} onSubmit={onSubmit}>
        <div className={"border-bottom pb-3"}>
          <h4>Configurações gerais do catálogo</h4>
          <span>Aqui você definirá como funcionará o seu catálogo de acordo com as preferências da sua marca</span>
        </div>
        <ConfigurationSwitchItem<FormData>
          label="Campo de observação nos produtos"
          description="Permitir que o cliente escreva observações sobre cada produto em seu pedido"
          tooltipMessage="Ao ativar essa opção, você permite que o cliente tenha acesso a um campo de texto na página de cada produto,
          onde ele pode escrever observações sobre o mesmo que serão enviadas em seu pedido, como cores de sua preferência.
          Você poderá ver essas observações no Painel Administrativo, na página do pedido do cliente."
          registerName="is_product_observations_enabled"
          register={register}
          defaultChecked={companyData.is_product_observations_enabled ? true : false}
        />
        <ConfigurationSwitchItem<FormData>
          label="Retirada em loja"
          description="Permite que seu cliente escolha a opção 'retirada em loja'"
          tooltipMessage="Selecionando esta opção, seu cliente poderá escolher 'Retirada em loja' como forma de envio. Você devem combinar com o cliente o ponto de entrega"
          registerName="is_pickup_enabled"
          register={register}
          defaultChecked={companyData.is_pickup_enabled ? true : false}
        />

        <ConfigurationSwitchItem<FormData>
          label="Exigir dados da excursão" // aqui
          description="Quando seu cliente escolher o meio de envio 'excursão', ele deverá preencher os detalhes da excursão."
          tooltipMessage="Ao ativar essa opção, você torna obrigatório o preenchimento dos dados da excursão no fechamento do pedido."
          registerName="is_excursion_info_required"
          register={register}
          defaultChecked={companyData.is_excursion_info_required ? true : false}
        />
        <div className={"border-bottom d-flex flex-column py-3 align-items-start"}>
          <div className="d-flex flex-row">
            <h5 className="m-0">Exigir endereço de entrega</h5>
            <QuestionToolTip
              id="requires-customer-address"
              message="Caso seja obrigatório, o cliente deve informar o endereço mesmo que o método de entrega seja Excursão ou Retirada. Caso não seja obrigatório, algumas integrações podem não funcionar"
            />
          </div>
          <span className="text-black-50 my-2">
            Determina se quando o cliente for finalizar a compra, ele é obrigado a informar o endereço de entrega do
            pedido ou não.
          </span>
          <Select
            options={requireCustomerAdrressOptions}
            placeholder={"Selecionar opção"}
            defaultValue={requireCustomerAdrressOptions.find((o) => o.value === companyData.requires_customer_address)}
            styles={{
              control: (defaultStyles, props) => ({
                ...(reactSelectStyles?.control?.(defaultStyles, props) ?? {}),
                border: "1px solid #B6B6B6 !important",
                width: 234,
              }),
            }}
            onChange={(option) => {
              const value = (option as SelectOption).value;
              setValue("requires_customer_address", value);
            }}
          />
          <div className={`card bg-danger text-white my-3`} style={{ maxWidth: "800px" }}>
            <div className="card-body">
              <p className="card-text">
                Caso deseje utilizar integrações com algum ERP, é possível que seja necessário sempre solicitar o
                endereço do cliente para que a integração funcione corretamente.
              </p>
            </div>
          </div>
        </div>
        <ConfigurationSwitchItem<FormData>
          label="Exibir estoque para o cliente"
          description="Permitir que o cliente veja a quantidade de estoque disponível de cada variação."
          tooltipMessage="Ao ativar essa opção, seus clientes poderão ver a quantidade disponível de cada peça no seu estoque."
          registerName="show_stock_to_customer"
          register={register}
          defaultChecked={companyData.show_stock_to_customer ? true : false}
        />
        <div className="border-bottom">
          <div className="my-2">
            <h3>Clientes</h3>
          </div>
          <ConfigurationSwitchItem<FormData>
            label="Cadastro do cliente é obrigatório para fazer pedidos"
            description="Solicitar que o cliente se cadastre para que possa realizar pedidos no catálogo"
            tooltipMessage="Ao ativar essa opção, o cliente terá que se cadastrar no seu catálogo para que consiga fazer pedidos.
          O cliente é redirecionado para a página de cadastro quando tenta adicionar um produto à Sacola de compras."
            registerName="require_login_to_buy"
            register={register}
            defaultChecked={companyData.require_login_to_buy ? true : false}
            cardMessage="Você precisa ativar essa opção se deseja que as informações dos seus clientes, como pedidos realizados pelo mesmo,
          estejam disponívels na aba ”Clientes” do Painel Administrativo"
            cardColor="warning"
            hasBottomBorder={false}
          />
          <ConfigurationSwitchItem<FormData>
            label="Nome e telefone do cliente é obrigatório para fazer pedidos"
            description="Solicitar que o cliente informe o nome e telefone para que possa realizar pedidos no catálogo"
            tooltipMessage="Ao ativar essa opção, o cliente terá que informar o nome e telefone no seu catálogo para que consiga fazer pedidos.
            O cliente será obrigado a informar o nome e o telefone quando tentar adicionar um produto à Sacola de compras."
            registerName="require_name_and_phone_to_buy"
            register={register}
            defaultChecked={companyData.require_name_and_phone_to_buy ? true : false}
            cardMessage="Caso a opção ”Cadastro do cliente é obrigatório para fazer pedidos” esteja ativada, o usuário será redirecionado para a página de cadastro, e consequentemente você terá acesso aos dados do cliente."
            cardColor="warning"
            hasBottomBorder={false}
          />
          <ConfigurationSwitchItem<FormData>
            label="Pré aprovação dos clientes para ter acesso ao catálogo"
            description="Quando o cliente se cadastrar, ele deve ter o acesso liberado antes de ter acesso ao catálogo"
            tooltipMessage="Ao ativar essa opção, quando o cliente se cadastrar ele irá para uma lista de pré aprovação, nessa lista você poderá aceitar ou não o cadastro do cliente, aceito-o ele terá acesso ao catálogo (podendo ver os preços e realizar pedidos)."
            registerName="is_pre_customers_enabled"
            register={register}
            defaultChecked={companyData.is_pre_customers_enabled ? true : false}
            cardMessage={`Atenção: Ativar essa funcionalidade implica em obrigar seu cliente a se cadastrar no seu catálogo, pois só estando cadastrado ele pode ter o acesso liberado. Portanto, a funcionalidade "Cadastro obrigatório" será ativada`}
            cardColor="danger"
            hasBottomBorder={false}
          />
          <ConfigurationSwitchItem<FormData>
            label="Telefone obrigatório no cadastro do cliente"
            description="Tornar obrigatório o preenchimento do telefone quando o cliente for se cadastrar"
            registerName="requires_customer_phone_on_register"
            register={register}
            defaultChecked={companyData.requires_customer_phone_on_register ? true : false}
            hasBottomBorder={false}
          />
        </div>

        <ConfigurationSwitchItem<FormData>
          label="Fluxo de compras automático"
          description="Permitir que os clientes realizem compras e façam o pagamento sem precisar entrar em contato com o vendedor, de forma independente"
          tooltipMessage="Ao ativar essa opção, você permite que, após o cliente finalizar o seu pedido, já receba um link de pagamento para aquele pedido,
          como nas plataformas de e-commerce, sem que haja a necessidade de que o vendedor libere o pedido para pagamento."
          registerName="order_automatic_flow"
          register={register}
          defaultChecked={companyData.order_automatic_flow ? true : false}
          cardMessage="Atenção: Para que essa opção seja utilizada em seu catálogo, é necessário que você cadastre o estoque dos seus produtos,
          e tenha o meio de pagamento Emepag configurado em seu catálogo. Caso tenha alguma dúvida, ou deseje realizar a configuração do meio de pagamento,
           por favor, entre em contato com o nosso suporte no botão de suporte."
          cardColor="danger"
        />
        <div className="border-bottom">
          <div className="my-2">
            <h3>WhatsApp</h3>
          </div>
          <ConfigurationSwitchItem<FormData>
            label="Botão do WhatsApp no catálogo"
            description="Ao ativar essa opção, aparecerá um botão flutuante do WhatsApp no seu catálogo, para que os clientes consigam entrar em contato com você diretamente através do WhatsApp."
            registerName="is_whatsapp_button_enabled"
            register={register}
            defaultChecked={companyData.is_whatsapp_button_enabled ? true : false}
            hasBottomBorder={false}
          />

          <ConfigurationInputItem<FormData>
            label="Link do botão do WhatsApp"
            register={register}
            registerName="whatsapp_button_url"
            defaultValue={companyData.whatsapp_button_url ?? ""}
            tooltipMessage="Caso você utilize algum link especial para direcionar os clientes para o seu WhatsApp, como um LinkTree ou qualquer outro, poderá inserir esse link aqui.
            Caso contrário, deixar o campo em branco direcionará os clientes diretamente para o WhatsApp cadastrado no catálogo"
            hasBottomBorder={false}
          />
        </div>
        <div className="border-bottom">
          <div className="my-2">
            <h3>Manutenção</h3>
          </div>
          <ConfigurationSwitchItem<FormData>
            label="Ativar Modo de Manutenção"
            description="Ao ativar essa opção, você desabilita o seu catálogo temporariamente, e os clientes que tentarem acessar o seu catálogo serão levados para uma página de manutenção"
            registerName="is_maintenance_mode_enabled"
            register={register}
            defaultChecked={companyData.is_maintenance_mode_enabled ? true : false}
            hasBottomBorder={false}
          />

          <ConfigurationInputItem<FormData>
            label="Mensagem da página de manutenção"
            register={register}
            registerName="maintenance_mode_message"
            defaultValue={companyData.maintenance_mode_message ?? ""}
            tooltipMessage="Mensagem que será exibida na página de manutenção. Você pode utilizar esse campo, por exemplo, para informar aos seus clientes quando o catálogo estará de volta.
            Se deixado em branco, os clientes verão apenas a logomarca da sua empresa."
            hasBottomBorder={false}
          />
        </div>
        <div className={"border-bottom d-flex flex-column py-3 align-items-start"}>
          <div className="d-flex flex-row">
            <h5 className="m-0">Vendedor padrão</h5>
            <QuestionToolTip
              id="default-seller-id"
              message="Quando o vendedor envia o seu próprio link, os pedidos feitos por esse link só aparecerão para aquele vendedor.
            Porém, se o cliente acessar o link padrão do catálogo, ao invés do link de um vendedor, o pedido será atribuído ao vendedor que você selecionar no campo Vendedor padrão."
            />
          </div>
          <span className="text-black-50 my-2">
            Define para qual vendedor serão enviados os pedidos, quando não tiverem um vendedor especificado
          </span>
          <Select
            options={sellers.map((s) => ({ label: s.name, value: s.id }))}
            placeholder={"Selecionar vendedor "}
            noOptionsMessage={() => "Nenhum vendedor encontrado"}
            defaultValue={sellers
              .map((s) => ({ label: s.name, value: s.id }))
              .find((s) => s.value === companyData.default_seller?.id)}
            styles={{
              control: (defaultStyles, props) => ({
                ...(reactSelectStyles?.control?.(defaultStyles, props) ?? {}),
                border: "1px solid #B6B6B6 !important",
                width: 234,
              }),
            }}
            onChange={(option) => {
              const value = (option as SelectOption).value;
              setValue("default_seller_id", value);
            }}
          />
          <div className={`card bg-warning text-white mt-3`} style={{ maxWidth: "800px" }}>
            <div className="card-body">
              <p className="card-text">
                Se o seu catálogo não possui múltiplos usuários vendedores, não é necessário escolher um vendedor
                padrão, pois todos os pedidos aparecerão no Painel Administrativo do usuário administrador ou do único
                vendedor.
              </p>
            </div>
          </div>
        </div>
        <div className="border-bottom">
          <div className="my-2">
            <h3>Descontos</h3>
            <h5 className="text-black-50">
              Define o valor dos descontos que os clientes podem receber em seus pedidos sob algumas condições, caso
              você os ofereça
            </h5>
          </div>
          <ConfigurationInputItem<FormData>
            label="Desconto para pagamentos à vista (%)"
            register={register}
            registerName="cash_payment_discount_percentage"
            defaultValue={companyData.cash_payment_discount_percentage ?? ""}
            tooltipMessage="O cliente receberá esse desconto se informar na finalização do pedido que o pagamento será feito à vista. O desconto é percentual."
            hasBottomBorder={false}
          />
          <div className={"d-flex flex-column align-items-start"}>
            <div className="d-flex flex-row">
              <h5>Tipo de desconto para CNPJ</h5>
              <QuestionToolTip
                id="cnpj-discount-type"
                message="Neste campo você determina se o desconto para clientes que informem o CNPJ será através de uma porcentagem do valor total do pedido, ou de um valor fixo, em reais."
              />
            </div>
            <Select
              options={cnpjDiscountTypeOptions}
              placeholder={"Selecione um tipo de desconto"}
              className={"my-2"}
              defaultValue={cnpjDiscountTypeOptions.find((o) => o.value === companyData.cnpj_discount_type)}
              onChange={(option) => {
                const value = (option as SelectOption).value;
                setValue("cnpj_discount_type", value);
              }}
              styles={{
                control: (defaultStyles, props) => ({
                  ...(reactSelectStyles?.control?.(defaultStyles, props) ?? {}),
                  minWidth: 234,
                }),
              }}
            />
          </div>
          {getValues("cnpj_discount_type") === "percentage" && (
            <div className={"d-flex flex-column align-items-start py-3"}>
              <div className="d-flex flex-row">
                <h5>Desconto para clientes que informem o CNPJ (%)</h5>
                <QuestionToolTip
                  id="cnpj-discount-percentage"
                  message="O cliente receberá esse desconto caso informe o CNPJ como documento na finalização do pedido. Nesse caso o desconto será proporcional à porcentagem inserida."
                />
              </div>
              <input
                className={"text-input"}
                {...register("cnpj_discount_percentage", { valueAsNumber: true })}
                style={{ width: "auto" }}
                defaultValue={companyData.cnpj_discount_percentage}
              />
            </div>
          )}
          {getValues("cnpj_discount_type") === "fixed" && (
            <div className={"py-3"}>
              <div className="d-flex flex-row">
                <h5>Desconto para clientes que informem o CNPJ (R$)</h5>
                <QuestionToolTip
                  id="cnpj-discount-fixed"
                  message="O cliente receberá esse desconto caso informe o CNPJ como documento na finalização do pedido. Nesse caso o desconto será exatamente do valor inserido."
                />
              </div>
              <CurrencyInput
                {...register("cnpj_discount_value", { valueAsNumber: true })}
                className="text-input me-2"
                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                decimalSeparator={","}
                groupSeparator={"."}
                style={{ width: "auto" }}
                defaultValue={companyData.cnpj_discount_value}
                onValueChange={(value) => {
                  setValue("cnpj_discount_value", value ? +value.replace(",", ".") : 0);
                }}
              />
            </div>
          )}

          <div className={`card bg-warning text-white mb-3`} style={{ maxWidth: "800px" }}>
            <div className="card-body">
              <p className="card-text">
                Caso deseje oferecer aos seus clientes algum desconto não apresentado aqui, você pode verificar a aba de
                “Cupons”
              </p>
            </div>
          </div>
        </div>
        <div className="border-bottom">
          <div className="my-2">
            <h3>Pedido mínimo</h3>
            <h5 className="text-black-50">
              Define os requisitos mínimos para que um cliente consiga enviar um pedido no seu catálogo.
            </h5>
          </div>
          <div className="d-flex flex-column align-items-start py-3">
            <div className="d-flex flex-row">
              <h5>Valor mínimo do pedido</h5>
              <QuestionToolTip
                id="minimum-order-value"
                message="Esse é o valor mínimo em produtos na Sacola para que o cliente consiga enviar o pedido no seu catálogo."
              />
            </div>
            <CurrencyInput
              {...register("minimun_order_value", { valueAsNumber: true })}
              className="text-input me-2"
              intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              decimalSeparator={","}
              groupSeparator={"."}
              style={{ width: "auto" }}
              defaultValue={companyData.minimun_order_value}
              onValueChange={(value) => {
                setValue("minimun_order_value", value ? +value.replace(",", ".") : 0);
              }}
            />
          </div>

          <div className={"d-flex flex-column align-items-start py-3"}>
            <div className="d-flex flex-row">
              <h5>Número mínimo de peças por pedido</h5>
              <QuestionToolTip
                id="minimum-pieces-number"
                message="Essa é a quantidade mínima de produtos que um cliente terá que adicionar à Sacola para que consiga enviar um pedido."
              />
            </div>
            <input
              className={"text-input me-2"}
              {...register("minimun_pieces_number", { valueAsNumber: true })}
              style={{ width: "auto" }}
              defaultValue={companyData.minimun_pieces_number}
            />
          </div>
          <div className={`card bg-warning text-white mb-3`} style={{ maxWidth: "800px" }}>
            <div className="card-body">
              <p className="card-text">
                Você pode colocar ambas as condições de valor mínimo do pedido e número mínimo de produtos, mas
                lembre-se que o pedido terá que atender a ambas as condições para que o cliente consiga enviá-lo.
              </p>
            </div>
          </div>
        </div>
        <ConfigurationInputItem<FormData>
          label="Token Emepag"
          description="Token de integração necessário para que você utilize o meio de pagamento Emepag para receber através do cartão de crédito, pix e boleto."
          register={register}
          defaultValue={companyData.emepag_api_token ?? ""}
          registerName="emepag_api_token"
        />
        <ConfigurationInputItem<FormData>
          label="Token Emepag V2"
          description="Token de integração necessário para que você utilize o meio de pagamento Emepag para receber através do cartão de crédito, pix e boleto."
          register={register}
          defaultValue={companyData.emepag_v2_api_token ?? ""}
          registerName="emepag_v2_api_token"
        />
        <ConfigurationInputItem<FormData>
          label="Meta (Facebook) Pixel Code"
          description="Código de integração necessário para que você utilize as funcionalidades do Pixel da Meta (Facebook)."
          register={register}
          defaultValue={companyData.meta_pixel ?? ""}
          registerName="meta_pixel"
        />
        <ConfigurationInputItem<FormData>
          label="Token Pode Organizar"
          description="Token de integração da plataforma Pode Organizar"
          register={register}
          defaultValue={companyData.pod_organizar_api_token ?? ""}
          registerName="pod_organizar_api_token"
        />

        <ConfigurationInputItem<FormData>
          label="ID do container GTM"
          description="ID do container do Google Tag Manager (Automaticamente ativado quandoe este campo é preenchido)"
          register={register}
          defaultValue={companyData.gtm_container_id ?? ""}
          registerName="gtm_container_id"
        />

        <div className={"d-flex flex-row py-3 align-items-center"}>
          <button className={"btn btn-primary"} disabled={updating}>
            {updating ? "Atualizando..." : "Atualizar"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default GeneralSettings;
