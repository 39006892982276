import * as React from "react";
import { CustomerWithLastOrder } from "@utils/types";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@services/Redux";
import DataTable, { TableColumn } from "react-data-table-component";
import LoadingIndicator from "@components/LoadingIndicator";
import tableStyles from "@styles/table";
import { getStatusColor } from "@utils/functions";
import StatusPill from "@components/StatusPill";
import CustomersFilters from "./Filters";
import { fetchCustomers, setCustomersFilters } from "@services/Redux/customers";
import { Link } from "react-router-dom";
import { useWindowSize } from "@utils/hooks";

const columns: TableColumn<CustomerWithLastOrder>[] = [
  {
    name: "Nome",
    selector: (row) => row.name,
    sortable: true,
    cell: (row) => <Link to={`/clientes/${row.id}`}>{row.name}</Link>,
  },
  {
    name: "Email",
    selector: (row) => row.email,
  },
  {
    name: "Telefone",
    selector: (row) => row.phone,
  },
  {
    name: "Código do último pedido",
    selector: (row) => row.last_order.code,
    cell: (row) => <Link to={`/pedido/${row.last_order.code}`}>{row.last_order.code}</Link>,
  },
  {
    name: "Status do último pedido",
    cell: (row) => <StatusPill title={row.last_order.status} bgColor={getStatusColor(row.last_order.status)} />,
  },
];

const CustomersPage: React.FC = () => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const {
    data: customers,
    filters,
    count: customersCount,
    loading,
  } = useSelector((state: RootState) => state.customers);
  const [searchTerm, setSearchTerm] = React.useState("");
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  const handlePerPageChange = (perPage: number) => {
    dispatch(setCustomersFilters({ ...filters, perPage }));
  };

  const handlePageChange = (page: number) => {
    dispatch(setCustomersFilters({ ...filters, page }));
  };

  React.useEffect(() => {
    if (companyData.id) {
      dispatch(fetchCustomers());
    }
    // eslint-disable-next-line
  }, [companyData, filters.status, filters.sort, filters.page, filters.perPage, filters.searchTerm]);

  React.useEffect(() => {
    if (filters.dateRangeSelectStep === 1) dispatch(fetchCustomers());
    // eslint-disable-next-line
  }, [companyData, filters.dateRangeSelectStep]);

  return (
    <div>
      <div className="row m-0 p-0 mb-4">
        <CustomersFilters />
        <div className="col-auto p-0 m-0">
          <form
            className="col m-0 p-0"
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(setCustomersFilters({ ...filters, searchTerm }));
            }}
          >
            <div className="row p-0 m-0">
              <div className={"col col-lg-auto p-0 m-0"}>
                <input
                  className={"text-input"}
                  placeholder={"Busque por clientes"}
                  value={searchTerm}
                  style={{ maxWidth: 300, minWidth: width >= 768 ? 400 : 200 }}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                />
              </div>
              <div className={"col-auto p-0 m-0 ms-3"}>
                <button type={"submit"} className={"btn btn-primary"}>
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={customers}
        progressPending={loading}
        progressComponent={<LoadingIndicator />}
        customStyles={tableStyles}
        noDataComponent="Nenhum cliente encontrado"
        pagination
        paginationServer
        paginationPerPage={filters.perPage}
        paginationTotalRows={customersCount}
        onChangeRowsPerPage={handlePerPageChange}
        onChangePage={handlePageChange}
        responsive
      />
    </div>
  );
};

export default CustomersPage;
