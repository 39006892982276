import Analytics from "@components/Analytics";
import store from "@services/Redux";
import env from "@utils/env";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

LogRocket.init("7twswy/vende-moda-backoffice");
setupLogRocketReact(LogRocket);

console.log(env, "env");

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize(env.REACT_APP_ANALYTICS_ID);
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <Analytics />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root"),
);
