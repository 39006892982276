import { RootState } from "@services/Redux";
import env from "@utils/env";
import { toastMessage } from "@utils/functions";
import { UserRole } from "@utils/types";
import * as React from "react";
import ReactGA from "react-ga4";
import { AiOutlineDashboard } from "react-icons/ai";
import { BiCog, BiExit, BiHomeAlt, BiPackage, BiStoreAlt, BiUser } from "react-icons/bi";
import { BsClipboardData } from "react-icons/bs";
import { IoMdPerson } from "react-icons/io";
import { IoShirtOutline } from "react-icons/io5";
import { RiCouponLine } from "react-icons/ri";
import { TbReport } from "react-icons/tb";
import { useSelector } from "react-redux";
import SidebarLink from "./Link";
import Styled from "./styled";

const Sidebar: React.FC = () => {
  const isSidebarToggled = useSelector((state: RootState) => state.app.isSidebarToggled);
  const companyData = useSelector((state: RootState) => state.company.data);
  const userId = localStorage.getItem("user_id");
  const userRole = localStorage.getItem("user_role");
  const userName = localStorage.getItem("user_name");
  const companyLogo = companyData.logo_image;

  const handleSellerLinkButtonClick = (userRole: UserRole) => {
    const url = `${env.REACT_APP_CLIENT_URL}`.replace("https://", "").replace("http://", "");
    userRole === UserRole.COMPANY_SELLER
      ? navigator.clipboard.writeText(`https://${companyData.code}.${url}/?vendedor=${userId}`)
      : navigator.clipboard.writeText(`https://${companyData.code}.${url}`);
    ReactGA.event({
      action: "copy_seller_link",
      category: "Seller",
      label: "Copiou link de vendedor",
    });
    toastMessage("Link copiado", "success");
  };

  return (
    <Styled.RootContainer className={"border-end pb-5"} is_toggled={isSidebarToggled}>
      <div className={"d-flex flex-column align-items-center justify-content-center mt-4 mb-2"}>
        <Styled.Logo
          className={"border"}
          src={`${companyLogo?.base_url}/companies/${companyData.id}/media/thumb_3/${companyLogo?.key}`}
        />
        <span className={"mt-1"}>{companyData.name}</span>
        <span>{userName}</span>
        {userRole === UserRole.COMPANY_SELLER && (
          <Styled.SellerLink onClick={() => handleSellerLinkButtonClick(UserRole.COMPANY_SELLER)}>
            Copiar link de vendedor
          </Styled.SellerLink>
        )}
        {userRole === UserRole.COMPANY_ADMIN && (
          <Styled.SellerLink onClick={() => handleSellerLinkButtonClick(UserRole.COMPANY_ADMIN)}>
            Copiar link do catálogo
          </Styled.SellerLink>
        )}
      </div>
      <div className={"d-flex justify-content-center"}>
        <Styled.MenuList>
          {userRole === UserRole.ADMIN ? (
            <>
              <li>
                <SidebarLink to={"/admin_dashboard"}>
                  <AiOutlineDashboard />
                  <span>Dashboard admin</span>
                </SidebarLink>
              </li>
              <li>
                <SidebarLink to={"/marcas"}>
                  <BiStoreAlt />
                  <span>Marcas</span>
                </SidebarLink>
              </li>
            </>
          ) : (
            <>
              <li>
                <SidebarLink to={"/dashboard"}>
                  <BiHomeAlt />
                  <span>Início</span>
                </SidebarLink>
              </li>
              <li>
                <SidebarLink to={"/pedidos"}>
                  <BsClipboardData />
                  <span>Pedidos</span>
                </SidebarLink>
              </li>
              <li>
                <SidebarLink to={"/produtos"}>
                  <IoShirtOutline />
                  <span>Produtos</span>
                </SidebarLink>
              </li>
              {companyData.is_pre_customers_enabled ? (
                <li className={"d-flex flex-column align-items-start"}>
                  <SidebarLink to={"/clientes"}>
                    <IoMdPerson />
                    <span>Clientes</span>
                  </SidebarLink>
                  <Styled.MenuSubList>
                    <li>
                      <SidebarLink to={"/clientes/gerais"}>
                        <span>Compradores</span>
                      </SidebarLink>
                    </li>
                    <li>
                      <SidebarLink to={"/clientes/pre-cadastro"}>
                        <span>Pré cadastrados</span>
                      </SidebarLink>
                    </li>
                  </Styled.MenuSubList>
                </li>
              ) : (
                <li>
                  <SidebarLink to={"/clientes"}>
                    <IoMdPerson />
                    <span>Clientes</span>
                  </SidebarLink>
                </li>
              )}
              <li>
                <SidebarLink to={"/estoque"}>
                  <BiPackage />
                  <span>Estoque</span>
                </SidebarLink>
              </li>
              <li>
                <SidebarLink to={"/cupons"}>
                  <RiCouponLine />
                  <span>Cupons</span>
                </SidebarLink>
              </li>
              {[UserRole.COMPANY_ADMIN, UserRole.ADMIN].indexOf(userRole as UserRole) >= 0 && (
                <>
                  <li>
                    <SidebarLink to={"/vendedores"}>
                      <BiUser />
                      <span>Vendedores</span>
                    </SidebarLink>
                  </li>

                  <li>
                    <SidebarLink to={"/relatorios"}>
                      <TbReport />
                      <span>Relatórios</span>
                    </SidebarLink>
                  </li>

                  <li className={"d-flex flex-column align-items-start"}>
                    <SidebarLink to={"/configuracoes"}>
                      <BiCog />
                      <span>Configurações</span>
                    </SidebarLink>
                    <Styled.MenuSubList>
                      <li>
                        <SidebarLink to={"/configuracoes/gerais"}>
                          <span>Gerais</span>
                        </SidebarLink>
                      </li>
                      <li>
                        <SidebarLink to={"/configuracoes/gerenciar-estoque"}>
                          <span>Gerenciar estoque</span>
                        </SidebarLink>
                      </li>
                      <li>
                        <SidebarLink to={"/configuracoes/cores"}>
                          <span>Cores</span>
                        </SidebarLink>
                      </li>
                      <li>
                        <SidebarLink to={"/configuracoes/tamanhos"}>
                          <span>Tamanhos</span>
                        </SidebarLink>
                      </li>
                      <li>
                        <SidebarLink to={"/configuracoes/integracoes"}>
                          <span>Integrações</span>
                        </SidebarLink>
                      </li>
                    </Styled.MenuSubList>
                  </li>
                </>
              )}
            </>
          )}
          <li>
            <SidebarLink to={"/logout"}>
              <BiExit />
              <span>Sair</span>
            </SidebarLink>
          </li>
        </Styled.MenuList>
      </div>
    </Styled.RootContainer>
  );
};

export default Sidebar;
