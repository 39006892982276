import * as React from "react";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import { toastError, toastMessage } from "@utils/functions";
import { User } from "@utils/types";
import { useSelector } from "react-redux";
import DataTable, { TableColumn } from "react-data-table-component";
import tableStyles from "@styles/table";
import { Link, useNavigate } from "react-router-dom";
import LoadingIndicator from "@components/LoadingIndicator";
import FakeLink from "@components/FakeLink";
import { confirmAlert } from "react-confirm-alert";

const ListSellersPage: React.FC = () => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const [sellers, setSellers] = React.useState<User[]>([]);
  const [sellersCount, setSellersCount] = React.useState(0);
  const [loadingSellers, setLoadingSellers] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const navigate = useNavigate();

  const columns = React.useMemo<TableColumn<User>[]>(
    () => [
      {
        name: "Id",
        selector: (seller) => seller.id,
      },
      {
        name: "Nome",
        selector: (seller) => seller.name,
      },
      {
        name: "Email",
        selector: (seller) => seller.email,
      },
      {
        name: "Telefone",
        selector: (seller) => seller.phone,
      },
      {
        button: true,
        name: "Ações",
        selector: (seller) => seller.id,
        cell: (seller) => (
          <>
            <Link
              style={{ textDecoration: "none" }}
              to={`/vendedores/${seller.id}/editar`}
              className={"text-primary me-2"}
            >
              Detalhes
            </Link>
            {seller.role !== "company_admin" && (
              <FakeLink
                onClick={() => {
                  handleDeleteSeller(seller);
                }}
                className={"text-danger"}
              >
                Excluir
              </FakeLink>
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line
    [companyData, sellers],
  );

  const handleDeleteSeller = async (user: User) => {
    confirmAlert({
      title: "Atenção!",
      message: `Deseja realmente excluir o vendedor ${user.name}?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.delete(`/company/${companyData.id}/seller/${user.id}`);
              fetchSellers();
              toastMessage(`Concluído.`, "success");
            } catch (error) {
              toastError(error);
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handlePerPageChange = (perPage: number) => {
    setPerPage(perPage);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const fetchSellers = async () => {
    try {
      setLoadingSellers(true);
      const { data } = await Api.get(`/company/${companyData.id}/seller`);
      setSellers(data.sellers);
      setSellersCount(data.count);
      setLoadingSellers(false);
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    if (companyData.id) fetchSellers();
    // eslint-disable-next-line
  }, [companyData, page, perPage]);

  return (
    <div>
      <div className="mb-4">
        <button
          onClick={() => {
            navigate("/vendedores/cadastrar");
          }}
          type={"submit"}
          className={"btn btn-primary"}
        >
          Adicionar novo vendedor
        </button>
      </div>
      <div className={"rounded-3"} style={{ backgroundColor: "white" }}>
        <DataTable
          columns={columns}
          data={sellers}
          progressPending={loadingSellers}
          progressComponent={<LoadingIndicator />}
          customStyles={tableStyles}
          noDataComponent="Você ainda não cadastrou nenhum vendedor"
          pagination
          paginationServer
          paginationPerPage={perPage}
          paginationTotalRows={sellersCount}
          onChangeRowsPerPage={handlePerPageChange}
          onChangePage={handlePageChange}
          responsive
        />
      </div>
    </div>
  );
};

export default ListSellersPage;
