import * as React from "react";
import Api from "@services/Api";
import { toastError } from "@utils/functions";
import { Company, OrderStatus } from "@utils/types";
import DataTable, { TableColumn } from "react-data-table-component";
import tableStyles from "@styles/table";
import LoadingIndicator from "@components/LoadingIndicator";
import { Money } from "@utils/money";
import { useWindowSize } from "@utils/hooks";
import { useNavigate } from "react-router-dom";

type CompanyWithStatusMetrics = {
  total_sales_value: number;
  products_count: number;
} & Partial<Company> & {
    [key in OrderStatus]: number;
  };

const AdminCompaniesList: React.FC = () => {
  const [companies, setCompanies] = React.useState<CompanyWithStatusMetrics[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const fetchCompanies = async () => {
    try {
      const { data } = await Api.get(`/metrics/companies`);
      setCompanies(data.result);
      setLoading(false);
    } catch (error) {
      toastError(error);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredCompanies = companies.filter(
    (company) =>
      company?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company?.code?.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  React.useEffect(() => {
    fetchCompanies();
  }, []);

  const columns = React.useMemo<TableColumn<CompanyWithStatusMetrics>[]>(
    () => [
      {
        name: "Nome",
        selector: (row) => row.name ?? "N/D",
        cell: (row) => <div title={row.code}>{row.name ?? "N/D"}</div>,
      },
      {
        name: "Telefone",
        selector: (row) => row.phone ?? "N/D",
      },

      {
        name: "Total transacionado",
        selector: (row) => row.total_sales_value,
        sortable: true,
        cell: (row) => `${Money(row.total_sales_value).format()}`,
      },
      {
        name: "Ticket médio",
        selector: (row) => (row.paid > 0 ? Money(row.total_sales_value).divide(row.paid).intValue : Money(0).intValue),
        sortable: true,
        cell: (row) => `${row.paid > 0 ? Money(row.total_sales_value).divide(row.paid).format() : Money(0).format()}`,
      },
      {
        name: "Produtos",
        selector: (row) => row.products_count,
        sortable: true,
      },
      {
        name: "Pedidos pagos",
        selector: (row) => row.paid,
        sortable: true,
      },
      {
        name: "Pedidos criados",
        selector: (row) => row.created,
        sortable: true,
      },
      {
        name: "Pedidos em espera",
        selector: (row) => row.waiting_seller_confirmation,
        sortable: true,
      },
      {
        name: "Pedidos aguardando pagto.",
        selector: (row) => row.ready_to_pay,
        sortable: true,
      },

      {
        name: "Pedidos cancelados",
        selector: (row) => row.cancelled,
        sortable: true,
      },
      {
        name: "Data de Criação",
        selector: (row) => {
          const date = new Date(row.created_at?.toString() ?? "");
          return date.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          });
        },
        sortable: true,
      },
      {
        name: "Usuários",
        cell: (row) => (
          <div>
            <button
              className={"btn btn-primary"}
              onClick={() => {
                navigate(`/marcas/${row.id}/detalhes`);
              }}
            >
              Consultar
            </button>
          </div>
        ),
      },
      {
        name: "Ações",
        cell: (row) => (
          <div>
            <button
              className={"btn btn-primary"}
              onClick={() => {
                navigate(`/marcas/${row.code}/editar`);
              }}
            >
              Editar
            </button>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    [companies],
  );

  return (
    <>
      {" "}
      <input
        className="text-input mb-3"
        type="text"
        style={{ maxWidth: 300, minWidth: width >= 768 ? 400 : 200 }}
        placeholder="Busque por nome ou código da empresa"
        onChange={handleSearch}
      />
      <div className={"row"}>
        <div className={"col-12 rounded-3"} style={{ backgroundColor: "white" }}>
          <DataTable
            columns={columns}
            data={filteredCompanies}
            progressPending={loading}
            progressComponent={<LoadingIndicator />}
            customStyles={tableStyles}
            noDataComponent="Você ainda não cadastrou nenhum produto"
            pagination
            paginationPerPage={50}
            paginationTotalRows={companies.length}
            responsive
          />
        </div>
      </div>
    </>
  );
};

export default AdminCompaniesList;
