import { useState } from "react";
import { BlingProduct } from "../interfaces";
import Api from "@services/Api";
import { toastError } from "@utils/functions";

export interface FetchBlingProductsOptions {
  name?: string;
  page?: number;
  code?: string;
  category?: string;
}

export const useBlingProducts = () => {
  const [products, setProducts] = useState<BlingProduct[]>([]);
  const [fetchingProducts, setFetchingProducts] = useState(true);
  const [page, setPage] = useState(1);

  const fetchProducts = async (options?: FetchBlingProductsOptions) => {
    setFetchingProducts(true);
    try {
      let url = "/integrations/bling/products?page=1";
      if (options?.name) {
        url += `&name=${options.name}`;
      }
      if (options?.code) {
        url += `&code=${options.code}`;
      }
      if (options?.category) {
        url += `&category=${options.category}`;
      }
      const { data } = await Api.get(url);
      setProducts(data.products);
      setPage(1);
    } catch (error) {
      toastError(error);
    } finally {
      setFetchingProducts(false);
    }
  };

  const fetchMoreProducts = async (targetPage?: number) => {
    setFetchingProducts(true);
    try {
      const { data } = await Api.get(`/integrations/bling/products?page=${targetPage ?? page + 1}`);
      setProducts([...products, ...data.products]);
      setPage(targetPage ?? page + 1);
    } catch (error) {
      toastError(error);
    } finally {
      setFetchingProducts(false);
    }
  };

  return {
    products,
    fetchingProducts,
    fetchProducts,
    fetchMoreProducts,
  };
};
