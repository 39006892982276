import styled from "styled-components";

const RootContainer = styled.div<{ is_toggled: boolean }>`
  z-index: 4;
  width: 255px;
  min-width: 255px;
  position: relative;
  left: ${({ is_toggled }) => (is_toggled ? "0px" : "-255px")};
  background-color: white;
  height: calc(100vh - 60px);
  padding-bottom: 10px;
  transition: left 1s;
  overflow: auto;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  span {
    margin-left: 10px;
    font-size: 18px;
  }
  svg {
    width: 28px;
    height: 28px;
  }
`;

const MenuSubList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 40px;
  li {
    margin: 0;
    padding: 4px 0px;
    font-size: 12px;
  }
`;

const Logo = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 100px;
`;

const SellerLink = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 12px;
  color: #00a8ff;
  padding: 0;
  margin: 0;
`;

const modules = {
  RootContainer,
  MenuList,
  Logo,
  MenuSubList,
  SellerLink,
};

export default modules;
