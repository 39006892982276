import { getStatusLabel } from "@utils/functions";
import { OrderStatus } from "@utils/types";
import * as React from "react";
import Styled from "./styled";

export interface StatusPillProps {
  title: string;
  bgColor: React.CSSProperties["backgroundColor"];
}

const StatusPill: React.FC<StatusPillProps> = (props) => {
  return (
    <Styled.RootContainer {...props}>
      <Styled.Circle {...props} />
      <div>{getStatusLabel(props.title as OrderStatus)}</div>
    </Styled.RootContainer>
  );
};

export default StatusPill;
