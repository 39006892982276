import FullScreenLoading from "@components/FullScreenLoading";
import LoadingIndicator from "@components/LoadingIndicator";
import Api from "@services/Api";
import tableStyles from "@styles/table";
import { toastError } from "@utils/functions";
import { Order, OrderPayment, OrderStatus } from "@utils/types";
import * as React from "react";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router";
import orderPaymentsColumns from "./columns";

const OrderPaymentsPage: React.FC = () => {
  const { order_code } = useParams();
  const [payments, setPayments] = React.useState<OrderPayment[]>([]);
  const [loadingPayments, setLoadingPayments] = React.useState(true);
  const [order, setOrder] = React.useState<Order>();
  const [loadingOrder, setLoadingOrder] = React.useState(true);
  const navigate = useNavigate();

  const fetchOrder = async () => {
    try {
      const { data } = await Api.get(`/order/code/${order_code}`);
      setOrder(data.order);
      setLoadingOrder(false);
    } catch (error) {
      toastError(error);
    }
  };

  const fetchPayments = async () => {
    try {
      const { data } = await Api.get(`/order/${order?.id}/payment`);
      setPayments(data.payments);
      setLoadingPayments(false);
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    fetchOrder();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (order) {
      fetchPayments();
    }
    // eslint-disable-next-line
  }, [order]);

  if (loadingOrder || loadingPayments || !order) return <FullScreenLoading />;

  return (
    <div>
      <div className={"mb-3"}>
        {![OrderStatus.PAID, OrderStatus.IN_ANALYSIS, OrderStatus.CREATED].includes(order.status) && (
          <button
            className={"btn btn-primary"}
            onClick={() => {
              navigate(`/pedidos/${order?.code}/pagamentos/confirmar`);
            }}
          >
            Confirmar pagamento manualmente
          </button>
        )}
      </div>
      <DataTable
        columns={orderPaymentsColumns}
        data={payments}
        progressPending={loadingPayments}
        progressComponent={<LoadingIndicator />}
        customStyles={tableStyles}
        noDataComponent="Este pedido ainda não possui nenhum pagamento"
        pagination
        responsive
      />
    </div>
  );
};

export default OrderPaymentsPage;
