import { CreditCardBrands, PaymentGateways, PaymentMethod, PaymentStatus } from "@utils/types";

export const statusOptions = [
  { value: PaymentStatus.ANALYSIS, label: "Em análise" },
  { value: PaymentStatus.PAID, label: "Pago" },
  { value: PaymentStatus.CANCELED, label: "Cancelado" },
  { value: PaymentStatus.EXPIRED, label: "Expirado" },
  { value: PaymentStatus.REJECTED, label: "Rejeitado" },
];

export const paymentMethodOptions = [
  { value: PaymentMethod.CREDIT_CARD, label: "Cartão de crédito" },
  { value: PaymentMethod.BOLETO, label: "Boleto" },
  { value: PaymentMethod.PIX, label: "Pix" },
  { value: PaymentMethod.BANK_TRANSFER, label: "Transferência bancária" },
];

export const gatewayOptions = [
  { value: PaymentGateways.EMEPAG, label: "Emepag" },
  { value: PaymentGateways.MANUAL, label: "Manual" },
  { value: PaymentGateways.WIRECARD, label: "Wirecard" },
  { value: PaymentGateways.YAPAY, label: "Yapay" },
];

export const creditCardBrandOptions = [
  { value: CreditCardBrands.VISA, label: "Visa" },
  { value: CreditCardBrands.MASTERCARD, label: "Mastercard" },
  { value: CreditCardBrands.AMEX, label: "American Express" },
  { value: CreditCardBrands.ELO, label: "Elo" },
  { value: CreditCardBrands.DINERS, label: "Diners" },
  { value: CreditCardBrands.HIPERCARD, label: "Hipercard" },
  { value: CreditCardBrands.AURA, label: "Aura" },
  { value: CreditCardBrands.JCB, label: "JCB" },
  { value: CreditCardBrands.AMERICAN_EXPRESS, label: "American Express" },
  { value: CreditCardBrands.HIPER_ITAU, label: "Hipercard (Itaú)" },
  { value: CreditCardBrands.DISCOVER, label: "Discover" },
];
