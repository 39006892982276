import { RootState } from "@services/Redux";
import env from "@utils/env";
import * as React from "react";
import { useSelector } from "react-redux";
import Modal, { ModalProps } from "react-responsive-modal";

const EditOrderModal: React.FC<ModalProps & { orderCode: string }> = ({ orderCode, ...props }) => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  React.useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.data === "client_ready") {
        setTimeout(() => {
          const iframes = document.querySelector("iframe");
          const token = localStorage.getItem("user_token");
          iframes?.contentWindow?.postMessage({ token }, "*");
        }, 1000);
      }
    });
  }, []);

  let url =
    process.env.NODE_ENV === "production"
      ? `${env.REACT_APP_CLIENT_URL}`.replace("https://", "").replace("http://", "")
      : `http://${companyData.code}.localhost:3002?marca=${companyData.code}&order_code=${orderCode}&seller_mode=true`;
  if (process.env.NODE_ENV === "production")
    url = `https://${companyData.code}.${url}?marca=${companyData.code}&order_code=${orderCode}&seller_mode=true`;

  return (
    <Modal
      center
      styles={{
        modal: {
          padding: 0,
          margin: 0,
          width: "90vw",
          maxWidth: "400px",
          height: "90vh",
          maxHeight: "800px",
          backgroundColor: "transparent",
          overflow: "hidden",
        },
      }}
      showCloseIcon={false}
      {...props}
    >
      <div style={{ width: "100%", height: "99%", padding: 0, margin: 0 }}>
        <button
          className="btn btn-primary d-flex w-100 justify-content-center align-items-center mb-2"
          style={{ height: 40 }}
          onClick={() => {
            props.onClose?.();
          }}
        >
          Finalizar
        </button>
        <iframe
          ref={iframeRef}
          id={"edit-order-modal"}
          style={{ width: "100%", height: "calc(100% - 40px)", padding: 0, margin: 0 }}
          title="edit-order-modal"
          src={url}
        />
      </div>
    </Modal>
  );
};

export default EditOrderModal;
