import { Theme, StylesConfig } from "react-select";
import { SelectOption } from "@utils/types";

export const reactSelectTheme = (theme: Theme): Theme => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: 40,
    menuGutter: 5,
  },
  colors: {
    ...theme.colors,
    primary25: "#e3e9ff",
    primary: "#2546bb",
    neutral50: "#b4b4b4",
  },
});

type IsMulti = boolean;

export const reactSelectStyles: StylesConfig<SelectOption, IsMulti> = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "black",
  }),
  container: (styles) => ({
    ...styles,
    border: "none",
    borderRadius: "0px",
    boxShadow: "none",
  }),
  control: (base) => ({
    ...base,
    border: "1px solid #d4d2d2 !important",
    borderRadius: "8px !important",
    minWidth: 200,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0 5px",
    height: "auto",
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: "0px",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "black",
    fontSize: "14px",
  }),
  option: (base) => ({ ...base }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
    fontSize: "14px",
  }),
  multiValue: (base) => ({ ...base, color: "white", backgroundColor: "#2546bb" }),
  multiValueLabel: (base) => ({ ...base, color: "white" }),
  input: (base) => ({
    ...base,
    height: 43,
    padding: "0px",
    margin: "0px",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999, borderRadius: 0 }),
};
