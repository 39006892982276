import { AiFillPlusCircle } from "react-icons/ai";
import styled from "styled-components";

const RootContainer = styled.div`
  background-color: white;
`;

const AddImageButton = styled(AiFillPlusCircle)`
  :hover {
    cursor: pointer;
  }
`;

const ProductImagesContainer = styled.div`
  border: 1px solid rgb(182, 182, 182);
  border-radius: 8px;
  padding: 10px;
  min-height: 100px;
  position: relative;
  :hover {
    cursor: unset;
  }
`;

const ProductImageActionsContainer = styled.div`
  width: 100%;
  background-color: #ededed;
  height: 0px;
  z-index: 3;
  display: flex;
  height: 50px;
  max-height: 0px;
  opacity: 0;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  @media (max-width: 992px) {
    opacity: 1;
    max-height: 50px;
  }
`;

const ProductImageContainer = styled.div`
  background-color: #ededed;
  height: 150px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  &:hover ${ProductImageActionsContainer} {
    max-height: 50px;
    opacity: 1;
    transition: all 0.3s;
  }
`;

const ProductImagesContainerLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const modules = {
  RootContainer,
  ProductImagesContainer,
  ProductImageContainer,
  ProductImageActionsContainer,
  AddImageButton,
  ProductImagesContainerLoadingOverlay,
};

export default modules;
