import { TableStyles } from "react-data-table-component";

const tableStyles: TableStyles = {
  headCells: {
    style: {
      color: "black",
      fontWeight: "bold",
    },
  },
  noData: {
    style: {
      borderRadius: "8px",
    },
  },
  table: {
    style: {
      borderRadius: "8px",
    },
  },
  progress: {
    style: {
      borderRadius: "8px",
    },
  },

  responsiveWrapper: {
    style: {
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
    },
  },
  pagination: {
    style: {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  },
};

export default tableStyles;
