import * as React from "react";
import { Image, Product } from "@utils/types";
import { BsTrash } from "react-icons/bs";
import styled from "styled-components";

interface ImageItemProps {
  image: Image;
  onDeleteRequest: (image: Image) => void;
  product: Product;
}

const DeleteButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: #f54242;
  padding: 3px 5px;
  border-radius: 6px;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;

/*
  ImageItem components exists so we can conditionally render the delete button without
  having to fill the main sortable component code with this logic.
*/

const SortableImageItem: React.FC<ImageItemProps> = ({ image, onDeleteRequest, product }) => {
  const { base_url, key } = image;
  const [imageLoaded, setImageLoaded] = React.useState(false);

  return (
    <>
      {imageLoaded ? (
        <DeleteButton
          onClick={() => {
            onDeleteRequest(image);
          }}
        >
          <BsTrash color={"white"} size={14} />
        </DeleteButton>
      ) : null}
      <img
        style={{ height: 150, pointerEvents: "none", objectFit: "cover", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" }}
        alt={"product"}
        className={"p-auto m-auto"}
        src={`${base_url}/products/${product.id}/thumb_2/${key}`}
        onLoad={() => setImageLoaded(true)}
      />
    </>
  );
};

export default SortableImageItem;
