import * as React from "react";
import ReactLoading from "react-loading";

const LoadingIndicator: React.FC = () => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
      className="my-4"
    >
      <ReactLoading type={"bars"} color={"black"} height={50} width={50} />
      <span>Buscando dados</span>
    </div>
  );
};

export default LoadingIndicator;
