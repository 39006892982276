import { useAppDispatch } from "@services/Redux";
import { toggleSidebar } from "@services/Redux/app";
import { useWindowSize } from "@utils/hooks";
import * as React from "react";
import { LinkProps, Link, useLocation } from "react-router-dom";
import styled from "styled-components";

/* Typing active as number to avoid react warning about boolean data type  */
const StyledLink = styled(Link)<{ active: number }>`
  display: flex;
  justify-content: center;
  text-decoration: none;
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  span {
    color: ${({ active }) => (active ? "#2546BB" : "black")};
  }
  svg {
    color: ${({ active }) => (active ? "#2546BB" : "#c0c0c0")};
  }
`;

const SidebarLink: React.FC<LinkProps> = ({ children, to, ...props }) => {
  const location = useLocation();
  let match = location.pathname.includes(to.toString());
  const windowSize = useWindowSize();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (windowSize.width < 992) {
      dispatch(toggleSidebar());
    }
  };

  return (
    <>
      <StyledLink active={match ? 1 : 0} to={to} onClick={handleClick} {...props}>
        {children}
      </StyledLink>
    </>
  );
};

export default SidebarLink;
