import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product, SelectOption } from "@utils/types";

interface StockData {
  color_id: number;
  size_id: number;
  quantity: number;
}

interface VariationsData {
  colors: SelectOption[];
  sizes: SelectOption[];
}

interface PriceData {
  color_id: number;
  size_id: number;
  price: number;
}

export interface EditProductState {
  stockData: StockData[];
  variationsData: VariationsData;
  priceData: PriceData[];
  product: Product;
  stockEnabled: boolean;
  loading: boolean;
  loadingPriceData: boolean;
}

export const editProductFormDataInitialState: EditProductState = {
  loading: true,
  stockData: [],
  variationsData: {
    colors: [],
    sizes: [],
  },
  priceData: [],
  product: {} as Product,
  stockEnabled: false,
  loadingPriceData: false,
};

const editProductFormDataReducer = createSlice({
  name: "editProductFormDataReducer",
  initialState: editProductFormDataInitialState,

  reducers: {
    setLoadingEditProductFormData: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setStockData(state, action: PayloadAction<StockData[]>) {
      state.stockData = action.payload;
    },
    setVariationsData(state, action: PayloadAction<VariationsData>) {
      state.variationsData = action.payload;
    },
    setPriceData(state, action: PayloadAction<PriceData[]>) {
      state.priceData = action.payload;
    },
    setProduct(state, action: PayloadAction<Product>) {
      state.product = action.payload;
    },
    setStockEnabled(state, action: PayloadAction<boolean>) {
      state.stockEnabled = action.payload;
    },
    setLoadingPriceData(state, action: PayloadAction<boolean>) {
      state.loadingPriceData = action.payload;
    },
    resetFormData(state) {
      state.product = editProductFormDataInitialState.product;
      state.loading = editProductFormDataInitialState.loading;
      state.stockData = editProductFormDataInitialState.stockData;
      state.variationsData = editProductFormDataInitialState.variationsData;
      state.priceData = editProductFormDataInitialState.priceData;
      state.stockEnabled = editProductFormDataInitialState.stockEnabled;
      state.loadingPriceData = editProductFormDataInitialState.loadingPriceData;
    },
  },
});

export default editProductFormDataReducer.reducer;

export const {
  setStockData,
  setVariationsData,
  setPriceData,
  setProduct,
  setStockEnabled,
  setLoadingEditProductFormData,
  resetFormData,
  setLoadingPriceData,
} = editProductFormDataReducer.actions;
