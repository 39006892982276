import React from "react";
import styled from "styled-components";

const RootContainer = styled.div<{ color: React.CSSProperties["color"] }>`
  width: 100%;
  background-color: white;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  border-bottom: 3px solid ${({ color }) => color};
  border-radius: 8px;
`;

const IconContainer = styled.div<{ color: React.CSSProperties["color"] }>`
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 10px;
  svg {
    width: 25px;
    height: 25px;
  }
`;

const CardInfoText = styled.span`
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
`;

const CardSubDescriptionText = styled.span`
  font-size: 14px;
  font-weight: 200;
  margin-left: 10px;
  align-self: flex-end;
`;

const modules = {
  RootContainer,
  IconContainer,
  CardInfoText,
  CardSubDescriptionText,
};

export default modules;
