import { Image, Product } from "@utils/types";
import * as React from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import Api from "@services/Api";
import { toastError, toastMessage } from "@utils/functions";
import SortableImageItem from "./SortableImageItem";

interface SortableImagesProps {
  product: Product;
  onChangeStart?: () => void;
  onChangeEnd?: () => void;
}

const SortableImages: React.FC<SortableImagesProps> = ({ product, onChangeStart, onChangeEnd }) => {
  const [imagesList, setImagesList] = React.useState(product.images);
  const onSortEnd = async (oldIndex: number, newIndex: number) => {
    onChangeStart?.();
    setImagesList((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    const sortedImages = arrayMoveImmutable(imagesList, oldIndex, newIndex);
    if (sortedImages !== imagesList) {
      const prioritiesPayload = sortedImages.map((image, index) => ({ image_id: image.id, priority: index }));
      try {
        await Api.put(`/product/${product.id}/update_images_priority`, { payload: prioritiesPayload });
        toastMessage("Ordem atualizada", "success");
      } catch (error) {
        toastError(error);
      }
    }
    onChangeEnd?.();
  };

  const handleDeleteImage = async (image: Image) => {
    try {
      await Api.delete(`/product/image/${image.id}`);
      toastMessage(`Imagem excluída`, "success");
      setImagesList((array) => array.filter((img) => img.id !== image.id));
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    setImagesList(product.images);
  }, [product]);

  return (
    <SortableList
      onSortEnd={onSortEnd}
      style={{ display: "flex", flexWrap: "wrap", userSelect: "none", marginTop: 20 }}
      draggedItemClassName={"dragged"}
    >
      {imagesList.map((image, index) => (
        <SortableItem key={image.id}>
          <div
            style={{
              position: "relative",
              cursor: "grab",
              userSelect: "none",
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
              marginBottom: 10,
              marginRight: 20,
            }}
          >
            <SortableImageItem image={image} onDeleteRequest={handleDeleteImage} product={product} />
          </div>
        </SortableItem>
      ))}
    </SortableList>
  );
};

export default SortableImages;
