import * as React from "react";
import styled from "styled-components";
import bugs from "@assets/svg/bugs.svg";

const RootContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ConnectionErrorPage: React.FC = () => {
  return (
    <RootContainer>
      <h1>Algo deu errado.</h1>
      <span>Tente novamente em alguns minutos...</span>
      <img src={bugs} width={400} className="mt-5" alt="error_image" />
    </RootContainer>
  );
};

export default ConnectionErrorPage;
