import { toastError, toastMessage } from "@utils/functions";
import { Product, SelectOption } from "@utils/types";
import * as React from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router";
import AddProductForm from "./Form";
import StockForm from "./StockForm";

const AddProductPage: React.FC = () => {
  const navigate = useNavigate();

  // Stock data state
  const [stockEnabled, setStockEnabled] = React.useState(false);
  const [variationsData, setVariationsData] = React.useState<{ colors: SelectOption[]; sizes: SelectOption[] }>({
    colors: [],
    sizes: [],
  });
  const [stockData, setStockData] = React.useState<{ color_id: number; size_id: number; quantity: number }[]>([]);

  const onProductSave = async (product: Product) => {
    try {
      ReactGA.event({
        action: "product_add",
        category: "Product",
        label: "Adicionou produto",
      });
      toastMessage("Produto adicionado", "success");
      navigate(`/produtos/${product.id}/editar`);
    } catch (error) {
      toastError(error);
    }
  };

  const handleVariationsDataChange = (variations: { colors: SelectOption[]; sizes: SelectOption[] }) => {
    setVariationsData(variations);
  };

  const handleToggleStock = () => {
    setStockEnabled(!stockEnabled);
  };

  const handleStockDataChange = (stockdata: { color_id: number; size_id: number; quantity: number }[]) => {
    setStockData(stockdata);
  };

  return (
    <div className={"row m-0 py-2 rounded-3"} style={{ backgroundColor: "white" }}>
      <div className={"col-12 col-lg-6"}>
        <AddProductForm
          successCallback={onProductSave}
          onVariationsDataChange={handleVariationsDataChange}
          stockData={stockData}
          stockEnabled={stockEnabled}
        />
      </div>
      <div className={"col-12 col-lg-6 mt-4 mt-lg-0"}>
        <StockForm
          variationsData={variationsData}
          onStockEnabledChange={handleToggleStock}
          onStockDataChange={handleStockDataChange}
        />
      </div>
    </div>
  );
};

export default AddProductPage;
