import { useBlingCategories } from "../hooks/use-categories.hooks";
import { BlingCategory } from "../interfaces";
import { SelectOption } from "@utils/types";
import { useEffect, useState } from "react";
import Select from "@components/Select";

interface BlingCategoriesSelectProps {
  onChange: (value: string) => void;
}

export const BlingCategoriesSelect: React.FC<BlingCategoriesSelectProps> = (props) => {
  const { categories, fetchCategories } = useBlingCategories();
  const [options, setOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectOptions = categories.map((category: BlingCategory) => {
      let description = "";
      if (category.categoriaPai?.id) {
        const categoriaPai = categories.find((c) => c.id === category.categoriaPai?.id);
        description = categoriaPai ? `${categoriaPai.descricao} > ${category.descricao}` : "";
      } else {
        description = category.descricao;
      }
      return {
        label: description,
        value: category.id,
      };
    });
    setOptions([{ label: "Todas", value: "" }, ...selectOptions]);
  }, [categories]);

  return (
    <div>
      <Select
        placeholder="Filtrar por categoria"
        options={options}
        onChange={(newValue) => {
          const option = newValue as SelectOption;
          props.onChange(option?.value ?? "");
        }}
      />
    </div>
  );
};
