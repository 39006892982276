import Api from "@services/Api";
import { toastError } from "@utils/functions";
import { EditCompanySchema } from "@utils/schemas";
import React from "react";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { z } from "zod";
import InputMask from "comigo-tech-react-input-mask";
import FullScreenLoading from "@components/FullScreenLoading";
import { toast } from "react-toastify";

type EditCompanyFormValues = z.infer<typeof EditCompanySchema>;

interface EditCompanyFormProps {
  initialValues: EditCompanyFormValues;
  onSubmit: (data: EditCompanyFormValues) => void;
}

const EditCompanyForm = ({ initialValues, onSubmit }: EditCompanyFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
  });

  return (
    <div className="bg-white rounded-2 p-5">
      <h2 className=" mb-5 text-muted">Editar empresa {initialValues.name}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=" mb-3">
          <label htmlFor="name" className="d-block text-muted">
            Nome
          </label>
          <input id="name" {...register("name")} className="d-block form-control  mt-1 p-2 w-25" />
          {errors.name && <span className="text-danger">{errors.name.message}</span>}
        </div>
        <div className="form-field mb-3">
          <label className="d-block text-muted" htmlFor="code">
            Código
          </label>
          <input className="d-block form-control  mt-1 p-2 w-25" id="code" {...register("code")} />
          {errors.code && <span>{errors.code.message}</span>}
        </div>
        <div className="form-field mb-3">
          <label className="d-block text-muted" htmlFor="phone">
            Telefone
          </label>
          <InputMask
            id="phone"
            {...register("phone")}
            className="d-block form-control  mt-1 p-2 w-25"
            mask="(99)99999-9999"
          />
          {errors.phone && <span>{errors.phone.message}</span>}
        </div>
        <div className="form-field mb-3">
          <label className="d-block text-muted">Requer data de nascimento</label>
          <select {...register("requires_birthdate")} className="w-10 mt-1 d-block rounded form-group px-4 py-2 ">
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.requires_birthdate && <span>{errors.requires_birthdate.message}</span>}
        </div>
        <div className="form-field mb-3">
          <label htmlFor="require_login_to_buy" className="d-block text-muted">
            Requer login para comprar
          </label>
          <select
            id="require_login_to_buy"
            {...register("require_login_to_buy")}
            className="w-10 mt-1 d-block rounded form-group px-4 py-2 "
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.require_login_to_buy && <span className="text-red-500">{errors.require_login_to_buy.message}</span>}
        </div>
        <div className="form-field mb-3">
          <label className="d-block text-muted">Requer documento</label>
          <select {...register("requires_document")} className="w-10 mt-1 d-block rounded form-group px-4 py-2 ">
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
        </div>
        <div className="form-field mb-3">
          <label className="d-block text-muted">Opções de entrega habilitadas?</label>
          <select {...register("is_shipment_enabled")} className="w-10 mt-1 d-block rounded form-group px-4 py-2 ">
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.is_shipment_enabled && <span>{errors.is_shipment_enabled.message}</span>}
        </div>
        <div className="form-field mb-3">
          <label className="d-block text-muted">Opções de pagamento habilitadas?</label>
          <select {...register("is_payment_enabled")} className="w-10 mt-1 d-block rounded form-group px-4 py-2 ">
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.is_payment_enabled && <span>{errors.is_payment_enabled.message}</span>}
        </div>
        <div className="form-field mb-3">
          <label className="d-block text-muted">Modo de manutenção habilitado?</label>
          <select
            {...register("is_maintenance_mode_enabled")}
            className="w-10 mt-1 d-block rounded form-group px-4 py-2 "
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.is_maintenance_mode_enabled && <span>{errors.is_maintenance_mode_enabled.message}</span>}
        </div>
        <div className="form-field mb-3">
          <label className="d-block text-muted">Requer informações de excursão?</label>
          <select
            {...register("is_excursion_info_required")}
            className="w-10 mt-1 d-block rounded form-group px-4 py-2 "
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.is_excursion_info_required && <span>{errors.is_excursion_info_required.message}</span>}
        </div>
        <div className="form-field mb-3">
          <label className="d-block text-muted">Retirada habilitada?</label>
          <select {...register("is_pickup_enabled")} className="w-10 mt-1 d-block rounded form-group px-4 py-2 ">
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.is_pickup_enabled && <span>{errors.is_pickup_enabled.message}</span>}
        </div>
        <div className="form-field mb-3">
          <label className="d-block text-muted">Mostrar estoque para o cliente?</label>
          <select {...register("show_stock_to_customer")} className="w-10 mt-1 d-block rounded form-group px-4 py-2 ">
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.show_stock_to_customer && <span>{errors.show_stock_to_customer.message}</span>}
        </div>
        <div className="d-none form-field">
          <label className="d-block text-muted">id da empresa</label>
          <input {...register("id")} />
        </div>
        <button type="submit" className={"mt-2 btn btn-primary"}>
          Enviar
        </button>{" "}
      </form>
    </div>
  );
};

export default function EditCompanyPage() {
  const [initialValues, setInitialValues] = React.useState<EditCompanyFormValues | undefined>();
  const { company_code: companyCode } = useParams<{ company_code: string }>();

  React.useEffect(() => {
    companyCode && fetchCompanyData(companyCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCompanyData = async (companyCode: string) => {
    try {
      const { data } = await Api.get(`/company/by_code/${companyCode}`);
      const {
        id,
        name,
        code,
        requires_birthdate,
        is_shipment_enabled,
        require_login_to_buy,
        is_payment_enabled,
        is_maintenance_mode_enabled,
        requires_document,
        phone,
        is_excursion_info_required,
        is_pickup_enabled,
        show_stock_to_customer,
      } = data.company;
      const formInitialValues = {
        id,
        name,
        code,
        requires_birthdate,
        is_shipment_enabled,
        require_login_to_buy,
        is_payment_enabled,
        is_maintenance_mode_enabled,
        requires_document,
        phone,
        is_excursion_info_required,
        is_pickup_enabled,
        show_stock_to_customer,
      };

      setInitialValues(formInitialValues);
    } catch (error) {
      toastError(error);
    }
  };

  const onSubmit = async (data: EditCompanyFormValues) => {
    try {
      const payload = {
        ...data,
        // @ts-ignore
        is_shipment_enabled: data.is_shipment_enabled === "true" || data.is_shipment_enabled === true,
        // @ts-ignore
        is_payment_enabled: data.is_payment_enabled === "true" || data.is_payment_enabled === true,
        // @ts-ignore
        requires_birthdate: data.requires_birthdate === "true" || data.requires_birthdate === true,
        // @ts-ignore
        require_login_to_buy: data.require_login_to_buy === "true" || data.require_login_to_buy === true,
        // @ts-ignore
        is_maintenance_mode_enabled: data.is_maintenance_mode_enabled === true,
        // @ts-ignore
        requires_document: data.requires_document === "true" || data.requires_document === true,
        // @ts-ignore
        is_excursion_info_required: data.is_excursion_info_required === true,
        // @ts-ignore
        is_pickup_enabled: data.is_pickup_enabled === "true" || data.is_pickup_enabled === true,
        // @ts-ignore
        show_stock_to_customer: data.show_stock_to_customer === "true" || data.show_stock_to_customer === true,
      };

      await Api.patch(`/company/${data.id}`, payload);
      toast("Empresa atualizada com sucesso!", { type: "success" });
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <>
      {initialValues?.phone ? (
        <EditCompanyForm initialValues={initialValues} onSubmit={onSubmit} />
      ) : (
        <FullScreenLoading />
      )}
    </>
  );
}
