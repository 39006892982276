import * as React from "react";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import { toastError, toastMessage } from "@utils/functions";
import { Size } from "@utils/types";
import { useSelector } from "react-redux";
import DataTable, { TableColumn } from "react-data-table-component";
import tableStyles from "@styles/table";
import FullScreenLoading from "@components/FullScreenLoading";
import FakeLink from "@components/FakeLink";
import { Link, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

const ListSizesPage: React.FC = () => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const [sizes, setSizes] = React.useState<Size[]>([]);
  const [loadingSizes, setLoadingSizes] = React.useState(true);
  const navigate = useNavigate();

  const columns = React.useMemo<TableColumn<Size>[]>(
    () => [
      {
        name: "Nome",
        selector: (row) => row.name,
      },
      {
        button: true,
        name: "Ações",
        selector: (row) => row.id,
        cell: (row) => (
          <>
            <Link
              style={{ textDecoration: "none" }}
              to={`/configuracoes/tamanhos/${row.id}/editar`}
              className={"text-primary me-2"}
            >
              Editar
            </Link>
            <FakeLink
              onClick={() => {
                handleDeleteSize(row);
              }}
              className={"text-danger"}
            >
              Excluir
            </FakeLink>
          </>
        ),
      },
    ],
    // eslint-disable-next-line
    [companyData],
  );

  const handleDeleteSize = async (size: Size) => {
    confirmAlert({
      title: "Atenção!",
      message: `Deseja realmente excluir o tamanho ${size.name}?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await Api.delete(`/company/${companyData.id}/size/${size.id}`);
              fetchSizes();
              toastMessage(`Concluído.`, "success");
            } catch (error) {
              toastError(error);
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const fetchSizes = async () => {
    try {
      const { data } = await Api.get(`/company/${companyData.id}/size`);
      setSizes(data.sizes);
      setLoadingSizes(false);
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    if (companyData.id) fetchSizes();
    // eslint-disable-next-line
  }, [companyData]);

  if (loadingSizes) return <FullScreenLoading />;

  return (
    <div className={"row p-0 m-0 rounded-3"} style={{ width: "100%", backgroundColor: "white" }}>
      <div className={"col-12 my-2 text-end"}>
        <button
          className={"btn btn-primary"}
          onClick={() => {
            navigate("/configuracoes/tamanhos/cadastrar");
          }}
        >
          Novo tamanho
        </button>
      </div>
      <div className={"col-12"}>
        <DataTable
          columns={columns}
          data={sizes}
          customStyles={tableStyles}
          noDataComponent="Você ainda não cadastrou nenhum tamanho"
          pagination
          responsive
        />
      </div>
    </div>
  );
};

export default ListSizesPage;
