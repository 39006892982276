import * as React from "react";
import { Order } from "@utils/types";
import { getStatusColor } from "@utils/functions";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@services/Redux";
import DataTable, { TableColumn } from "react-data-table-component";
import LoadingIndicator from "@components/LoadingIndicator";
import tableStyles from "@styles/table";
import { Money } from "@utils/money";
import moment from "@utils/moment";
import StatusPill from "@components/StatusPill";
import OrdersFilters from "./Filters";
import {
  fetchOrders,
  selectAllOrders,
  selectOrder,
  setOrdersFilters,
  unselectAllOrders,
  unselectOrder,
} from "@services/Redux/orders";
import { Link, useNavigate } from "react-router-dom";
import EditOrderModal from "@components/EditOrderModal";
import OrderItemActions from "./components/OrderItemActions";

const OrdersPage: React.FC = () => {
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState<Order | null>(null);
  const dispatch = useAppDispatch();
  const companyData = useSelector((state: RootState) => state.company.data);
  const selectedOrders = useSelector((state: RootState) => state.orders.selected);
  const { data: orders, filters, count: ordersCount, loading } = useSelector((state: RootState) => state.orders);
  const navigate = useNavigate();

  const columns: TableColumn<Order>[] = [
    {
      name: (
        <input
          type="checkbox"
          checked={selectedOrders.length > 0}
          onClick={() => {
            if (selectedOrders.length > 0) {
              dispatch(unselectAllOrders());
            } else {
              dispatch(selectAllOrders());
            }
          }}
        />
      ),
      minWidth: "50px",
      maxWidth: "50px",
      cell: (row) => (
        <input
          type="checkbox"
          value={selectedOrders.find((order) => order.id === row.id) ? "true" : "false"}
          checked={selectedOrders.find((order) => order.id === row.id) ? true : false}
          onClick={() => {
            if (selectedOrders.find((order) => order.id === row.id)) {
              dispatch(unselectOrder(row));
            } else {
              dispatch(selectOrder(row));
            }
          }}
        />
      ),
      selector: (row) => row.id,
    },
    {
      name: "Data",
      minWidth: "130px",
      selector: (row) => moment(row[filters.orderBy] ?? row.created_at).format("DD/MM/YY HH:mm"),
    },
    {
      name: "Pedido",
      selector: (row) => row.code,
      minWidth: "100px",
      cell: (row) => <Link to={`/pedido/${row.code}`}>{row.code}</Link>,
    },
    {
      name: "Valor",
      selector: (row) => row.value,
      minWidth: "150px",
      right: true,
      format: (row) => Money(row.value).format(),
    },
    {
      name: "Situação",
      selector: (row) => row.status,
      minWidth: "170px",
      cell: (row) => <StatusPill title={row.status} bgColor={getStatusColor(row.status)} />,
    },
    {
      name: "Cliente",
      minWidth: "200px",
      selector: (row) => row.customer_name ?? "",
    },
    {
      name: "Ações",
      center: true,
      minWidth: "200px",
      cell: (row) => <OrderItemActions order={row} onEditOrderClick={setSelectedOrder} />,
    },
  ];

  const handlePerPageChange = (perPage: number) => {
    dispatch(setOrdersFilters({ ...filters, perPage }));
  };

  const handlePageChange = (page: number) => {
    dispatch(setOrdersFilters({ ...filters, page }));
  };

  React.useEffect(() => {
    if (selectedOrder) setIsEditModalOpen(true);
  }, [selectedOrder]);

  React.useEffect(() => {
    if (companyData.id) {
      dispatch(fetchOrders());
    }
    // eslint-disable-next-line
  }, [companyData, filters.status, filters.orderBy, filters.sort, filters.page, filters.perPage]);

  React.useEffect(() => {
    if (filters.dateRangeSelectStep === 1) dispatch(fetchOrders());
    // eslint-disable-next-line
  }, [companyData, filters.dateRangeSelectStep]);

  return (
    <div>
      <OrdersFilters />
      <div className="mt-4">
        <DataTable
          columns={columns}
          data={orders}
          progressPending={loading}
          progressComponent={<LoadingIndicator />}
          customStyles={tableStyles}
          noDataComponent="Você ainda não tem nenhum pedido com esses filtros"
          pagination
          paginationServer
          paginationPerPage={filters.perPage}
          paginationTotalRows={ordersCount}
          onChangeRowsPerPage={handlePerPageChange}
          onChangePage={handlePageChange}
        />
      </div>
      {selectedOrder?.code && isEditModalOpen && (
        <EditOrderModal
          orderCode={selectedOrder.code ?? ""}
          open={isEditModalOpen}
          onClose={() => {
            navigate(`/pedido/${selectedOrder.code}`);
            dispatch(fetchOrders());
          }}
        />
      )}
    </div>
  );
};

export default OrdersPage;
