import Select from "@components/Select";
import Api from "@services/Api";
import { RootState } from "@services/Redux";
import { toastError, toastMessage } from "@utils/functions";
import { SelectOption } from "@utils/types";
import * as React from "react";
import CurrencyInput from "react-currency-input-field";
import { SubmitHandler, useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import estadosCidades from "../../../../assets/estados-cidades.json";

interface CouponFormValues {
  code: string;
  description: string;
  discount_percentage?: number;
  discount_fixed_value?: number;
  usage_count?: number;
  max_discount_value?: number;
  conditions?: {
    min_order_value?: number;
    min_items_count?: number;
    payment_method?: string;
    shipping_method?: string;
    per_user_limit?: number;
    expires_at?: string;
    first_order_only?: boolean;
    usage_per_user?: number;
    usage_limit?: number;
  };
}

const AddCouponPage: React.FC = () => {
  const companyData = useSelector((state: RootState) => state.company.data);
  const { register, handleSubmit, watch, getValues, setValue } = useForm<CouponFormValues>();
  const [loading, setLoading] = React.useState(false);
  const [discountType, setDiscountType] = React.useState("percentage");
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<CouponFormValues> = async (data) => {
    data.max_discount_value = +`${data.max_discount_value}`.replace(".", "").replace(",", ".").replace("R$", "").trim();
    data.discount_fixed_value = data.discount_fixed_value
      ? +`${data.discount_fixed_value}`.replace(".", "").replace(",", ".").replace("R$", "").trim()
      : 0;
    data.discount_percentage = data.discount_percentage ? +data.discount_percentage : 0;
    data.usage_count = +(data.usage_count ?? 0);
    if (!data.conditions) data.conditions = {};
    if (data.conditions.min_order_value) {
      data.conditions.min_order_value = +`${data.conditions.min_order_value}`
        .replace(".", "")
        .replace(",", ".")
        .replace("R$", "")
        .trim();
    } else {
      delete data.conditions.min_order_value;
    }
    if (data.conditions.min_order_value) {
      data.conditions.min_order_value = +`${data.conditions.min_order_value}`
        .replace(".", "")
        .replace(",", ".")
        .replace("R$", "")
        .trim();
    } else {
      delete data.conditions.min_order_value;
    }
    if (!data.conditions.expires_at) delete data.conditions.expires_at;
    if (!data.conditions.min_items_count) delete data.conditions.min_items_count;
    if (!data.conditions.min_order_value) delete data.conditions.min_order_value;
    if (!data.conditions.per_user_limit) delete data.conditions.per_user_limit;
    if (!data.conditions.usage_limit) delete data.conditions.usage_limit;

    if (Object.keys(data.conditions).length === 0) delete data.conditions;

    setLoading(true);
    try {
      await Api.post(`/company/${companyData.id}/coupon`, data);
      setLoading(false);
      toastMessage("Cupom adicionado.", "success");
      navigate(-1);
    } catch (error) {
      setLoading(false);
      toastError(error);
    }
  };

  return (
    <div className={"p-2"} style={{ width: "100%", backgroundColor: "white" }}>
      <div className={"mb-3"}>
        <h4 className={"m-0 p-0 mt-2"}>Informações do cupom</h4>
        <span className={"text-muted"}>Forneça as informações básicas do seu cupom.</span>
      </div>
      <form className={"row"} onSubmit={handleSubmit(onSubmit)}>
        <div className={"col-md-6"}>
          <label>Código</label>
          <input {...register("code")} placeholder={"Ex: fretegratis"} className={"form-control mt-2"} />
        </div>
        <div className={"col-md-6"}>
          <label>Descrição</label>
          <input {...register("description")} placeholder={""} className={"form-control mt-2"} />
        </div>
        <div className={"col-md-6 mt-3"}>
          <label>Tipo de desconto</label>
          <Select
            placeholder={"Selecione o tipo de desconto"}
            className={"mt-2"}
            defaultValue={{ value: "percentage", label: "Porcentagem" }}
            onChange={(value) => {
              const option = value as SelectOption;
              setDiscountType(option.value);
            }}
            options={[
              { value: "percentage", label: "Porcentagem" },
              { value: "fixed_value", label: "Valor" },
            ]}
          />
        </div>
        {discountType === "percentage" ? (
          <div className={"col-md-6 mt-3"}>
            <label>Desconto (%)</label>
            <input
              {...register("discount_percentage")}
              type={"number"}
              placeholder={"Ex: 50%"}
              className={"form-control mt-2"}
            />
          </div>
        ) : (
          <div className={"col-md-6 mt-3"}>
            <label>Desconto (R$)</label>
            <CurrencyInput
              {...register("discount_fixed_value")}
              intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              decimalSeparator={","}
              groupSeparator={"."}
              placeholder={""}
              className={"form-control mt-2"}
            />
          </div>
        )}

        <div className={"col-12 mt-5 mb-2"}>
          <h4 className={"m-0 p-0"}>Condições</h4>
          <span className={"text-muted"}>Defina em quais condições o seu cupom será válido.</span>
        </div>
        <div className={"col-md-6 mt-3"}>
          <label>Valor mínimo do pedido</label>
          <CurrencyInput
            {...register("conditions.min_order_value")}
            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
            decimalSeparator={","}
            groupSeparator={"."}
            placeholder={""}
            className={"form-control mt-2"}
          />
        </div>
        <div className={"col-md-6 mt-3"}>
          <label>Número mínimo de peças no pedido</label>
          <input
            {...register("conditions.min_items_count")}
            type={"number"}
            placeholder={""}
            className={"form-control mt-2"}
          />
        </div>
        <div className={"col-md-6 mt-3"}>
          <label>Limite de uso</label>
          <input
            {...register("conditions.usage_limit")}
            type={"number"}
            placeholder={""}
            className={"form-control mt-2"}
          />
        </div>
        <div className={"col-md-6 mt-3"}>
          <label>Limite de uso por usuário</label>
          <small className={"text-sm text-muted d-block"}>
            Essa opção só pode ser utilizada se o login obrigatório para compras estiver habilitado nas configurações
          </small>
          <input
            {...register("conditions.per_user_limit")}
            type={"number"}
            placeholder={""}
            className={"form-control mt-2"}
          />
        </div>
        <div className={"col-md-6 mt-3"}>
          <label>Válido até</label>
          <ReactInputMask
            {...register("conditions.expires_at")}
            mask={"99/99/9999"}
            placeholder={""}
            className={"form-control mt-2"}
          />
        </div>

        <div className={"col-md-6 mt-3"}>
          <label>Desconto máximo (R$)</label>
          <CurrencyInput
            {...register("max_discount_value")}
            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
            decimalSeparator={","}
            groupSeparator={"."}
            placeholder={""}
            className={"form-control mt-2"}
          />
        </div>
        <div className={"col-12 mt-3 d-flex align-items-end pb-2"}>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="firstOrderOnlySwitch"
              onChange={(e) => {
                setValue("conditions.first_order_only", e.target.checked);
              }}
            />
            <label className="form-check-label" htmlFor="firstOrderOnlySwitch">
              Apenas primeira compra
            </label>
          </div>
        </div>
        <div className={"mt-3"}>
          <button className={"btn btn-primary"} disabled={loading}>
            {loading ? "Enviando" : "Enviar"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCouponPage;
